import React from "react";
import { BsLink45Deg, BsInstagram } from "react-icons/bs";
import { RiShoppingBagLine } from "react-icons/ri";
import { FiAtSign } from "react-icons/fi";
import { Button } from "antd";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../Utils/SupabaseServices/ContentDatabase";
import "./PlatformPosts/PostCarousel.css";

const CreatorFeedContentInfo = (props: any) => {
  const {
    user_id,
    post_date,
    permalink=null,
    shop_link,
    tagged_link=null,
    post_id,
    media_url,
    shoppableContent,
    isMobile,
    isGlobalView=false,
    username,
    showAsAd=false,PlatformIcon
  } = props;
  const handleRedirect = (postId: any, mediaUrl: any) => {
    track("Redirect to original post", TRACKING_TYPES.SEARCH, {
      pathname: window.location.pathname,
      details: { post_id: postId, media_url: mediaUrl },
      user_id: user_id,
    });
    updateRefClick(postId, user_id);
  };

  const handleShop = (postId: any, mediaUrl: any) => {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: window.location.pathname,
      details: { post_id: postId, media_url: mediaUrl },
      user_id: user_id,
    });
  };

  const handleTagged = (postId: any, mediaUrl: any) => {
    track("Redirect to tagged link", TRACKING_TYPES.EVENT, {
      pathname: window.location.pathname,
      details: { post_id: postId, media_url: mediaUrl },
      user_id: user_id,
    });
  };

  const iconStyle = {
    marginRight: "3px",
    cursor: "pointer",
    transition: "color 0.3s ease-in-out",
    color: "#000",
  };

  const handleMouseEnter = (e: any) => {
    e.currentTarget.style.color = "#888";
  };

  const handleMouseLeave = (e: any) => {
    e.currentTarget.style.color = "#000";
  };

  const RenderLink = ({ href, target, onClick, children }: any) =>
    href ? (
      <a href={href} target={target} rel="noreferrer">
        {React.cloneElement(children, {
          // style: iconStyle,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          onClick,
        })}
      </a>
    ) : null;


  const PostLinks = () => (
    <div className="creator-feed-post-links">
      <RenderLink
        href={permalink}
        target="_blank"
        onClick={() => handleRedirect(post_id, media_url)}
      >
        <BsLink45Deg />
      </RenderLink>

      {shop_link && (
        <RenderLink
          href={shop_link}
          target={isMobile ? "_self" : "_blank"}
          onClick={() => handleShop(post_id, media_url)}
        >
          {shoppableContent === "icon" ? (
            <RiShoppingBagLine />
          ) : shoppableContent === "text" ? (
            <Button
            // className="shop-btn"
            style={{
              fontSize: "0.6rem",
              backgroundColor: "#000",
              marginRight: "3px",
              cursor: "pointer",
              transition: "color 0.3s ease-in-out",
              color: "#fff",
            }}
            onMouseOver={(e: any) => {
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e: any) => {
              e.target.style.color = "#fff";
            }}
            >
            Shop
            </Button>
          ) : null}
        </RenderLink>
      )}

      <RenderLink
        href={tagged_link}
        target={isMobile ? "_self" : "_blank"}
        onClick={() => handleTagged(post_id, media_url)}
      >
        <FiAtSign />
      </RenderLink>

      {permalink ? (
        <RenderLink
          href={permalink}
          target="_blank"
          onClick={() => handleRedirect(post_id, media_url)}
        >
          {PlatformIcon && <PlatformIcon className="ml-6" />}
        </RenderLink>
      ) : (
      <>  {PlatformIcon && <PlatformIcon className="ml-6" />}</>
      )}

      {isGlobalView && username && (
        <p className="creator-feed-username">@{username}</p>
      )}
    </div>
  );

  return (
    <div className="creator-feed-content-info">
      {!showAsAd ? (
        <>
          {new Date(post_date).getFullYear() > 2000 && (
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
          )}
          <PostLinks />
        </>
      ) : (
        <>
          <p className="creator-feed-content-date">Sponsored</p>
          <PostLinks />
        </>
      )}
    </div>
  );
};

export default CreatorFeedContentInfo;
