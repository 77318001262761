import { BsFillPlayFill, BsLink45Deg } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./PodcastPost.css";
import Caption from "../Caption";
import { useState } from "react";
import { Button, Modal } from "antd";
import "../CommentsPost/CommentsPost.css";

import { MdOutlinePodcasts } from "react-icons/md";

export type PodcastPostType = Post & {
  platform: "podcast";
  metadata: {
    ep_description: string;
    ep_thumbnail: string;
    ep_summary: string;
    ep_keywords: string;
  };
};

export default function PodcastPost({
  post,
  showCaption,
  shoppableContent,
}: {
  post: PodcastPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const location = useLocation();
  const { showAsAd, post_date, post_id, permalink } = post;
  function handleRedirect(postId: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: postId,
    });
  }

  var html: any = post.metadata ? JSON.stringify(post.metadata) : null;
  const jsonString = JSON.parse(html);
  const jsonObject = JSON.parse(jsonString);

  const descriptionHTML = jsonObject?.ep_html;

  const convertToLinks = (text: string) => {
    const urlPattern = /(https?:\/\/\S+)/g;

    const result = text?.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return result;
  };
  return (
    <div>
      <div className="podcast-post-img-wrp">
        <img
          src={post.media_url}
          alt={post.caption}
          className="podcast-post-img bg-f1"
        />
      </div>
      <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <MdOutlinePodcasts
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                <MdOutlinePodcasts
                  className="ml-6"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-ad-indicator">Sponsored</p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <MdOutlinePodcasts
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                <MdOutlinePodcasts
                  className="ml-6"
                />
              )}
            </div>
          </>
        )}
      </div>
      {post?.caption && <Caption caption={post.caption} />}
      {post.metadata?.ep_description ? (
        <button className="newsletter-post-btn" onClick={showModal}>
          See Podcast Description
        </button>
      ) : (
        ""
      )}

      <Modal
        bodyStyle={{
          overflowY: "auto",

          maxHeight: "calc(100vh - 250px)",
        }}
        title={`${new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          day: "numeric",
          month: "long",
        }).format(new Date(post_date))} Podacst`}
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          dangerouslySetInnerHTML={{ __html: convertToLinks(descriptionHTML) }}
        />
      </Modal>
    </div>
  );
}
