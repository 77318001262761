import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css"
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const ThisWeekLinksComponents = (props: any) => {
  const { username, user_id, isThisWeekLinksComponentOpen, title, description } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true)
    let res;
    if (title === "This Month's Shoppable Content") {
      res = await addComponent("This Month's Shoppable Content", "", username, user_id, "true", "Check it out!", "",
        "This Month");
    } else {
      res = await addComponent("This Week's Shoppable Content", "", username, user_id, "true", "Check it out!", "",
        "This Week");
    }
    if (res === "true") {
      props.refetch()
      props.setIsShoplinksModalOpen(false);
      props.setIsThisWeekLinksComponentOpen(false);
      setConfirmLoading(false)

    }

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsThisWeekLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsThisWeekLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton" >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isThisWeekLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="thisweek-shop"
      >
        <h5 className="title mb-20">{title}</h5>
        <p className="text-center">
          {description}
          {/* All of your shoppable content posted in the last 7 days will always be shown in this carousel. Content will automatically be updated! */}
        </p>

      </Modal>
    </>
  );
};


export default ThisWeekLinksComponents;
