import React from "react";
import FeedPost, { Post } from "../FeedPost/FeedPost";
import "./CreatorFeed.css";

const CreatorFeed = ({
	data,
	showCaption,
	showComments,
	isGlobalView,
	username,
	query,
	shoppableContent
}: any) => {
	return (
		<div className='creator-feed-content-wrapper' >
			{data && data.length > 0 ? (
				<div className='creator-feed-grid-wrp'>
					{data.map((post: Post, index: number) => (
						<React.Fragment key={index}>
						<FeedPost
							key={index}
							post={{ ...post}}
							showCaption={showCaption}
							showComments={showComments}
							isGlobalView={isGlobalView}
							username={username}
							query={query}
							shoppableContent={shoppableContent}
						/>
						</React.Fragment>
					))}
				</div>
			) : (
				<div className='creator-feed-div-not-found'>
					<p>No Results Found 😢</p>
				</div>
			)}
		</div>
	);
};

export default CreatorFeed;
