import CreatorData from "../../Utils/CreatorInfo.json";
const CreatorQuote = () => {
	function getRandomCreatorData() {
		return CreatorData[(CreatorData.length * Math.random()) << 0];
	}
	return (
		<div className='auth-creator-quote-container'>
			<div className='auth-right-panel'>
				<h1 className='auth-creator-quote'>
					<span className='auth-creator-quote-quotes'>&ldquo;</span>
					{getRandomCreatorData().Qoute}
				</h1>
				<div className='auth-creator-info'>
					<img
						className='auth-creator-image'
						src={getRandomCreatorData().Image}
						alt='creator profile'
					/>
					<p className='auth-creator-name'>
						{getRandomCreatorData().Description}
						<br />
						{getRandomCreatorData().Userhandle}
					</p>
				</div>
			</div>
		</div>
	);
};

export default CreatorQuote;
