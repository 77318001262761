import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export default function useUpdatePostAdIndex() {
  const queryClient = useQueryClient();

  const updateIndexValue = async ({
    user_id,
    sourceIndex,
    targetIndex,
    activeKey,
    post_id,
    username,
    newData,
  }: {
    user_id: any;
    sourceIndex: any;
    targetIndex: any;
    activeKey: any;
    post_id: string;
    username: any;
    newData: any;
  }) => {

    let { error: e1 } = await supabase
      .from(`${username}`)
      .update({ ads_order: sourceIndex })
      .eq("user_id", user_id)
      .eq("ads_order", `${targetIndex}`)
      .select();

    let { error: e2 } = await supabase
      .from(`${username}`)
      .update({ ads_order: targetIndex })
      .eq("user_id", user_id)
      .eq("post_id", `${post_id}`)
      .select();

    let { error: e3 } = await supabase
      .from("ads_content")
      .update({ ads_order: sourceIndex })
      .eq("user_id", user_id)
      .eq("ads_order", `${targetIndex}`)
      .select();

    let { error: e4 } = await supabase
      .from("ads_content")
      .update({ ads_order: targetIndex })
      .eq("user_id", user_id)
      .eq("post_id", `${post_id}`)
      .select();

    if (e1 || e2 || e3 || e4) {
      throw new Error("Error updating ads order");
    }

    return newData;
  };

  // Tag optimistic update
  return useMutation(updateIndexValue, {
    // If the mutation fails, use the context returned from onError to roll back
    onError: (error: any, variables) => {
      if (variables.newData) {
        queryClient.setQueryData(
          ["paginatedPosts", variables.activeKey],
          variables.newData
        );
      }
    },
    // Always refetch after error or success:
    onSettled: async (_, error, { activeKey, newData }) => {
      if (!error && newData) {
        // Try invalidating the query with a slight delay
        setTimeout(() => {
          queryClient.invalidateQueries(["paginatedPosts", activeKey], {
            refetchPage: () => true,
          });
        }, 0); // Adjust the delay as needed
      }
    },
  });
}
