import { useQueryClient } from "react-query";
import { supabase } from "../../config/supabaseClient";

// change for dedicated tabless
export async function storeUserControls(user_id: any, numOfHashtags: any) {
  const { data, error }: any = await supabase
    .from("controls")
    .select("*")

    .eq("user_id", user_id);

  if (data && data.length === 0) {
    await supabase
      .from("controls")
      .insert([
        {
          user_id,
          show_caption: "true",
          showCreatorCaption: "true",
          maxCreatorHashtag: numOfHashtags ? numOfHashtags : 20,
          totalHashtagNum: numOfHashtags ? numOfHashtags : 20,
          maxHashtag: numOfHashtags ? numOfHashtags : 20,
          totalRecentSearches: "20",
          totalPopularSearches: "20",
          popularSearchesNumFollower: "20",
          popularSearchesNumCreator: "20",
          recentSearchesNumFollower: "20",
          recentSearchesNumCreator: "20",
        },
      ])
      .single();
    await supabase
      .from("controls_live")
      .insert([
        {
          user_id,
          show_caption: "true",
          totalHashtagNum: numOfHashtags ? numOfHashtags : 20,
          maxHashtag: numOfHashtags ? numOfHashtags : 20,
          totalRecentSearches: "20",
          totalPopularSearches: "20",
          popularSearchesNumFollower: "20",
          recentSearchesNumFollower: "20",
        },
      ])
      .single();
    await supabase
      .from("controls_staging")
      .insert([
        {
          user_id,
          showCreatorCaption: "true",
          maxCreatorHashtag: numOfHashtags ? numOfHashtags : 20,
          totalHashtagNum: numOfHashtags ? numOfHashtags : 20,
          totalRecentSearches: "20",
          totalPopularSearches: "20",
          popularSearchesNumCreator: "20",
          recentSearchesNumCreator: "20",
        },
      ])
      .single();
  }
}

export async function fetchFollowerControls(user_id: any) {
  let { data }: any = await supabase
    .from("controls_live")
    .select("*")
    .eq("user_id", user_id)
    .limit(1)
    .order("created_at", { ascending: false })
    .single();

  if (data) {
    const showCaption = data.show_caption;
    const showTopHashtags = data.topHashtags;
    const showPopularSearches = data.popular_searches;
    const showRecentSearches = data.recent_searches;
    const maxHashtags = data.maxHashtag;
    const maxPopularSearches = data.popularSearchesNumFollower;
    const maxRecentSearches = data.recentSearchesNumFollower;
    const totalRecentSearches = data.totalRecentSearches;
    const totalPopularSearches = data.totalPopularSearches;
    const recentSearchesNum = data.recentSearchesNumFollower;
    const popularSearchesNum = data.popularSearchesNumFollower;
    const totalHashtags = data.totalHashtagNum;
    const showFilters = data.showFollowerFilters;
    const showAds = data.showAdsFollower;
    const showComments = data.show_follower_comments;
    const openAiSearch = data.open_ai_search;
    const showIcons = data.showIconsFollower;
    const showBrowserCategories = data.show_browser_live;
    const showCustomCategories = data.show_custom_live;
    const showTrendingCategories = data.show_trending_live;
    const showHighlights = data.show_highlights_live;
    const showLinks = data.show_links_live;
    const checkedType = data.initial_content2;
    const showSocialLinks = data.show_social_links;
    const showYesterdayStories = data.yesterday_stories;
    const showThisWeekStories = data.this_week_stories;
    const showShoppableLinks = data.show_shoppable_links;
    const contentOrder = data.content_order;
    const isSearchPage = data.is_search_page;
    const shoppableContent=data.shoppable_content

    return {
      showCaption,
      showTopHashtags,
      showPopularSearches,
      showRecentSearches,
      maxHashtags,
      maxPopularSearches,
      maxRecentSearches,
      totalRecentSearches,
      totalPopularSearches,
      recentSearchesNum,
      popularSearchesNum,
      totalHashtags,
      showFilters,
      showAds,
      showComments,
      openAiSearch,
      showIcons,
      showBrowserCategories,
      showCustomCategories,
      showTrendingCategories,
      showHighlights,
      showLinks,
      checkedType,
      showSocialLinks,
      showYesterdayStories,
      showThisWeekStories,
      showShoppableLinks,
      contentOrder,
      isSearchPage,
      shoppableContent
    };
  }
}

export async function fetchCreatorControls(user_id: any) {
  let { data }: any = await supabase
    .from("controls_staging")
    .select("*")
    .eq("user_id", user_id)
    .limit(1)
    .order("created_at", { ascending: false })
    .single();

  if (data) {
    const showCaption = data.showCreatorCaption;
    const showTopHashtags = data.topHashtagsCreator;
    const showPopularSearches = data.popularSearchesCreator;
    const showRecentSearches = data.recentSearchesCreator;
    const maxHashtags = data.maxCreatorHashtag;
    const maxPopularSearches = data.popularSearchesNumCreator;
    const maxRecentSearches = data.recentSearchesNumCreator;
    const totalRecentSearches = data.totalRecentSearches;
    const totalPopularSearches = data.totalPopularSearches;
    const recentSearchesNum = data.recentSearchesNumCreator;
    const popularSearchesNum = data.popularSearchesNumCreator;
    const totalHashtags = data.totalHashtagNum;
    const showFilters = data.showCreatorFilters;
    const showAds = data.showAdsCreator;
    const showComments = data.show_creator_comments;
    const openAiSearch = data.open_ai_search;
    const showIcons = data.showIconsCreator;
    const showBrowserCategories = data.show_browser_staging;
    const showCustomCategories = data.show_custom_staging;
    const showTrendingCategories = data.show_trending_staging;
    const showHighlights = data.show_highlights_staging;
    const showLinks = data.show_links_staging;
    const checkedType = data.initial_content2;
    const showSocialLinks = data.show_social_links;
    const showYesterdayStories = data.yesterday_stories;
    const showThisWeekStories = data.this_week_stories;
    const showShoppableLinks = data.show_shoppable_links;
    const contentOrder = data.content_order;
    const isSearchPage = data.is_search_page;

    return {
      showCaption,
      showTopHashtags,
      showPopularSearches,
      showRecentSearches,
      maxHashtags,
      maxPopularSearches,
      maxRecentSearches,
      totalRecentSearches,
      totalPopularSearches,
      recentSearchesNum,
      popularSearchesNum,
      totalHashtags,
      showFilters,
      showAds,
      showComments,
      openAiSearch,
      showIcons,
      showBrowserCategories,
      showCustomCategories,
      showTrendingCategories,
      showHighlights,
      showLinks,
      checkedType,
      showSocialLinks,
      showYesterdayStories,
      showThisWeekStories,
      showShoppableLinks,
      contentOrder,
      isSearchPage,
    };
  }
}

// export async function checkControls(user_id: any) {
//   const { data, error }: any = await supabase
//     .from("controls")
//     .select("*")
//     .eq("user_id", user_id);

//   if (data && data.length) {
//     if (
//       data[0].showCreatorCaption === data[0].show_caption &&
//       data[0].showAdsCreator === data[0].showAdsFollower &&
//       data[0].show_creator_comments === data[0].show_follower_comments &&
//       data[0].show_browser_staging === data[0].show_browser_live &&
//       data[0].topHashtagsCreator === data[0].topHashtags &&
//       data[0].maxCreatorHashtag === data[0].maxHashtag &&
//       data[0].popularSearchesCreator === data[0].popular_searches &&
//       data[0].popularSearchesNumCreator ===
//         data[0].popularSearchesNumFollower &&
//       data[0].showCreatorFilters === data[0].showFollowerFilters &&
//       data[0].show_highlights_staging === data[0].show_highlights_live &&
//       data[0].show_links_staging === data[0].show_links_live

//       // data[0].recentSearchesCreator === data[0].recent_searches &&
//       // data[0].recentSearchesNumCreator === data[0].recentSearchesNumFollower &&
//     ) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// }

export async function updateMaxHashtagsLive(user_id: any, newValue: any) {
  await supabase
    .from("controls_live")
    .update({ maxHashtag: newValue })
    .eq("user_id", `${user_id}`);
}
export async function updatePopularLive(user_id: any, newValue: any) {
  await supabase
    .from("controls_live")
    .update({ popularSearchesNumFollower: newValue })
    .eq("user_id", `${user_id}`);
}
export async function updateRecentLive(user_id: any, newValue: any) {
  await supabase
    .from("controls_live")
    .update({ recentSearchesNumFollower: newValue })
    .eq("user_id", `${user_id}`);
}
export async function updateRecentStaging(user_id: any, newValue: any) {
  await supabase
    .from("controls_staging")
    .update({ recentSearchesNumCreator: newValue })
    .eq("user_id", `${user_id}`);
}
export async function updatePopularStaging(user_id: any, newValue: any) {
  await supabase
    .from("controls_staging")
    .update({ popularSearchesNumCreator: newValue })
    .eq("user_id", `${user_id}`);
  return true;
}
export async function updateMaxHashtagsStaging(user_id: any, newValue: any) {
  await supabase
    .from("controls_staging")
    .update({ maxCreatorHashtag: newValue })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveCaptionToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ show_caption: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveFilterToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ showFollowerFilters: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveAdsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ showAdsFollower: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveCommentsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ show_follower_comments: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveBrowserCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ show_browser_live: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveYesterdayStoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ yesterday_stories: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveThisWeekStoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ this_week_stories: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveCustomCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ show_custom_live: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveTrendingCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ show_trending_live: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveHighlightsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ show_highlights_live: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveLinksToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ show_links_live: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveShoppableLinksToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ show_shoppable_links: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLivePopularSearchesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_live")
    .update({ popular_searches: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveTopHashtagsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ topHashtags: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingCaptionToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ showCreatorCaption: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveSocialLinksToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ show_social_links: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingSocialLinksToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ show_social_links: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingFilterToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ showCreatorFilters: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingAdsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ showAdsCreator: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingCommentsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ show_creator_comments: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingBrowserCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ show_browser_staging: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingCustomCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ show_custom_staging: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingTrendingCategoriesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ show_trending_staging: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingHighlightsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ show_highlights_staging: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingLinksToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ show_links_staging: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingIconsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_staging")
    .update({ showIconsCreator: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateLiveIconsToggle(user_id: any, toggle: any) {
  await supabase
    .from("controls_live")
    .update({ showIconsFollower: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingPopularSearchesToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ popularSearchesCreator: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateStagingTopHashtagsToggle(
  user_id: any,
  toggle: any
) {
  await supabase
    .from("controls_staging")
    .update({ topHashtagsCreator: toggle })
    .eq("user_id", `${user_id}`);
}

export async function checkControls(user_id: any) {
  const { data: liveData, error: liveError }: any = await supabase
    .from("controls_live")
    .select("*")
    .eq("user_id", user_id);

  const { data: stagingData, error: stagingError }: any = await supabase
    .from("controls_staging")
    .select("*")
    .eq("user_id", user_id);
  if (liveError || stagingError) {
    // Handle errors if needed
    return false;
  }

  if (liveData && stagingData && liveData.length && stagingData.length) {
    const isMatching =
      stagingData[0].showCreatorCaption === liveData[0].show_caption &&
      stagingData[0].showAdsCreator === liveData[0].showAdsFollower &&
      stagingData[0].show_creator_comments ===
        liveData[0].show_follower_comments &&
      stagingData[0].show_browser_staging === liveData[0].show_browser_live &&
      stagingData[0].topHashtagsCreator === liveData[0].topHashtags &&
      stagingData[0].maxCreatorHashtag === liveData[0].maxHashtag &&
      stagingData[0].popularSearchesCreator === liveData[0].popular_searches &&
      stagingData[0].popularSearchesNumCreator ===
        liveData[0].popularSearchesNumFollower &&
      stagingData[0].showCreatorFilters === liveData[0].showFollowerFilters &&
      stagingData[0].show_highlights_staging ===
        liveData[0].show_highlights_live &&
      stagingData[0].show_links_staging === liveData[0].show_links_live;
    return isMatching;
  } else {
    // Handle the case when data is not available for both tables
    return false;
  }
}

export async function pushUpdatesToLive(user_id: any) {
  const { data: stagingData } = await supabase
    .from("controls_staging")
    .select("*")
    .eq("user_id", `${user_id}`);

  if (stagingData && stagingData.length > 0) {
    const stagingDataRow = stagingData[0];

    await supabase
      .from("controls_live")
      .update({
        show_caption: stagingDataRow.show_creator_caption,
        show_ads_follower: stagingDataRow.show_ads_creator,
        show_follower_comments: stagingDataRow.show_creator_comments,
        show_browser_live: stagingDataRow.show_browser_staging,
        top_hashtags: stagingDataRow.top_hashtags_creator,
        max_hashtag: stagingDataRow.max_creator_hashtag,
        popular_searches: stagingDataRow.popular_searches_creator,
        popular_searches_num_follower:
          stagingDataRow.popular_searches_num_creator,
        show_follower_filters: stagingDataRow.show_creator_filters,
        show_highlights_live: stagingDataRow.show_highlights_staging,
        show_links_live: stagingDataRow.show_links_staging,
        // Add other fields as needed
      })
      .eq("user_id", `${user_id}`);
  }
}

export async function updateCheckedType(user_id: any, type: any, page: any) {
  if (page === "follower" || page === "live") {
    await supabase
      .from("controls_live")
      .update({ initial_content2: type })
      .eq("user_id", user_id);
  } else {
    await supabase
      .from("controls_staging")
      .update({ initial_content2: type })
      .eq("user_id", user_id);
  }
}

export async function updateContentOrder(user_id: any, type: any, page: any) {
  if (page === "follower" || page === "live") {
    await supabase
      .from("controls_live")
      .update({ content_order: type })
      .eq("user_id", user_id);
  } else {
    await supabase
      .from("controls_staging")
      .update({ content_order: type })
      .eq("user_id", user_id);
  }
}

export async function updateShoppableContent(user_id: any, type: any, page: any) {
  if (page === "follower" || page === "live") {
    await supabase
      .from("controls_live")
      .update({ shoppable_content: type })
      .eq("user_id", user_id);
  } else {
    await supabase
      .from("controls_staging")
      .update({ shoppable_content: type })
      .eq("user_id", user_id);
  }
}

export async function updateBrandColor(
  username: any,
  user_id: any,
  color: string,
  textColor:string
) {
  await supabase
    .from("user")
    .update({ brand_color: color, brand_text_color:textColor })
    .eq("user_id", user_id)
    .eq("username", username);
}
