import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  CheckboxProps,
  Input,
  Pagination,
  Select,
} from "antd";
import "../../../../../components/CheckboxComponent/FeedCheckboxComponent.css";
import Skeleton from "react-loading-skeleton";
import { HiCheck, HiPencil } from "react-icons/hi";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { useLoadProductBankPages } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import { removeProductBankQuery } from "../../../../../Utils/SupabaseServices/ProductBankDatabase";
import { supabase } from "../../../../../config/supabaseClient";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import AllContentTable from "../../../../../components/EditTemplates/AllContentTable";
import AddedContentTable from "../../../../../components/EditTemplates/AddedContentTable";

const POSTS_PER_PAGE = 10;

const EditProductBankCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    defaultAllPosts,
    refetchCustomProduct,
    setRemovePosts,
    removePosts,
  } = props;
  const [allPosts, setAllPosts] = useState([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();
  useEffect(() => {
    setPostIds(post_ids);
    setAllPosts(defaultAllPosts);
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [post_ids, defaultQuery, defaultAllPosts]);
  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    user_id || "",

    POSTS_PER_PAGE,
    query,
    username || ""
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query
    );

  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

  return (
    <>
      <div className="margin-added-content">
        <AddedContentTable
          posts={allPosts}
          username={username}
          user_id={user_id}
          setAllPosts={setAllPosts}
          componentName={componentName}
          handleFeed={handleFeed}
          refetch={refetch}
          setPostIds={setPostIds}
          setRemovePosts={setRemovePosts}
        />
        <SearchInput
          placeholder="Search your product bank"
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setCurrentPage(1);
            setQuery("");
            setDefaultQuery("");
            refetch();
            setCurrentPage(1);
          }}
        />
      </div>

      {/* Wrap the ContentTable component with InfiniteScroll */}

      <AllContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        posts={allPosts}
        query={query}
        isLoading={isLoading}
        setAllPosts={setAllPosts}
        componentName={componentName}
        setRemovePosts={setRemovePosts}
        refetch={refetch}
      />

      {/* Render the Pagination component */}
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

// const AddedContentTable = (props: any) => {
//   const {
//     username,
//     user_id,
//     posts,
//     setAllPosts,
//     componentName,
//     handleFeed,
//     refetch,
//     setRemovePosts,
//     setPostIds,
//   } = props;
//   const [checkedValues, setCheckedValues] = useState<any>([]);
//   useEffect(() => {
//     setCheckedValues(posts);
//   }, [posts]);

//   // const onMinus = async (post_id: string) => {
//   //   const postData = postIds.find((post: any) => post.table_id === post_id);
//   //   await removeProductBankQuery(post_id, user_id, postData, componentName);

//   //   let updatedPostArray = postIds.filter(
//   //     (postId: any) => postId.table_id !== post_id
//   //   );
//   //   setPostIds(updatedPostArray);
//   //   handleFeed(updatedPostArray);
//   //   setAllPosts(updatedPostArray);
//   //   refetch()

//   // };
//   const onMinus = (post_id: string) => {
//     const postData = checkedValues.find(
//       (post: any) => post.table_id === post_id
//     );
//     // const removeProductPromise = removeProductBankQuery(post_id, user_id, postData, componentName);

//     setRemovePosts((prev: any) => [...(prev || []), postData]);
//     const updatedPostArray = checkedValues.filter(
//       (postId: any) => postId.table_id !== post_id
//     );

//     setPostIds(updatedPostArray);
//     setCheckedValues(updatedPostArray);
//     handleFeed(updatedPostArray);
//     setAllPosts(updatedPostArray);

//     // Promise.all([removeProductPromise, refetch()]);
//   };

//   const [data, setData] = useState<any>([]);
//   const [value, setValue] = useState<string>();
//   const [edit, setEdit] = useState(false);
//   const [editIndex, setEditIndex] = useState("");

//   const handleDone = async (post_id: string) => {
//     const { data: user, error } = await supabase
//       .from("product_bank")
//       .update({ name: value })
//       .eq("product_id", post_id)
//       .eq("user_id", user_id);
//     const postData = checkedValues.find(
//       (post: any) => post.table_id === post_id
//     );
//     postData.table_name = value;

//     setEdit(false);
//     setEditIndex("");
//   };

//   const SkeletonStatBox = () => {
//     return (
//       <div className="skeletonDiv">
//         <Skeleton className="skeletonStyle" />
//       </div>
//     );
//   };

//   return (
//     <>
//       <div className="addedContent">
//         <h5 className="addedContentTitle">
//           {componentName ? componentName : "Carousel Title"}
//         </h5>
//         <div className="addedSvg">
//           {checkedValues && checkedValues.length ? (
//             checkedValues.map((post: any, index: any) => (
//               <React.Fragment key={index}>
//                 <div key={index} className="feed-tab-post-container added-post">
//                   <div className="feed-tab-media-wrapper">
//                     <Button
//                       onClick={() => onMinus(post.table_id)}
//                       className="carousel-content-remove-btn"
//                     >
//                       Remove
//                     </Button>

//                     <>
//                       {post.table_image ? (
//                         <>
//                           {post.table_image.split(",").length > 1 &&
//                           post.table_image
//                             .split(",")
//                             .every((url: any) => url.trim() !== "") ? (
//                             <Carousel
//                               dotPosition="bottom"
//                               className="feed-tab-media-carousel"
//                             >
//                               {post.table_image
//                                 .split(",")
//                                 .map((media_url: any, index: any) => (
//                                   <React.Fragment key={index}>
//                                     {media_url.includes("video") ? (
//                                       // <video

//                                       //   className="feed-tab-media-check"
//                                       //   autoPlay
//                                       //   playsInline
//                                       //   muted
//                                       //   loop
//                                       //   poster={
//                                       //     post.thumbnail &&
//                                       //     post.thumbnail.split(",").length &&
//                                       //     post.thumbnail.split(",")[index]
//                                       //       ? post.thumbnail.split(",")[index]
//                                       //       : "/assets/dummy_video.png"
//                                       //   }
//                                       // >
//                                       //   <source src={media_url} />
//                                       // </video>
//                                       <div className="h-255">
//                                         <VideoComponent
//                                           media_url={media_url}
//                                           thumbnail={
//                                             post.thumbnail &&
//                                             post.thumbnail.split(",").length &&
//                                             post.thumbnail.split(",")[index]
//                                               ? post.thumbnail.split(",")[index]
//                                               : "/assets/dummy_video.png"
//                                           }
//                                         />
//                                       </div>
//                                     ) : (
//                                       <img
//                                         className="feed-tab-media-check"
//                                         src={media_url}
//                                         alt="post"
//                                       />
//                                     )}
//                                   </React.Fragment>
//                                 ))}
//                             </Carousel>
//                           ) : (
//                             <>
//                               {post.table_image.includes("video") ? (
//                                 // <video
//                                 //   style={{
//                                 //     maxHeight: "300px",
//                                 //     whiteSpace: "nowrap",
//                                 //     overflowY: "scroll",
//                                 //     borderRadius: "1rem",
//                                 //   }}
//                                 //   className="feed-tab-media-check"
//                                 //   autoPlay
//                                 //   playsInline
//                                 //   muted
//                                 //   loop
//                                 //   poster={
//                                 //     post.thumbnail
//                                 //       ? post.thumbnail
//                                 //       : "/assets/dummy_video.png"
//                                 //   }
//                                 // >
//                                 //   <source src={post.table_image} />
//                                 // </video>
//                                 <div className="h-255">
//                                   <VideoComponent
//                                     media_url={post.table_image}
//                                     thumbnail={post.table_image}
//                                   />
//                                 </div>
//                               ) : (
//                                 <img
//                                   className="feed-tab-media-check"
//                                   src={
//                                     post.table_image
//                                       ? post.table_image
//                                       : "/assets/dummy_video.png"
//                                   }
//                                   alt="post"
//                                 />
//                               )}
//                             </>
//                           )}
//                         </>
//                       ) : (
//                         <img
//                           className="feed-tab-media-check"
//                           src={post?.table_image}
//                           alt="post"
//                         />
//                       )}
//                     </>
//                   </div>

//                   {edit === true && editIndex === index ? (
//                     <>
//                       <Input
//                         value={value}
//                         onChange={(e: any) => setValue(e.target.value)}
//                       />{" "}
//                       <HiCheck
//                         onClick={() => {
//                           handleDone(post.table_id);
//                         }}
//                       />
//                     </>
//                   ) : (
//                     <p
//                       style={{
//                         textAlign: post.table_name !== "" ? "left" : "center",
//                       }}
//                     >
//                       {post?.table_name}{" "}
//                       <HiPencil
//                         onClick={() => {
//                           setValue(post.table_name);
//                           setEdit(true);
//                           setEditIndex(index);
//                         }}
//                       />
//                     </p>
//                   )}
//                 </div>
//               </React.Fragment>
//             ))
//           ) : (
//             <>
//               <>
//                 <div className="feed-tab-post">
//                   {[...Array(4)].map((_, index) => (
//                     <SkeletonStatBox />
//                   ))}
//                 </div>
//               </>
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// const ContentTable = (props: any) => {
//   const {
//     username,
//     user_id,
//     data,
//     posts,
//     isLoading,
//     query,
//     setAllPosts,
//     componentName,
//     setRemovePosts,
//   } = props;
//   const [checkedValues, setCheckedValues] = useState<any>([]);
//   useEffect(() => {
//     setCheckedValues(posts);
//   }, [posts]);
//   const onAdd = async (post_id: any) => {
//     if (!checkedValues?.includes(post_id)) {
//       const postData = data?.pages?.[0].find(
//         (post: any) => post.table_id === post_id
//       );

//       const updatedCheckedValues = [postData, ...checkedValues];
//       setCheckedValues(updatedCheckedValues);
//       props.handleFeed(updatedCheckedValues);

//       setAllPosts((prev: any) => [...prev, postData]);
//     }
//   };

//   const onMinus = async (post_id: any) => {
//     const postData = checkedValues.find(
//       (post: any) => post.table_id === post_id
//     );

//     // const removeProductPromise = removeProductBankQuery(post_id, user_id, postData, componentName);
//     setRemovePosts((prev: any) => [...(prev || []), postData]);
//     const updatedPostArray = checkedValues.filter(
//       (postId: any) => postId.table_id !== post_id
//     );

//     setAllPosts(updatedPostArray);
//     props.handleFeed(updatedPostArray);
//     // setAllPosts(updatedPostArray);

//     // Promise.all([removeProductPromise, props.refetch()]);
//   };

//   return (
//     <>
//       {!isLoading ? (
//         <>
//           {data?.pages ? (
//             <div className="feed-tab-posts-container">
//               {data.pages.map((group: any, pageIndex: number) => (
//                 <Fragment key={pageIndex}>
//                   {group.map((image: any, index: number) => (
//                     <div
//                       key={index}
//                       className="feed-tab-post-container post-width"
//                     >
//                       <div
//                         className="feed-tab-media-wrapper"
//                         style={
//                           image.platform === "newsletter"
//                             ? {
//                                 backgroundColor: "#efeded",
//                                 textAlign: "center",
//                               }
//                             : {}
//                         }
//                       >
//                         <React.Fragment key={index}>
//                           {Array.isArray(checkedValues) &&
//                           checkedValues.some(
//                             (elem: any) => elem.table_id === image.table_id
//                           ) &&
//                           posts.some(
//                             (elem: any) => elem.table_id === image.table_id
//                           ) ? (
//                             <Button
//                               onClick={() => onMinus(image.table_id)}
//                               className="carousel-content-remove-btn"
//                             >
//                               Remove
//                             </Button>
//                           ) : (
//                             <Button
//                               onClick={() => onAdd(image.table_id)}
//                               className="carousel-content-add-btn"
//                             >
//                               Add
//                             </Button>
//                           )}
//                         </React.Fragment>
//                         {/* ))} */}

//                         {image.platform !== "newsletter" ? (
//                           <>
//                             {image.table_image ? (
//                               <>
//                                 {image.table_image.split(",").length > 1 &&
//                                 image.table_image
//                                   .split(",")
//                                   .every((url: any) => url.trim() !== "") ? (
//                                   <Carousel
//                                     dotPosition="bottom"
//                                     className="feed-tab-media-carousel"
//                                   >
//                                     {image.table_image
//                                       .split(",")
//                                       .map((media_url: any, index: any) => (
//                                         <React.Fragment key={index}>
//                                           {media_url.includes("video") ? (
//                                             // <video
//                                             //   // style={{
//                                             //   //   maxHeight: "300px",
//                                             //   // }}
//                                             //   className="feed-tab-media-check"
//                                             //   autoPlay
//                                             //   playsInline
//                                             //   muted
//                                             //   loop
//                                             //   poster={
//                                             //     image.thumbnail &&
//                                             //     image.thumbnail.split(",")
//                                             //       .length &&
//                                             //     image.thumbnail.split(",")[
//                                             //       index
//                                             //     ]
//                                             //       ? image.thumbnail.split(",")[
//                                             //           index
//                                             //         ]
//                                             //       : "/assets/dummy_video.png"
//                                             //   }
//                                             // >
//                                             //   <source src={media_url} />
//                                             // </video>
//                                             <div className="h-255">
//                                               <VideoComponent
//                                                 media_url={media_url}
//                                                 thumbnail={
//                                                   image.thumbnail &&
//                                                   image.thumbnail.split(",")
//                                                     .length &&
//                                                   image.thumbnail.split(",")[
//                                                     index
//                                                   ]
//                                                     ? image.thumbnail.split(
//                                                         ","
//                                                       )[index]
//                                                     : "/assets/dummy_video.png"
//                                                 }
//                                               />
//                                             </div>
//                                           ) : (
//                                             <img
//                                               className="feed-tab-media-check"
//                                               src={media_url}
//                                               alt="post"
//                                             />
//                                           )}
//                                         </React.Fragment>
//                                       ))}
//                                   </Carousel>
//                                 ) : (
//                                   <>
//                                     <>
//                                       {image.table_image.includes("video") ? (
//                                         // <video
//                                         //   style={{
//                                         //     // maxHeight: "300px",
//                                         //     whiteSpace: "nowrap",
//                                         //     overflowY: "scroll",
//                                         //     borderRadius: "1rem",
//                                         //   }}
//                                         //   className="feed-tab-media-check"
//                                         //   autoPlay
//                                         //   playsInline
//                                         //   muted
//                                         //   loop
//                                         //   poster={
//                                         //     image.thumbnail
//                                         //       ? image.thumbnail
//                                         //       : "/assets/dummy_video.png"
//                                         //   }
//                                         // >
//                                         //   <source src={image.table_image} />
//                                         // </video>
//                                         <div className="h-255">
//                                           <VideoComponent
//                                             media_url={image.table_image}
//                                             thumbnail={image.table_image}
//                                           />
//                                         </div>
//                                       ) : (
//                                         <img
//                                           className="feed-tab-media-check"
//                                           src={
//                                             image.table_image
//                                               ? image.table_image
//                                               : "/assets/dummy_video.png"
//                                           }
//                                           alt="post"
//                                         />
//                                       )}
//                                     </>
//                                   </>
//                                   // <img
//                                   //   className="feed-tab-media-check"
//                                   //   src={
//                                   //     image.thumbnail
//                                   //       ? image.thumbnail
//                                   //       : "/assets/dummy_video.png"
//                                   //   }
//                                   //   alt="post"
//                                   // />
//                                 )}
//                               </>
//                             ) : (
//                               <img
//                                 className="feed-tab-media-check"
//                                 src={
//                                   image.thumbnail
//                                     ? image.thumbnail
//                                     : "/assets/dummy_video.png"
//                                 }
//                                 alt="post"
//                               />
//                             )}
//                           </>
//                         ) : (
//                           <div>
//                             <h4 className="mt-7">
//                               {new Intl.DateTimeFormat("en-US", {
//                                 year: "numeric",
//                                 day: "numeric",
//                                 month: "long",
//                               }).format(new Date(image.post_date))}
//                             </h4>
//                             <h4> Newsletter</h4>
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   ))}
//                 </Fragment>
//               ))}
//             </div>
//           ) : (
//             <p>No shoppable content present for '{query}'</p>
//           )}
//         </>
//       ) : (
//         ""
//       )}
//     </>
//   );
// };

export default EditProductBankCheckboxComponent;
