import React from "react";
import Skeleton from "react-loading-skeleton";

export const BannerSkeleton = () => {
  const SkeletonStatBox = () => {
    return (
      <div
        className="banner-skeleton"
      >
        <Skeleton
          className="bannerSkeleton"
        />
      </div>
    );
  };

  return (
    <>
      <SkeletonStatBox />
    </>
  );
};
