

import { useState } from "react";
import "./PostCarousel.css";

export default function Caption(props:any) {
    const { caption } = props;
    const [showAll, setShowAll] = useState(false);
    const maxCharLength = 150;

    // Function to convert URLs and phone numbers to clickable links
    const convertToClickable = (text: string) => {
        // Regular expression pattern to match URLs
        const urlPattern = /(https?:\/\/\S+)/g;

        // Regular expression pattern to match phone numbers (10 digits)
        const phonePattern = /\b\d{10}\b/g;

        // Keep track of processed phone numbers
        const processedPhoneNumbers = new Set<string>();

        // Split the text by URLs and phone numbers
        const parts = text.split(new RegExp(`(${urlPattern.source}|${phonePattern.source})`));

        // Map over the parts and create clickable links for URLs and phone numbers
        return parts.map((part, index) => {
            if (part && part.match(urlPattern)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            } else if (part && part.match(phonePattern) && !processedPhoneNumbers.has(part)) {
                processedPhoneNumbers.add(part); // Add phone number to processed set
                return (
                    <a key={index} href={`tel:${part}`}>
                        {part}
                    </a>
                );
            } else {
                return part;
            }
        });
    };

    return (
        <>
        {caption && caption.length > 0 && (
            
        <div className="posts-caption" style={{ background:caption.trim().length ? "#fcfcfc" : "#fff" }}>
            
            {showAll || caption.length <= maxCharLength ? (
                convertToClickable(caption)
            ) : (
                <>
                    {convertToClickable(caption.substring(0, maxCharLength))}
                    <span
                        className="posts-caption-more"
                        onClick={() => {
                            setShowAll(true);
                        }}
                    >
                        {!props.isNewsletter ? (
                            <>... see more</>
                        ) : (
                            <button className="posts-caption-more-btn" onClick={() => props.setIsModalOpen(true)}>... see more</button>
                        )}
                    </span>
                </>
            )}
        </div>
        )}
        </>
    );
}
