import { Tooltip } from "antd";
import { useState } from "react";
import { BsFilesAlt, BsFillCheckCircleFill,	BsLink45Deg} from "react-icons/bs";
import "./CopyToClipboard.css";
export default function CopyToClipboard({
	textToCopy,
	buttonText = "Live Page",
}: {
	textToCopy: string;
	buttonText?: string;
}) {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(textToCopy).then(() => {
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		});
	};
	return (
		<Tooltip
			placement='bottom'
			open={copied}
			title={
				<div
					className="tooltip-style"
				>
					<BsFillCheckCircleFill />
					<p className="m-0">Copied!</p>
				</div>
			}
		>
			<div className='copy-clipboard-btn-wrapper' onClick={copyToClipboard}>
				<p className="m-0">{buttonText}</p>
				<BsLink45Deg/>
			</div>
		</Tooltip>
	);
}
