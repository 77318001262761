import { useQuery } from "react-query";
import axios from "axios";
import {
  updateAltQuery,
  updateTimeout,
} from "../SupabaseServices/NoSearchesDatabase";
import track, { TRACKING_TYPES } from "../SupabaseServices/Track";
import { useLocation } from "react-router-dom";

export function useGetAlternateQuery(
  user_id: any,
  dataLoading: boolean,
  data: any,
  query: any
) {
  const location = useLocation();
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://api.apilayer.com/dymt/did_you_mean_this?q=${query}`,
    headers: {
      apikey: "LPEX2rGKlH9X3PTSE2QKo2poEAI7sIas",
    },
    timeout: 3000,
  };

  async function getAlternateQuery() {

    // setTimeout(async () => {
    //   if (
    //     !query ||
    //     query === "" ||
    //     !data ||
    //     data === undefined ||
    //     data.length === 0
    //   ) {
    //     // track("no search", TRACKING_TYPES.SEARCH, {
    //     //   pathname: location.pathname,
    //     //   details: query,
    //     //   user_id: user_id,
    //     // });
    //     if (query.length >= 3 && !query.startsWith("#")) {
    //       try {
    //         var res: any = await axios.request(config);
  
    //         const alt = res.data.result;
  
    //         updateAltQuery(user_id, query, alt);
    //         return alt;
    //       } catch (error) {
    //         updateTimeout(user_id, query);
    //       }
    //     }
    //   }
    // });
  }

  async function alt() {
    
  }
  return useQuery(["getAlternateQuery", user_id, query], getAlternateQuery, {
    refetchOnWindowFocus: false,
  });
}
