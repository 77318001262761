import React, { useEffect, useState } from "react";
import { updatePhoneNo } from "../../../../Utils/SupabaseServices/UserDatabase";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { supabase } from "../../../../config/supabaseClient";
import { Button, Modal, Radio, RadioChangeEvent } from "antd";

const Phone = (props: any) => {
  const { user_id, username, userData, setIsPhone } = props;

  const [phoneNumber, setPhoneNumber] = useState<any>(userData.phoneNo || "");
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(phoneNumber); // Store the original phone number

  const [editPhoneNo, setEditPhoneNo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalError, setModalError] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const [isPhoneVerify, setIsPhoneVerify] = useState<any>();
  const [isTabsLocked, setIsTabsLocked] = useState<any>();

  const [value, setValue] = useState(1);
  useEffect(() => {
    const handleStorageChange = () => {
      const data = localStorage.getItem("phone_verify");
      if (data === "false") {
        setIsTabsLocked(true);
      } else {
        setIsTabsLocked(false);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial setup (run once)
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window]);
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const [otp, setOTP] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  function handleUpdateValue(value: any, option: any) {
    if (option === "phone_no") {
      setPhoneNumber(value);
    }
  }
  const doneEdit = async (option: any) => {
    if (option === "phone_no") {
      localStorage.setItem("phone_verify", "false");

      // let phNumber = `${value}${phoneNumber}`;
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        setModalTitle("Enter Valid Phone Number!");
        setIsPhoneVerify("");
        showModal();
        setIsPhoneVerify("");
        // await updatePhoneNo(user_id, username, phoneNumber);
        setIsPhone(false);
      } else {
        await updatePhoneNo(user_id, username, phoneNumber);
        setIsPhoneVerify(false);
      }
    }
  };
  async function sendPhoneNumberOTP() {
    setOTP("");
    setModalError("");
    setIsModalOpen(true);
    setModalContent(true);
    setModalTitle("Verify Phone Number");
    let phNumber = `${value}${phoneNumber}`;
    const { data, error } = await supabase.auth.signInWithOtp({
      phone: phNumber,
    });
    if (error !== null) {
      setModalError("Error in sending OTP. Try again after 60 seconds");
    }
  }

  async function verifyPhoneNumber() {
    let phNumber = `${value}${phoneNumber}`;

    await supabase.auth
      .verifyOtp({
        phone: phNumber,
        token: otp,
        type: "sms",
      })
      .then((res: any) => {
        if (res.error === null) {
          setIsModalOpen(false);
          setIsPhone(true);
          setIsPhoneVerify(true);
          localStorage.removeItem("phone_verify");
        } else {
          setModalError("Invalid OTP");
        }
      });
  }
  const cancelEdit = () => {
    setPhoneNumber(initialPhoneNumber); // Revert to original phone number
    setEditPhoneNo(false);
    setValue(1)
  };
  return (
    <div className="account-grid-item">
      <div>
        <h3 className="account-grid-item-key">
          Phone{" "}
          <BsInfoCircle className="info-tooltip" data-tooltip-id="phone" />
        </h3>

        <h3 className="account-grid-item-value" id="phone-number">
          {!editPhoneNo ? (
            phoneNumber ? (
              phoneNumber
            ) : (
              "Add Phone Number"
            )
          ) : (
            <>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1} id="us">US</Radio>
                <Radio value={91} id="india">India</Radio>
              </Radio.Group>
              <input
                type="text"
                id="phone_no"
                className="account-value-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  if (value === "" || /^\d*$/.test(value)) {
                    handleUpdateValue(value, "phone_no");
                  }
                }}
                // onChange={(e: any) => handleUpdateValue(e, "phone_no")}
                value={phoneNumber}
              />
            </>
          )}
          </h3>
          {!editPhoneNo ? (
            // <button
            //   className="account-edit-btn-phn"
            //   onClick={() => {
            //     setEditPhoneNo(true);
            //   }}
            // >
         
            <Button
            className="changeButton"
              onClick={() => {
                setEditPhoneNo(true);
              }}
              id="phone-edit"
            >
                {phoneNumber ?
             <>Change</>:<>Add Number</>}
            </Button>
          ) : (
            <div className="saveButtonDiv">
            <Button
            className="saveButton"
              onClick={() => {
                setEditPhoneNo(false);
                doneEdit("phone_no");
              }}
              id="save-phone"
            >
              Save
            </Button>
            <Button
            className="saveButton"
            onClick={cancelEdit}
            >
              Cancel
            </Button>
          </div>
          )}
          <div>
            {/* {isPhoneVerify === false || isTabsLocked ? (
              <button className="verify-btn" onClick={sendPhoneNumberOTP}>
                Verify Phone Number!
              </button>
            ) : isPhoneVerify === true ? (
              <p className="verified-text">Verified</p>
            ) : (
              ""
            )} */}
          </div>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
        footer={null}
        // footer={modalTitle==="Verify Phone Number"?null:ok}
      >
        {modalContent === true ? (
          <>
            <input
              type="text"
              className="otp-input"
              placeholder="Enter 6 digit OTP"
              onChange={(e: any) => setOTP(e.target.value)}
              value={otp}
            />
            <button className="modal-verify-btn" onClick={verifyPhoneNumber}>
              Verify
            </button>
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default Phone;
