import { useQuery } from "react-query";
import {
  fetchCreatorControls,
  fetchFollowerControls,
} from "../SupabaseServices/ControlsDatabase";
import { fetchGlobalControls } from "../SupabaseServices/GlobalControlsDatabase";

export function useLoadControls(user_id: any, page: any) {
  async function getFeedControls() {

      let controls;
      if (page === "follower") {
        controls = await fetchFollowerControls(user_id);
      } else if (page === "creator") {
        controls = await fetchCreatorControls(user_id);
      } else if (page === "global") {
        controls = await fetchGlobalControls();
      }

      if (!controls || controls === undefined) {
        return [];
      } else if (controls) {
        return controls;
      }
      //  else {
      //   throw new Error("Failed to fetch controls");
      // }
    
  }
  return useQuery(
    ["getFeedControls", user_id, page],
    getFeedControls,
    {
      refetchOnWindowFocus: true,
    }
  );
}
