import React from "react";
import { isMobile } from "react-device-detect";
import { GrPrevious, GrNext } from "react-icons/gr";
import "./CustomPagination.css"

const CustomPagination = (props: any) => {
  const {
    totalItems,
    currentIndex,
    handlePrevPage,
    handleNextPage,
  } = props;
  const buttonStyle = {
    background: "none",
    border: "none",
    cursor: "pointer",
  };
  const itemsPerPage =isMobile?1: 3;

  const disabledStyle = {
    cursor: "not-allowed",
    opacity: 0.5,
  };

  return (
    <>
      {totalItems > itemsPerPage && (
        <div className="total-pages">
          <button
            style={currentIndex === 0 ? { ...buttonStyle, ...disabledStyle } : buttonStyle}
            onClick={handlePrevPage}
            disabled={currentIndex === 0}
          >
            <GrPrevious />
          </button>
          <button
            style={currentIndex >= totalItems - itemsPerPage ? { ...buttonStyle, ...disabledStyle } : buttonStyle}
            onClick={handleNextPage}
            disabled={currentIndex >= totalItems - itemsPerPage}
          >
            <GrNext />
          </button>
        </div>
      )}
    </>
  );
};

export default CustomPagination;



// import React from "react";
// import { GrPrevious, GrNext } from "react-icons/gr";

// const CustomPagination = (props: any) => {
//   const {
//     totalItems,
//     itemsPerPage,
//     currentEnd,
//     currentStart,
//     // currentPage,
//     // onPageChange,
//   } = props;
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

//   const handlePrevPage = () => {
//     // if (currentStart > 1) {
//     //   // onPageChange(currentPage - 1);
//     // }
//     // props.setCurrentStart((prev: any) => prev - 1);
//     props.setCurrentEnd((prev: any) => prev - 1);
//     // }
//   };

//   const handleNextPage = () => {
//     // if (currentStart < totalItems) {
//     //   onPageChange(currentPage + 1);
//     // }
//     // props.setCurrentStart((prev: any) => prev + 1);
//     props.setCurrentEnd((prev: any) => prev + 1);
//     // }
//   };

//   const buttonStyle = {
//     background: "none",
//     border: "none",
//     cursor: "pointer",
//   };

//   const disabledStyle = {
//     cursor: "not-allowed",
//     opacity: 0.5,
//   };
//   return (
//     <>
//     {totalItems>itemsPerPage?
//     <div style={{ margin: "auto", marginRight: "0px",textWrap: "nowrap", }}>
//       <button
//         style={
//           currentEnd > itemsPerPage
//             ? { ...buttonStyle, ...disabledStyle }
//             : buttonStyle
//         }
//         onClick={handlePrevPage}
//         disabled={currentStart === 0}
//       >
//         <GrPrevious />
//       </button>
//       <button
//         style={
//           currentEnd === totalItems
//             ? { ...buttonStyle, ...disabledStyle }
//             : buttonStyle
//         }
//         onClick={handleNextPage}
//         disabled={currentEnd === totalItems}
//       >
//         <GrNext />
//       </button>
//     </div>:""}
//     </>
//   );
// };

// export default CustomPagination;


