import { Alert, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { storeYoutubeChannel } from '../../../Utils/SupabaseServices/ContentDatabase';
import { useParams } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';

const YoutubeIntegrationModal = (props:any) => {
    const {showChannelIdModal,user_id,data,refetchData}=props
    const [ytChannelId, setYtChannelId] = useState(data?.youtube_channel_id);
    const { username } = useParams();
    const [errorChannelId, setErrorChannelId] = useState("");
    const [loadingYoutubeConnect, setLoadingYoutubeConnect] = useState(false);
    const [showChannelIdInstructions, setShowChannelIdInstructions] =
    useState(false);
  return (
    <>  <Modal
    title="Connect Youtube Channel"
    open={showChannelIdModal}
    onOk={async () => {
      props.setShowChannelIdModal(true);

      const { error, message } = await storeYoutubeChannel(
        user_id as string,
        ytChannelId,
        username
      );
      if (error) {
        setErrorChannelId(message);
      } else {
        refetchData();
        props.setShowChannelIdModal(false);
      }
      setLoadingYoutubeConnect(false);
    }}
    onCancel={() => {
      setErrorChannelId("");
      props.setShowChannelIdModal(false);
    }}
    okText="Connect"
    okButtonProps={{disabled:!ytChannelId, loading: loadingYoutubeConnect }}
    cancelButtonProps={{ disabled: loadingYoutubeConnect }}
  >
    <p>
      By connecting your youtube channel, all your videos will be displayed
      in the feed of your main page. In the content tab you will be able to
      hide or delete the videos you don't want to show.{" "}
    </p>
    {loadingYoutubeConnect ? (
      <div
        className='spin-integration'
      >
        <Spin />
      </div>
    ) : (
      <>
        {errorChannelId && (
          <Alert
            type="error"
            message={`Error: ${errorChannelId}`}
            showIcon
            className='alert-margin'
          />
        )}

        <p>To connect just enter your Channel ID:</p>
        <Input
          placeholder="Channel ID"
          value={ytChannelId}
          onChange={(e) => {
            setYtChannelId(e.target.value);
          }}
        />
        {!showChannelIdInstructions ? (
          <p
            className="account-social-content-channelid-text"
            onClick={() => setShowChannelIdInstructions(true)}
          >
            Find Your Channel ID
          </p>
        ) : (
          <div className="account-social-content-channelid-ins">
            <p>
              You can see your channel ID in your advanced{" "}
              <a
                href="http://www.youtube.com/account_advanced"
                className="account-social-content-channelid-link"
                target="_blank"
                rel="noreferrer"
              >
                account settings
              </a>
            </p>
            <ol>
              <li>Sign in to YouTube</li>
              <li>
                In the top right, click your <b>profile picture</b>{" "}
                <BsChevronRight  className='chevron-right' />
                <b>Settings</b>
              </li>
              <li>
                From the left menu, select <b>Advanced settings</b>
              </li>
              <li>
                You’ll see your channel’s user and channel ID, copy your{" "}
                <b>channel ID</b>
              </li>
            </ol>
          </div>
        )}
      </>
    )}
  </Modal></>
  )
}

export default YoutubeIntegrationModal