import React, { useState } from "react";
import SocialLinks from "../../../../components/SocialLinks/SocialLinks";
import ActiveSocialLinks from "../../../../components/SocialLinks/ActiveSocialLinks";
import { Button } from "antd";
import { ColorModal } from "../../Controls/LiveViewControls/Components/GeneralControls";

const SocialLinksComponent = (props: any) => {
  const { defaultColor, defaultTextColor, username, user_id,defaultEdit,setDefaultEdit } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textColor, setTextColor] = useState(defaultTextColor);
  const [textColorLoading, setTextColorLoading] = useState<any>(false);
  const handleColor = (bgColor: string, textColor: string) => {
    setTextColorLoading(true);
    setTextColor(textColor);
    setTimeout(() => {
      setTextColorLoading(false);
    }, 500);
  };
  return (
    <div className="mt-8">
      <div className="account-values-profiles">
        <div className="account-social-logins-heading">
          Active Social Links Preview &nbsp;
          <Button
            size="small"
            className="solid-bg-button"
            onClick={(prev) => setIsModalOpen(true)}
          >
            Preview
          </Button>
        </div>
        <ActiveSocialLinks
          textColor={textColor}
          textColorLoading={textColorLoading}
        />
      </div>

      <div className="account-values-profiles">
        <div className="account-social-logins-heading">Available Links</div>
        <SocialLinks defaultEdit={defaultEdit} setDefaultEdit={setDefaultEdit}/>
      </div>
      <ColorModal
        fromScreen="socialLinksScreen"
        ModalTitle="Icons Color"
        isColorModalOpen={isModalOpen}
        setIsColorModalOpen={setIsModalOpen}
        handleColor={handleColor}
        username={username}
        user_id={user_id}
        defaultColor={defaultColor}
        defaultTextColor={defaultTextColor}
      />
    </div>
  );
};

export default SocialLinksComponent;
