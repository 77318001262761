import React, { useState } from "react";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import AccountSkeleton from "../../../components/skeleton/DashboardView/accountSkeleton";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { Tabs } from "antd";
import { Tooltip } from "react-tooltip";
import "./ManageLinks.css";
import MyLinksComponent from "./TabComponents/MyLinksComponent";
import SocialLinksComponent from "./TabComponents/SocialLinksComponent";

const ManageLinks = (props: any) => {
  const { username } = props;
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
  const [defaultEdit, setDefaultEdit] = useState(false);
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  const items = [
    {
      key: "My Links",
      label: "My Links",
      children: (
        <MyLinksComponent
          username={username}
          isLinksModalOpen={isLinksModalOpen}
          setIsLinksModalOpen={setIsLinksModalOpen}
        />
      ),
    },
    {
      key: "Social Links",
      label: "Social Links",
      children: (
        <SocialLinksComponent
          defaultColor={userData?.brand_color}
          defaultTextColor={userData?.brand_text_color}
          username={username}
          user_id={userData?.user_id}
          defaultEdit={defaultEdit}
          setDefaultEdit={setDefaultEdit}
        />
      ),
    },
  ];
  return (
    <div>
      {userLoading ? (
        <AccountSkeleton />
      ) : (
        <>
          <div className="details">
            <h1 className="dashboard-mobile-hide">
              {StaticVariables().MANAGE_ALL_LINKS_TITLE}
            </h1>
            <div className="links-wrapper">
              <div className="row" id="analytics-row">
                <Tabs
                  defaultActiveKey="General"
                  items={items}
                  type="card"
                  onTabClick={() => {setDefaultEdit(false);}}
                  />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageLinks;
