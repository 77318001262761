import { Button, Input, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../../../../config/supabaseClient";

export const EditHighlightLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    defaultName,
    link,
    cta,
    image,
    setIsButtonDisabled,
  } = props;
  const [name, setName] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [shopImage, setShopImage] = useState("");

  const [shopCTA, setShopCTA] = useState("");

  useEffect(() => {
    setName(defaultName);
    setShoplink(link);
    setShopCTA(cta);
    setShopImage(image);
  }, [defaultName, link, cta, image]);

  useEffect(() => {
    props.handleData(shopImage, name, shoplink, "link", shopCTA);
  }, [shopImage, name, shoplink, shopCTA]);

  const handleInputChange = (value: any, setter: any) => {
    setter(value);
    setIsButtonDisabled(!value);
  };

  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      fileList = fileList.map((file: any) => {
        if (file.response) {
          file.url = file.response.url;
          file.staus = "uploaading";
        }
        return file;
      });

      setTimeout(() => {
        info.file.status = "done";
      }, 1000);

      const { data: highlightslist }: any = await supabase.storage
        .from("highlight_section")
        .list(`${username}/links/${name}`, { limit: 5000 });

      for (let item of highlightslist) {
        await supabase.storage
          .from("highlight_section")
          .remove([`${username}/links/${name}/${item.name}`]);
      }

      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("highlight_section")
        .upload(
          `${username}/links/${name}/${
            info.file.type.split("/")[0]
          }${post_id}.${info.file.type.split("/")[1]}`,
          info.file.originFileObj
            ? info.file.originFileObj
            : info.fileList[info.fileList.length - 1].originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("highlight_section")
        .getPublicUrl(
          `${props.username}/links/${name}/${
            info.file.type.split("/")[0]
          }${post_id}.${info.file.type.split("/")[1]}`
        );
      setShopImage(url.publicUrl);
      
      handleInputChange(url.publicUrl, setShopImage);
      props.setImage(url.publicUrl)
    },
  };

  return (
    <div className="editModal">
      <div className="highlight-data-section edit-highlight">
        <div className="highlight-media">
          <img
            src={shopImage ? shopImage : "/assets/image-bg.jpeg"}
            className="highlight-image"
          />
        </div>

        <div className="highlight-details">
          <div className="highlight-name">{name ? name : "Highlight Name"}</div>
          <div className="highlight-link-tag">
            <Button className="highlight-shop-button">{shopCTA}</Button>
          </div>
        </div>
      </div>
      <div className="fieldGroup">
        <label className="bold-label">Highlight Title</label>
        <Input
          placeholder="Enter Title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
            props.setName(e.target.value);
            props.setErrorMsg("");
          }}
          className="mb-15"
        />
        <label className="bold-label">Button Text</label>
        <Input
          placeholder="Check it Out!"
          value={shopCTA}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setShopCTA);
            props.setCTA(e.target.value);
          }}
          className="mb-15"
        />
        <label className="bold-label">Button Link</label>
        <Input
          placeholder="https://links.com"
          value={shoplink}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setShoplink);
            props.setLink(e.target.value);
          }}
          className="mb-15"
        />
        <div className="image-upload-section">
          <Upload {...uploadProps} multiple={false} maxCount={1}>
            <Button
              disabled={!name}
              className="uploadButton"
            >
              <div className="text-center">
                <p className="textContainerHeading">
                  Click to select image or drag & drop to this area to upload
                </p>
                <p className="upload-text"> JPG, JPEG, PNG, HEIC, or WEBP</p>
              </div>
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  );
};
