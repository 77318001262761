import { useEffect, useState } from "react";
import AuthBackBtn from "../../../components/AuthBackBtn/authBackBtn";
import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import AuthTitle from "../../../components/AuthTitles/authTitle";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import { updateUserPassword } from "../../../Utils/SupabaseServices/UserDatabase";
import { useLocation } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";

const UpdatePassword = () => {
	const [password, setPassword] = useState<any>("");
	const { user } = useAuth();
	const user_id = user?.id || '';
	const [passwordError, setPasswordError] = useState("");
	const [msg, setMsg] = useState<any>();
	const [error, setError] = useState<any>();
	const location = useLocation();
	useEffect(() => {
		track(location.pathname, TRACKING_TYPES.PAGE, {
			pathname: location.pathname,
			user_id:user_id
		});
	}, []);
	async function resetPassword(e: any) {
		e.preventDefault();
		const update = await updateUserPassword(password);
		if (update) {
			setMsg("Password Updated Successfully. Login Now!");
			setError("");
		} else {
			setMsg("");
			setError("Couldn't update password");
		}
	}
	function SubmitButton(props: any) {
		if (password) {
			return <button className='submit-btn'>{props.name}</button>;
		} else {
			return (
				<button className='submit-btn'>
					{props.name}
				</button>
			);
		}
	}
	const validatePassword = (e: any) => {
		e.preventDefault();

		if (password.length < 6) {
			setPasswordError("password should be atleast 6 letters");
		} else {
			setPasswordError("");

			resetPassword(e);
			return true;
		}
	};
	return (
		<div className='auth-container'>
			<div className='auth-options-container'>
				<AuthPagesHeader />
				<div className='providers'>
					<AuthBackBtn route='/login' />
					<AuthTitle isLogin={true} />

					<div>
						<form className='input-form' onSubmit={validatePassword}>
							<div className='input-div'>
								{" "}
								<input
									type='password'
									placeholder='Enter New Password'
									onChange={(e: any) => setPassword(e.target.value)}
									required
								/>
							</div>
							{passwordError ? <ErrorMsg error={passwordError} /> : ""}
							<SubmitButton name='Reset Password' />
						</form>
						{msg ? (
							<div className='success-msg'>
								<p>{msg}</p>
							</div>
						) : error ? (
							<ErrorMsg error={msg} />
						) : (
							""
						)}
					</div>
					<AuthPageSwitch loginPage={true} />
				</div>

				<AuthTerm />
			</div>

			<CreatorQuote />
		</div>
	);
};

export default UpdatePassword;
