import { useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

/**
 * Load Platform Integration Status
 */
export function useLoadIntegrations(userId: string) {
	async function getPlatformIntegrationStatus() {
		const { data, error }: any = await supabase
			.from("user")
			.select("*")
			.eq("user_id", userId);
		if (data && data.length >= 0 && Array.isArray(data)) {
			return data[0];
		} else if (error) {
			throw new Error(error.message);
		} else {
			throw new Error("Failed to fetch posts");
		}
	}

	return useQuery("platformIntegrationStatus", getPlatformIntegrationStatus);
}
