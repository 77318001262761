import { Link } from "react-router-dom";

const AuthTerm = () => {
  return (
    <div className="auth-terms">
      <Link to="/privacy">Terms of use</Link> | <Link to="/privacy">Privacy policy</Link>
      
    </div>
  );
};

export default AuthTerm;
