import React, { useEffect, useState, useRef } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit } from "react-icons/bs";
const VideoComponent = (props: any) => {
  const { media_url, thumbnail } = props;
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);
  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);
  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>
        {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
      </>
    );
  };

  const VideoTag = (props: any) => {
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const {
      media_url,

      thumbnail,
    } = props;

    return (
      <>
        {!Boolean(media_url) ? (
          <div>
            <BsHourglassSplit
            // className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            muted
            // className="feed-tab-media-check"
            //   className={"feed-tab-media-check hover-play"}

            // style={{
            //   maxHeight: "300px",
            //   whiteSpace: "nowrap",
            //   overflowY: "scroll",
            //   borderRadius: "1rem",
            // }}
            preload={"metapost" || "auto"}
            contextMenu="return false;"
            hoverOverlay={
              <div
                className="video-hover"
              ></div>
            }
            pausedOverlay={
              <div
                // style={{ position: "relative", border: "1px solid red" }}
                // className="feed-tab-media-check"
                //   style={{
                //     position: "relative",
                //     maxHeight: "300px",
                //     whiteSpace: "nowrap",
                //     overflowY: "scroll",
                //     borderRadius: "1rem",
                // }}
                // className={"video-component-feed-card-links-image"}
              >
                <img
                  src={thumbnail}
                  alt=""
                  className="h-100"
                  //   style={{
                  //     maxHeight: "300px",
                  //     whiteSpace: "nowrap",
                  //     overflowY: "scroll",
                  //     borderRadius: "1rem",
                  //   }}
                  // className={"video-component-feed-card-links-image"}
                  // className="feed-tab-media-check"
                  loading="eager"
                />

                <OverlayStatus
                  hover={hover}
                  showClickablePlayBtn={showClickablePlayBtn}
                />
              </div>
            }
            loadingOverlay={
              <img
                src={thumbnail}
                alt=""
                className={"video-component-feed-card-links-image"}
                // className={"creator-feed-card-links-image"}
                // className="feed-tab-media-check"
                loading="eager"
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />
        )}
      </>
    );
  };
  return (
    <>
      <VideoTag
        media_url={media_url}
        thumbnail={thumbnail ? thumbnail : "/assets/dummy_video.png"}
      />
    </>
  );
};

export default VideoComponent;
