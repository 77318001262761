import { Link } from "react-router-dom";
import "./ErrorPages.css";
import HeaderDark from "../components/Header/headerDark";

const Error401 = () => {
	async function signOut() {
		localStorage.clear();
	}
	return (
		
		<div className='error-page'>

			<div className='error-page-header'>
				<HeaderDark />
			</div>

			<div className='error-page-error-msg'>
				Authentication Error 😥 <br/>
				Please try logging in again! <br/><br/>
				<button className='error-page-back-btn' onClick={signOut}>
					<Link to='/login'>login</Link>
				</button>
			</div>
		</div>
	);
};

export default Error401;
