import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

export function useLoadSearchBarTags(username: any) {
  async function getSearchBarTags() {
 
    const { data } = await supabase
      .from("user")
      .select("search_bar_tags")
      .eq("username", username)
    //   .order("index");

    if (data && data.length) {
  
    return data[0]?.search_bar_tags
    } else return "";
  }

  return useQuery(["SearchBarTags", username], getSearchBarTags, {
    refetchOnWindowFocus: true,
  });
}
