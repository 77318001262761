import React, { useEffect } from "react";
import "./AdditionalCarousel.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isNavigable } from "../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";

const AdditionalCarousels = (props: any) => {
  const { username, components, componentId } = props;
  const navigate = useNavigate();

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });
    }
  };

  return (
    <>
      <div style={{marginTop:'5%'}}>
        {/* <hr className="hr-wrapper" style={{  }} /> */}
        <p className="component-name">More from @{username}</p>
        <div className="wrapper-div">
          {components &&
            components
              .filter(isNavigable)
              .filter((elem: any) => elem.unique_id !== componentId)
              .filter((elem: any) => !elem.isEmpty)
              .filter((elem: any) => elem.status === "active") // Only include navigable components
              // Only include navigable components
              .map((elem: any, index: any) => (
                <div
                  key={elem.id}
                  className="product-card"
                  onClick={() => handleNavigate(elem)}
                >
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${elem.card_image})` }}
                  >
                    <p className="product-text">{elem.name}</p>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default AdditionalCarousels;
