import { Input, Modal } from "antd";
import { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import EditProductBankCheckboxComponent from "./EditProductBankCheckBoxComponent";
import { useLoadCustomProduct } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
import {
  removeProductBankQuery,
  updateProductBankQuery,
} from "../../../../../Utils/SupabaseServices/ProductBankDatabase";

const EditProductBank = ({
  username,
  user_id,
  isProductBankEditModalOpen,
  setIsProductBankEditModalOpen,
  type,
  component,
  refetch,
  setSelectedComponent,
}:any) => {
  const [name, setName] = useState(component.name || "");
  const [postIds, setPostIds] = useState("");
  const [removePosts, setRemovePosts] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [defaultAllPosts, setDefaultAllPosts] = useState([]);

  const { isLoading: CustomProductLoading, data: CustomProduct, refetch: refetchCustomProduct } = useLoadCustomProduct(user_id, username, component?.name);

  useEffect(() => {
    setName(component?.name || "");
    setPostIds(component?.post_ids || "");
    setDefaultQuery(component?.query || "");
  }, [component]);

  useEffect(() => {
    setDefaultAllPosts(CustomProduct);
  }, [CustomProduct]);

  const handleInputChange = (value:any, setter:any) => {
    setter(value);
    setErrorMsg("");
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const tableIds = removePosts.map((item:any) => item.table_id);

    if (removePosts.length > 0) {
      await Promise.all(
        removePosts.map((post:any) =>
          removeProductBankQuery(post.table_id, user_id, post, component?.name)
        )
      );
    }

    await updateProductBankQuery(postIds, user_id, component?.name);

    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      "product-bank",
      "",
      "",
      tableIds.toString()
    );

    if (res === "true") {
      refetch();
      refetchCustomProduct();
      resetForm();
    } else {
      setErrorMsg("Category with this name already exists");
    }

    setConfirmLoading(false);
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setDefaultAllPosts([]);
    setSelectedComponent("");
    setIsProductBankEditModalOpen(false);
  };

  return (
    <Modal
      open={isProductBankEditModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: !name }}
    >
      <div className="mt-1">
        <h5 className="editTitle">Editing {name}</h5>
        <Input
          placeholder="Enter Carousel Title"
          value={name}
          onChange={(e) => handleInputChange(e.target.value, setName)}
          className="mb-15"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        <EditProductBankCheckboxComponent
          username={username}
          user_id={user_id}
          handlePostIds={setPostIds}
          className="ant-checkbox-wrapper"
          componentName={name}
          defaultQuery={defaultQuery}
          setDefaultQuery={setDefaultQuery}
          defaultAllPosts={defaultAllPosts}
          refetchCustomProduct={refetch}
          setRemovePosts={setRemovePosts}
          removePosts={removePosts}
        />
      </div>
    </Modal>
  );
};

export default EditProductBank;




// import {  Input, Modal } from "antd";
// import  { useEffect, useState } from "react";
// import "../../StayUpToDateControls.css";
// import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
// import EditProductBankCheckboxComponent from "./EditProductBankCheckBoxComponent";
// import { useLoadCustomProduct } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
// import {
//   removeProductBankQuery,
//   updateProductBankQuery,
// } from "../../../../../Utils/SupabaseServices/ProductBankDatabase";

// const EditProductBank = (props: any) => {
//   const {
//     username,
//     user_id,
//     isProductBankEditModalOpen,
//     setIsProductBankEditModalOpen,
//     type,
//     component,
//     refetch,
//   } = props;
//   const [name, setName] = useState(component.name || "");
//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
//   const [defaultAllPosts, setDefaultAllPosts] = useState([]);
//   const [postIds, setPostIds] = useState("");
//   const [removePosts, setRemovePosts] = useState<any[]>([]);
//   const [confirmLoading, setConfirmLoading] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [defaultQuery, setDefaultQuery] = useState("");

//   const {
//     isLoading: CustomProductLoading,
//     data: CustomProduct,
//     refetch: refetchCustomProduct,
//   }: any = useLoadCustomProduct(user_id, username, component?.name);
//   useEffect(() => {
//     setPostIds(component?.post_ids);
//     setName(component?.name);
//     setDefaultQuery(component?.query);
//   }, [component]);
//   useEffect(() => {
//     setDefaultAllPosts(CustomProduct);
//   }, [CustomProduct]);
//   const handleInputChange = (value: string, setter: Function) => {
//     setter(value); // Set the input value
//     // setIsButtonDisabled(!value); // Disable button if either name or details is empty
//   };
//   const handleOk = async () => {
//     setConfirmLoading(true);
//     const tableIds = removePosts.map((item) => item.table_id);

//     if (removePosts && removePosts.length > 0) {
//       for (const post of removePosts) {
//         await removeProductBankQuery(
//           post.table_id,
//           user_id,
//           post,
//           component?.name
//         );
//       }

//       // Promise.all([removeProductPromise, refetch()]);
//     }
//     await updateProductBankQuery(postIds, user_id, component?.name);

//     //  else {

//     // }
//     const res = await updateComponent(
//       name,
//       component,
//       username,
//       user_id,
//       defaultQuery,
//       "product-bank",
//       "",
//       "",
//       tableIds.toString()
//     );
//     if (res === "true") {
//       refetch();
//       refetchCustomProduct();
//       setName("");
//       setPostIds("");
//       setErrorMsg("");
//       setDefaultQuery("");
//       setDefaultAllPosts([]);
//       props.setSelectedComponent("");
//       props.setIsProductBankEditModalOpen(false);
//       props.setIsProductBankEditModalOpen(false);

//       setConfirmLoading(false);
//     } else {
//       setErrorMsg("Category with this name already exists");
//       setConfirmLoading(false);
//     }
//     // setConfirmLoading(false);

//     setIsProductBankEditModalOpen(false);
//   };

//   const handleCancel = () => {
//     props.setIsProductBankEditModalOpen(false);
//     props.setSelectedComponent("");

//     // props.setIsCustomProductBankComponentOpen(false);
//     setName("");
//     setPostIds("");
//     setErrorMsg("");
//     setDefaultQuery("");
//     setDefaultAllPosts([]);
//   };

//   async function handlePostIds(post_ids: any) {
//     setPostIds(post_ids);
//     setDefaultAllPosts(post_ids);
//   }
//   return (
//     <>
//       <Modal
//         open={isProductBankEditModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         okText="Update"
//         confirmLoading={confirmLoading}
//         okButtonProps={{ disabled: !name }}
//       >
//         <div className="mt-1">
//           <h5 className="editTitle">Editing {name}</h5>
//           <Input
//             placeholder="Enter Carousel Title"
//             value={name}
//             onChange={(e: any) => {
//               handleInputChange(e.target.value, setName);
//               setErrorMsg("");
//             }}
//             className="mb-15"
//           />
//           {errorMsg && <p className="errorMsg">{errorMsg}</p>}
//           <EditProductBankCheckboxComponent
//             username={username}
//             user_id={user_id}
//             handlePostIds={handlePostIds}
//             className="ant-checkbox-wrapper"
//             componentName={name}
//             // post_ids={postIds}
//             defaultQuery={defaultQuery}
//             setDefaultQuery={setDefaultQuery}
//             defaultAllPosts={defaultAllPosts}
//             refetchCustomProduct={refetch}
//             setRemovePosts={setRemovePosts}
//             removePosts={removePosts}
//           />
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditProductBank;
