import { Button, Segmented, Spin, Table } from "antd";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const SocialCharts = (props: any) => {
  const { socialLinksAnalytics, loadingSocialLinksAnalytics, brand_color } =
    props;
  const totalCount = socialLinksAnalytics?.reduce(
    (accumulator: any, currentValue: any) => {
      return accumulator + currentValue.clicks;
    },
    0
  );

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Platform",
      dataIndex: "social",
      key: "social",
      render: (text: string, record: any) => (
        <a href={record.link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
  ];
  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          className="custom-tooltip"
        >
          <p className="social">{data.social}</p>
          <p className="clicks-color p-3px">
            Clicks: {data.clicks}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = () => (
    <div className="clicks">
        <div className="clicks-color">
          <ul>
            <li> Clicks</li>
          </ul>
      </div>
    </div>
  );
  return (
    <>
      <div
        className="loaderMyLink"
      >
        <div className="fullDiv">
          {loadingSocialLinksAnalytics ? (
            <div
            className="spin"
            >
              <Spin />
            </div>
          ) : (
            <div>
              <strong>Total Clicks: {totalCount}</strong>
              <div className="mb-15"></div>
              <ResponsiveContainer width="95%" height={300}>
                <BarChart
                  data={socialLinksAnalytics}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="index" interval={0} width={10} />
                  <YAxis />
                  <Tooltip content={renderCustomTooltip} />
                  <Legend content={CustomLegend} />

                  <Bar dataKey="clicks" fill="#42b0ba" barSize={20} />
                </BarChart>
              </ResponsiveContainer>
              <Table
                style={{ marginTop: "15px" }}
                columns={columns}
                dataSource={socialLinksAnalytics}
                rowKey="social"
                pagination={false}
                className="tabAnchor"
              />{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SocialCharts;
