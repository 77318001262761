import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./StayUpToDateComponentsWrapper.css";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal } from "antd";
import {
  deleteComponent,
  updateIndexValue,
} from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import SubComponents from "./SubComponents";
import EditCustomLinks from "./EditTemplates/EditCustomLinks";
import EditCustomContent from "./EditTemplates/EditCustomContent";
import EditCustomBanner from "./EditTemplates/EditCustomBanner";
import EditHighlightLinks from "./EditTemplates/EditHighlightLinks";
import StayUpToDateSkeleton from "./StayUpToDateSkeleton";
import EditYesterdayLinks from "./EditTemplates/EditYesterdayLinks";
import EditProductBank from "./EditTemplates/EditProductBank";
import EditQueryContent from "./EditTemplates/EditQueryContent";
import EditTrendingProducts from "./EditTemplates/EditTrendingProducts";
import StatusSelector from "../../../../components/StatusSelector/StatusSelector";
import EditMyLinks from "./EditTemplates/EditMyLinks";

const StayUpToDateComponentsWrapper = (props: any) => {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color, refatchStayUpToDate
  } = props;
  const [localData, setLocalData] = useState(StayUpToDateComponents);
  useEffect(() => {
    // refetch();
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isContentEditModalOpen, setIsContentEditModalOpen] = useState(false);
  const [isLinksEditModalOpen, setIsLinksEditModalOpen] = useState(false);
  const [isYesterdayLinksEditModalOpen, setIsYesterdayLinksEditModalOpen] =
    useState(false);

  const [isQueryLinksEditModalOpen, setIsQueryLinksEditModalOpen] =
    useState(false);
  const [isTrendingProductsEditModalOpen, setIsTrendingProductsEditModalOpen] =
    useState(false);

  const [isBannerEditModalOpen, setIsBannerEditModalOpen] = useState(false);
  const [isProductBankEditModalOpen, setIsProductBankEditModalOpen] =
    useState(false);

  const [isHighlightEditModalOpen, setIsHighlightEditModalOpen] =
    useState(false);

  const [isMyLinkEditModalOpen, setIsMyLinkEditModalOpen] =
    useState(false);
  const [isFormEditModalOpen, setIsFormEditModalOpen] =
    useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [status, setStatus] = useState("");



  useEffect(() => {
    refetch()
  }, [status, StayUpToDateComponents]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateIndexValue(user_id, newData);
  };
  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }
  async function handleEdit(name: any) {
    if (name.type === "content") {
      setIsContentEditModalOpen(true);
    } else if (name.type === "links") {
      setIsLinksEditModalOpen(true);
    } else if (name.type === "my-links" || name.display_name === "My Links") {
      setIsMyLinkEditModalOpen(true);
    } else if (name.type === "form" || name.display_name === "Form") {
      setIsMyLinkEditModalOpen(true);
    }
    else if (name.type === "query-links" || name.type === "query-content") {
      setIsQueryLinksEditModalOpen(true);
    } else if (name.type === "trending products") {
      setIsTrendingProductsEditModalOpen(true);
    } else if (
      name.display_name.split(",").includes("Yesterday") ||
      name.display_name.split(",").includes("This Week") ||
      name.display_name.split(",").includes("This Month") ||
      name.display_name.split(",").includes("Monday") ||
      name.display_name.split(",").includes("Tuesday") ||
      name.display_name.split(",").includes("Wednesday") ||
      name.display_name.split(",").includes("Thursday") ||
      name.display_name.split(",").includes("Friday") ||
      name.display_name.split(",").includes("Saturday") ||
      name.display_name.split(",").includes("Sunday") ||
      name.display_name.split(",").includes("This Weekend")
    ) {
      setIsYesterdayLinksEditModalOpen(true);
    } else if (name.type === "product-bank") {
      setIsProductBankEditModalOpen(true);
    } else if (name.type === "banner") {
      setIsBannerEditModalOpen(true);
    } else if (name.type === "highlight") {
      setIsHighlightEditModalOpen(true);
    }

    setSelectedComponent(name);
  }
  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch
    ).then(() => {
      setLocalData(filteredData);
      setConfirmLoading(false);
      setOpenDeleteModal(false);
    });
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };
  return (
    <div className="stay-up-to-date-wrapper">
      {StayUpToDateComponentsLoading ||
        (localData && localData.length === 0) ? (
        <div className="loader-container">
          <div className="component-box">
            <StayUpToDateSkeleton />
          </div>
        </div>
      ) : (
        <>
          <DragDropContext
            onDragStart={() => {
              document.documentElement.style.scrollBehavior = "unset";
              document.body.style.scrollBehavior = "unset";
            }}
            onDragEnd={(result: any) => {
              onDragEnd(result);
            }}
          >
            <Droppable droppableId="element">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {localData &&
                    localData.map((component: any, index: any) => (
                      // <div key={index}>
                      <Draggable
                        key={component.index?.toString()}
                        draggableId={component.index?.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {" "}
                            <div
                              key={component.id}
                              className="component-box"
                            // style={{
                            //   display:
                            //     component.display_name === "Popup Newsletter"
                            //       ? "none"
                            //       : undefined,
                            // }}
                            >
                              {/* {component.display_name !==
                                "Popup Newsletter" && ( */}
                              <div className="component-item">
                                <div className="component-text">
                                  <MdOutlineDragIndicator className="outline-drag" />
                                  <span className="component-name">
                                    {component.display_name === 'Form' && component.name === 'Form' ? '' : component.name}
                                  </span>
                                </div>
                                <div className="button-container">
                                  <StatusSelector
                                    defaultValue={component.status}
                                    component={component}
                                    user_id={user_id}
                                    refetch={refetch}
                                    setStatus={setStatus}
                                    refatchStayUpToDate={refatchStayUpToDate}
                                  />
                                  {component.type === "links" ||
                                    component.type === "query-links" ||
                                    component.type === "query-content" ||
                                    component.type === "content" ||
                                    component.type === "banner" ||
                                    component.type === "highlight" ||
                                    component.type === "product-bank" ||
                                    component.type === "trending products" ||
                                    component.display_name === "My Links" ||
                                    component.type === "my-links" ||
                                    component.display_name === "Form" ||
                                    component.type === "form" ||
                                    component.display_name
                                      .split(",")
                                      .includes("Monday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Tuesday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Wednesday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Thursday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Friday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Saturday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Sunday") ||
                                    component.display_name
                                      .split(",")
                                      .includes("This Weekend") ||
                                    component.display_name
                                      .split(",")
                                      .includes("Yesterday") ||
                                    component.display_name.split(",")
                                      .includes("This Month") ||
                                    component.display_name
                                      .split(",")
                                      .includes("This Week") ? (
                                    <Button
                                      className="edit-btn"
                                      onClick={() => {
                                        handleEdit(component);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  <Button
                                    className="remove-btn"
                                    onClick={() =>
                                      handleDelete(component.name)
                                    }
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                              {/* )} */}
                              <SubComponents
                                user_id={user_id}
                                username={username}
                                component={component}
                                selectedComponent={selectedComponent}
                                setSelectedComponent={setSelectedComponent}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                isEditModalOpen={isEditModalOpen}
                                setIsEditModalOpen={setIsEditModalOpen}
                                refetch={refetch}
                                brand_color={brand_color}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
          <EditCustomContent
            username={username}
            user_id={user_id}
            isContentEditModalOpen={isContentEditModalOpen}
            setIsContentEditModalOpen={setIsContentEditModalOpen}
            type="content"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditCustomLinks
            username={username}
            user_id={user_id}
            isLinksEditModalOpen={isLinksEditModalOpen}
            setIsLinksEditModalOpen={setIsLinksEditModalOpen}
            type="links"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditProductBank
            username={username}
            user_id={user_id}
            isProductBankEditModalOpen={isProductBankEditModalOpen}
            setIsProductBankEditModalOpen={setIsProductBankEditModalOpen}
            type="product-bank"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditYesterdayLinks
            username={username}
            user_id={user_id}
            isYesterdayLinksEditModalOpen={isYesterdayLinksEditModalOpen}
            setIsYesterdayLinksEditModalOpen={setIsYesterdayLinksEditModalOpen}
            type="links"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditQueryContent
            username={username}
            user_id={user_id}
            isQueryLinksEditModalOpen={isQueryLinksEditModalOpen}
            setIsQueryLinksEditModalOpen={setIsQueryLinksEditModalOpen}
            type="query"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetchDashboard={refetch}
          />
          <EditTrendingProducts
            username={username}
            user_id={user_id}
            isTrendingProductsEditModalOpen={isTrendingProductsEditModalOpen}
            setIsTrendingProductsEditModalOpen={
              setIsTrendingProductsEditModalOpen
            }
            type="trending products"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetchDashboard={refetch}
          />

          <EditCustomBanner
            username={username}
            user_id={user_id}
            isBannerEditModalOpen={isBannerEditModalOpen}
            setIsBannerEditModalOpen={setIsBannerEditModalOpen}
            type="banner"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditHighlightLinks
            username={username}
            user_id={user_id}
            isHighlightEditModalOpen={isHighlightEditModalOpen}
            setIsHighlightEditModalOpen={setIsHighlightEditModalOpen}
            type="highlight"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />

          <EditMyLinks
            username={username}
            user_id={user_id}
            isMyLinkEditModalOpen={isMyLinkEditModalOpen}
            setIsMyLinkEditModalOpen={setIsMyLinkEditModalOpen}
            type="my-link"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />

          <EditMyLinks
            username={username}
            user_id={user_id}
            isMyLinkEditModalOpen={isFormEditModalOpen}
            setIsMyLinkEditModalOpen={setIsFormEditModalOpen}
            type="form"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};

export default StayUpToDateComponentsWrapper;
