import "./styles/app.css";
import { LoginSocialFacebook } from "reactjs-social-login";
import Routing from "./Routes/Routing";
  
function App() {
  return (
    <div className="App fullDiv">
      <Routing />

      <LoginSocialFacebook //To load the react js social login library on app mount. Display is hidden.
        scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement"
        appId="644067177183784"
        fieldsProfile={"id,name"}
        onResolve={() => {}}
        onReject={(err) => {
        }}
        className="login-app"
      >
        Continue With Facebook
      </LoginSocialFacebook>
    </div>
  );
}

export default App;
