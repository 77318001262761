import { Link } from "react-router-dom";
import "./ErrorPages.css";
import HeaderDark from "../components/Header/headerDark";

export default function NoUser() {
	async function signOut() {
		localStorage.clear();
	}
	return (
		<div className='error-page'>
			<div className='error-page-header'>
				<HeaderDark />
			</div>

			<div className='error-page-error-msg'>
				No user found 😥<br/><br/>
				Please check the spelling of the username in the URL and try again! <br/><br/>
				{/* <button className='error-page-back-btn' onClick={signOut}>
					<Link to='/'>back</Link>
				</button> */}
			</div>
		</div>
	);
}
