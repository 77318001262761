import { Button, Input, Spin } from "antd";
import React, { useState } from "react";
import { changeSearchBarText } from "../../Utils/SupabaseServices/UserDatabase";

const SearchBarText = (props: any) => {
  const { isLoading, userData } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [newSearchName, setNewSearchName] = useState("");
  const handleUpdate = async () => {
    await changeSearchBarText(userData?.user_id, newSearchName).then(() => {
      props.refetch();
      setIsEdit(false);
    });
  };
  const handleCancel = () => {
    setIsEdit(false);
  };
  return (
    <>
      <div>
        <div className="account-social-logins-heading">
          <h5>
            Search Bar Placeholder Text{" "}
          </h5>
        </div>
        {isLoading ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {userData?.name ? (
              <>
                <div className="account-social-content-connect">
                  <React.Fragment>
                    <div className="account-social-link-connect-row">
                      <div
                        className="account-social-link-connect-row-cont searchbar"
                      >
                        {isEdit ? (
                          <Input
                            value={newSearchName}
                            onChange={(e: any) =>
                              setNewSearchName(e.target.value)
                            }
                          />
                        ) : (
                          <p>
                            {userData?.search_bar_text
                              ? userData?.search_bar_text
                              : `Search ${userData?.name}'s Page`}
                          </p>
                        )}
                          <>
              {!isEdit ? (
                <Button
                  className="search-btn-style"
                  onClick={() => {
                    setNewSearchName(
                      userData?.search_bar_text
                        ? userData?.search_bar_text
                        : `Search ${userData?.name}'s Page`
                    );
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    className="search-btn-style"
                    onClick={() => {
                      // setIsEdit(false);
                      handleUpdate();
                    }}
                    disabled={!newSearchName}
                  >
                    Save
                  </Button>
                  <Button
                    className="search-btn-style"
                    onClick={() => {
                      // setIsEdit(false);
                      handleCancel();
                    }}
                    //  disabled={!newSearchName}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
                      </div>
                    </div>

                    {/* <hr
                      className="hr-style"
                    /> */}
                  </React.Fragment>
                </div>
              </>
            ) : (
              <div
                className="account-social-content-connect account-style"
              ></div>
            )}
          </>
        )}{" "}
      </div>
    </>
  );
};

export default SearchBarText;
