import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { Switch, Modal } from "antd";
import { HiTrash } from "react-icons/hi";
import {
  deleteFillout,
  deleteFlowdesk,
  deleteFlowdeskPopup,
  deleteInstagram,
  deleteNewsletter,
  deletePinterest,
  deletePodcast,
  deleteTiktok,
  deleteYoutube,
} from "../../Utils/SupabaseServices/UserDatabase";
import { useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import "../Header/Header.css";

const IntegrationPlatforms = (props: any) => {
  const { username } = useParams();
  const {
    platformName,
    isConnected,
    isProcessing,
    isPlatformEnabled,
    onTogglePlatform,
    onConnectPlatform,
    onDeletePlatform,
    showConfirmation,
    platformTodelete,
    userId,
    refetchData,
    subscription_type,
    first_connected_platform,
  } = props;
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const handleCancelDelete = () => {
    props.setShowConfirmation(false);
  };
  const handleConfirmDelete = async (platform: any) => {
    switch (platform) {
      case "Instagram":
        await deleteInstagram(username, userId).then(() => {
          refetchData();
        });
        break;

      case "Youtube":
        await deleteYoutube(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Podcast":
        await deletePodcast(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Newsletter":
        await deleteNewsletter(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Tiktok":
        await deleteTiktok(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Pinterest":
        await deletePinterest(username, userId).then(() => {
          refetchData();
        });
        break;
      case "Flodesk Inline":
        await deleteFlowdesk(username, userId).then(() => {
          refetchData();
        });
        break;
        case "Flodesk Popup":
          await deleteFlowdeskPopup(username, userId).then(() => {
            refetchData();
          });
          break;
      case "Fillout":
        await deleteFillout(username, userId).then(() => {
          refetchData();
        });

        break;

      default:
        break;
    }

    props.setShowConfirmation(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    return (
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="account-social-content-connect-row-platform">
        <div className="account-social-content-connect-row">
          <div className="account-social-content-connect-row-cont">
            <p style={{ opacity: isConnected ? 1 : 0.5 }}>
              {platformName === "Twitter" ? "X" : platformName}
            </p>
            {first_connected_platform === platformName ? (
              <>
                {isConnected ? (
                  !isProcessing ? (
                    <div className="account-social-content-connect-status">
                      <p>Processing</p>
                    </div>
                  ) : (
                    <div className="account-social-content-connect-status active">
                      <BsCheck className="font-16" />
                    </div>
                  )
                ) : (
                  <div
                    className="account-social-content-connect-status"
                    onClick={onConnectPlatform}
                  >
                    <p>Connect</p>
                  </div>
                )}
              </>
            ) : subscription_type === "basic" ? (
              first_connected_platform === platformName ? (
                <>
                  {isConnected ? (
                    !isProcessing ? (
                      <div className="account-social-content-connect-status">
                        <p>Processing</p>
                      </div>
                    ) : (
                      <div className="account-social-content-connect-status active">
                        <BsCheck className="font-16" />
                      </div>
                    )
                  ) : (
                    <div
                      className="account-social-content-connect-status"
                      onClick={onConnectPlatform}
                    >
                      <p>Connect</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="falock">
                    <FaLock
                      className="falock-color"
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedPlatform(platformName);
                      }}
                    />
                  </div>
                </>
              )
            ) : (
              <>
                {isConnected ? (
                  !isProcessing ? (
                    <div className="account-social-content-connect-status">
                      <p>Processing</p>
                    </div>
                  ) : (
                    <div className="account-social-content-connect-status active">
                      <BsCheck className="font-16" />
                    </div>
                  )
                ) : (
                  <div
                    className="account-social-content-connect-status"
                    onClick={onConnectPlatform}
                  >
                    <p>Connect</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="platform-switch">
          <Switch
            style={{
              backgroundColor: isPlatformEnabled ? "green" : "rgb(241, 98, 98)",
            }}
            checked={isPlatformEnabled}
            onChange={onTogglePlatform}
            disabled={!isConnected}
          />
           {/* <HiTrash
            style={{
              fontSize: "19px",
              color: isConnected === false ? "grey" : "#414141",
              float: "right",
            }}
            onClick={isConnected ? onDeletePlatform : disabledPlatform}
            aria-disabled={isConnected === false}
            // aria-disabled={isConnected===false?true:false}
          /> */}

        </div>
      </div>
      {showConfirmation && platformTodelete === platformName && (
        <div className="confirmation-dialog">
          <p className="platform-name">
            Are you sure you want to delete {platformName}'s integration and its
            data?
          </p>
          <button className="confirm-btn cancel" onClick={handleCancelDelete}>
            Cancel
          </button>
          <button
            className="confirm-btn confirm confirm-btn-color"
            onClick={() => handleConfirmDelete(platformName)}
          >
            Confirm
          </button>
        </div>
      )}
      <hr className="hr-style" />
      <Modal
        title={`Upgrade to Unlock ${selectedPlatform} Integration`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="unlock-text">
          {`Unlocking the Social Integrations will allow you to integrate your ${selectedPlatform}'s data in the feed.`}
        </p>
        <div className="text-right">
          <a
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="upgrade-subscription">Upgrade</button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default IntegrationPlatforms;
