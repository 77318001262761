import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

/**
 * Load content
 */
export function useLoadLDescription(user_id: string) {
  async function getDescription({ pageParam = 1 }) {
    const { data, error }: any = await supabase
      .from("user")
      .select("description")
      .eq("user_id", user_id);


    if (data && data.length >= 0 && Array.isArray(data)) {
    	return data[0].description;
    } else if (error) {
    	throw new Error(error.message);
    } else {
    	throw new Error("Failed to fetch posts");
    }
  }

  return useQuery(["getDescription", user_id], getDescription, {
    enabled: Boolean(user_id),
  });
}
