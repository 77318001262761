import { Spin, Table } from "antd";
import { wrap } from "module";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const LinksCharts = (props: any) => {
  const { myLinksAnalytics, loadingMyLinksAnalytics, brand_color } = props;
  const totalCount = myLinksAnalytics?.reduce(
    (accumulator: any, currentValue: any) => {
      return accumulator + currentValue.clicks;
    },
    0
  );

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
  ];
  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          className="custom-tooltip"
        >
          <p className="m-2 p-3px">{data.link}</p>
          <p style={{ color: "#42b0ba", padding: "3px" }}>
            Clicks: {data.clicks}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = () => (
    <div className="clicks">
        <div style={{ color: "#42b0ba" }}>
          <ul>
            <li> Clicks</li>
          </ul>
      </div>
    </div>
  );
  return (
    <>
      <div
      className="loaderMyLink"
      >
        <div className="fullDiv">
          {loadingMyLinksAnalytics ? (
            <div
            className="spin"
            >
              <Spin />
            </div>
          ) : (
            <div>
              <strong>Total Clicks: {totalCount}</strong>
              <div className="mb-15"></div>
              <ResponsiveContainer width="95%" height={380}>
                <BarChart
                  data={myLinksAnalytics}
                  width={100}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="index" interval={0} width={10} />
                  <YAxis></YAxis>
                  <Tooltip content={renderCustomTooltip} />
                  <Legend content={CustomLegend} />
                  <Bar dataKey="clicks" fill="#42b0ba" barSize={20} />
                </BarChart>
              </ResponsiveContainer>
              <Table
                style={{ marginTop: "15px" }}
                columns={columns}
                dataSource={myLinksAnalytics}
                rowKey="My Link"
                pagination={false}
              />{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LinksCharts;
