import { supabase } from "../../config/supabaseClient";

export async function fetchGlobalControls() {
  let { data }: any = await supabase
    .from("global_controls")
    .select("*")
    .order("id");

  if (data.length > 0) {
    let showGlobalFilters;
    let showGlobalAds;
    const totalHashtags = data[0].control_value;
    const maxHashtags = data[1].control_value;
    const showCaption = data[2].control_value;
    const showTopHashtags = data[3].control_value;
    const showFilters = data[4].control_value;
    if (showFilters === "true") {
      showGlobalFilters = true;
    } else {
      showGlobalFilters = false;
    }
    const showAds = data[5].control_value;
    if (showAds === "true") {
      showGlobalAds = true;
    } else {
      showGlobalAds = false;
    }

    return {
      showCaption,
      showTopHashtags,
      maxHashtags,
      totalHashtags,
      showGlobalFilters,
      showGlobalAds
    };
  }
}
