
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { BannerSkeletonTemplateWrapper } from "../BannerSkeletonTemplateWrapper";
import { Button } from "antd";

const BannerWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const { title, component, user_id, brand_color, cta } = props;
  const location = useLocation();

  function handleRedirect(postId: string, media_url: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }



  return (
    <>
      {loading ? (
        <BannerSkeletonTemplateWrapper />
      ) : (
        <div
          className="outter-section-banner-subcomponent"
          style={{
            backgroundColor: brand_color && `${brand_color}`,
          }}
        >
          <a
            href={
              component?.details?.split(",")[1]?.startsWith("http")
                ? component.details.split(",")[1]
                : `http://${component.details.split(",")[1]}`
            }
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              handleRedirect(component.id, component?.details?.split(",")[0]);
              const url = component?.details?.split(",")[1];
              if (url && url !== "") {
                const validUrl = url.startsWith("http") ? url : `http://${url}`;
                window.open(validUrl, "_blank");
              }
            }}
            className="text-decoration-none"
          >
            <div
              className="outter-section-div"
            >
              <div className="pb-10px">
                <div
                  className="previewHeading"
                >
                  <p>
                    {props.title}
                  </p>
                </div>
                <div>
                  <Button
                    className="ant-btn css-dev-only-do-not-override-1nimcbs ant-btn-primary mw-300"
                  >
                    {cta}
                  </Button>
                </div>
              </div>
              <div
                className="outter-section-banner"
                style={{
                  backgroundColor: brand_color && `${brand_color}`,
                  zIndex: "1",
                }}
              >
                <img
                  src={
                    component?.details?.split(",")[0] || "/assets/image-bg.jpeg"
                  }
                  alt={title}
                  className="banner-image index1"
                  onError={(e) => {
                    e.currentTarget.src = "/assets/image-bg.jpeg";
                  }}
                />
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default BannerWrapper;
