import { createClient } from "@supabase/supabase-js";

const supabaseUrl: any ='https://nneqehyphaavozzymtwp.supabase.co'
	// process.env.REACT_APP_ENV === "production"
	// 	? process.env.REACT_APP_SUPABASE_URL
	// 	: process.env.REACT_APP_SUPABASE_URL_PREVIEW;
const supabaseAnonKey: any ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5uZXFlaHlwaGFhdm96enltdHdwIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY2Njg0MzAwNSwiZXhwIjoxOTgyNDE5MDA1fQ.z6uqaHkQZBjDSHCRf4JqmxlrkZ8KdPZmPtLzfKb3bXc'
	// process.env.REACT_APP_ENV === "production"
	// 	? process.env.REACT_APP_SUPABASE_ANON_KEY
	// 	: process.env.REACT_APP_SUPABASE_ANON_KEY_PREVIEW;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);




// for preview ---------------------

// import { createClient } from "@supabase/supabase-js";

// const supabaseUrl: any ='https://dqmncccmjrpgsdpvjbvp.supabase.co'
// 	// process.env.REACT_APP_ENV === "production"
// 	// 	? process.env.REACT_APP_SUPABASE_URL
// 	// 	: process.env.REACT_APP_SUPABASE_URL_PREVIEW;
// const supabaseAnonKey: any ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRxbW5jY2NtanJwZ3NkcHZqYnZwIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4NTU0OTczOCwiZXhwIjoyMDAxMTI1NzM4fQ.AhJXXNIdnO-T6BHCGPfkPaf3NjqXgeVp5FYeLOq-PiQ'
// 	// process.env.REACT_APP_ENV === "production"
// 	// 	? process.env.REACT_APP_SUPABASE_ANON_KEY
// 	// 	: process.env.REACT_APP_SUPABASE_ANON_KEY_PREVIEW;

// export const supabase = createClient(supabaseUrl, supabaseAnonKey);
