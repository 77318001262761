import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

export function useLoadCustomCategories(user_id: any, page: any) {
  async function getCustomCategories() {
    let liveCategories: any = [];
    let stagingCategories: any = [];
    const { data } = await supabase
      .from("custom_categories")
      .select("*")
      .eq("user_id", user_id)
      .order("index");

    if (data && data.length) {
      data.map((category: any) => {
        if (category.page === "live") {
          liveCategories.push({
            name: category.category_name,
            value: category.value,
            queries: category.custom_queries,
            index: category.index,
            default: category.default_query,
            isExisting: category.is_existing,
          });
        } else if (category.page === "staging") {
          stagingCategories.push({
            name: category.category_name,
            value: category.value,
            queries: category.custom_queries,
            index: category.index,
            default: category.default_query,
            isExisting: category.is_existing,
          });
        }
      });
      if (page === "live" || page === "follower") {
        return liveCategories;
      } else if (page === "staging" || page === "creator") {
        return stagingCategories;
      }
    } else return [];
  }

  return useQuery(["customCategories", user_id], getCustomCategories, {
    refetchOnWindowFocus: true,
  });
}
