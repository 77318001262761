import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../StaticVariables";

export function useLoadProductBankContent(
  user_id: string,
  username: any,
  currentPage: any,
  postsPerPage: any,
  totalPages: any,
  query: any,
  productFilter?: any
) {
  const fetchProductBankPosts = async () => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    // const input=query?.split(" ").map((elem:any)=>(`%${elem}%`))
    const input = query
      ? query
          .split(" ") // Split filter string into words
          .filter(
            (word: string) =>
              !StaticVariables().connectingWords.includes(word.toLowerCase())
          ) // Remove connecting words
          .map((elem: string) => `%${elem}%`)
      : [];
    // if (!productFilter || (productFilter && productFilter === "All")) {
    //   const { data, error } = await supabase.rpc("get_productbank_postss", {
    //   query_string: input,
    //   userid: user_id,

    //   start_number: start,
    //   end_number: end,
    //   query_tagg: input,
    // });}
    // else{
    //   const { data, error } = await supabase.rpc("get_productbank_posts_fil", {
    //     query_string: input,
    //     userid: user_id,

    //     start_number: start,
    //     end_number: end,
    //     query_tagg: input,
    //     filter:productFilter
    //   });
    // }
    // if (error) {
    //   throw new Error(error.message);
    // }
    // const seen = new Set();
    // const uniquePosts = data.filter((post: any) => {
    //   const duplicate = seen.has(post.table_id);
    //   seen.add(post.table_id);
    //   return !duplicate;
    // });
    // return uniquePosts;
    // return data;
    const rpcFunction =
      productFilter && productFilter !== "All"
        ? "get_productbank_posts_fil"
        : "get_productbank_postss";

    const { data, error } = await supabase.rpc(rpcFunction, {
      query_string: input,
      userid: user_id,
      start_number: start,
      end_number: end,
      query_tagg: input,
      ...(productFilter &&
        productFilter !== "All" && { filter: productFilter }),
    });

    if (error) {
      throw new Error(error.message);
    }

    const seen = new Set();
    const uniquePosts = data.filter((post: any) => {
      const duplicate = seen.has(post.table_id);
      seen.add(post.table_id);
      return !duplicate;
    });

    return uniquePosts;
  };

  return useInfiniteQuery(
    ["ProductBankPosts", currentPage, query,productFilter],
    fetchProductBankPosts,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
