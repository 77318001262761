import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

// Hook for adding or removing tags
export default function useUpdateProductTags() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    tagString,
    post_id,
    username,
    action,
    tag,
    user_id,
  }: {
    currentPage: any;
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    username: any;
    action: any;
    tag: any;
    user_id: any;
  }) => {

    if (action === "submit") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id)
        // .limit(1);

      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
    } else if (action === "remove") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id)
        .limit(1);
      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
    }

    // if (error) {
    //   throw new Error(error.message);
    // } else {
    return true;
    // }
  };

  return useMutation(submitTag, {
    onMutate: async ({
      tagString,
      post_id,
      currentPage,
      username,
      pageIndex,
      index,
      activeKey,
      filter,
    }) => {
      await queryClient.cancelQueries("ProductBankPosts");

      // Optimistically update the local data
      const previousData = queryClient.getQueryData(["ProductBankPosts"]);

      queryClient.setQueryData(["ProductBankPosts"], (oldData: any) => {
        // if (oldData) {
        //   // Find and update the specific post with new permalink
        //   const newData = oldData.map((page: any) =>
        //     page.map((post: any) =>
        //       // post.table_id === post_id ? { ...post, table_tags: tagString } : post
        //     )
        //   );
        //   return newData;
        // }
        return oldData;
      });

      return { previousData }; // Return context for rollback
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["ProductBankPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("ProductBankPosts");
    },
  });
}
