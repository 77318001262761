
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";

// // Use this component to redirect authenticated users to their dashboard
// export default function AuthRoute({ Component }: any) {
//   const { session, isOnboardingFinished, username } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if the session exists and the redirect hasn't been done yet
//     if (session && username && !isOnboardingFinished) {
//       navigate(`/creator/${username}`);
//     }
//   }, [session, username, isOnboardingFinished]);

//   return <Component />;
// }


import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function AuthRoute({ Component }: any) {
  const { session, isOnboardingFinished, username } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (session && username && !isOnboardingFinished) {
      // Ensure that navigate is only called when needed
      // navigate(`/creator/${username}`);
      // navigate(`/dashboard/${username}`);

    }
  }, [session, username, isOnboardingFinished, navigate]); // Add navigate to the dependency array

  return <Component />;
}
