import React, { useEffect, useState } from "react";
import "./FormData.css";
import { Button } from "antd";
import { BannerSkeleton } from "../../BannerSkeleton";
import { isMobile } from "react-device-detect";
import { BsLink45Deg } from "react-icons/bs";
const FormData = (props: any) => {
  const [loading, setLoading] = useState(true);

  const { title, component, handleRedirect, brand_color, filloutId,isHandleNavigate } = props;
  useEffect(() => {
    // Create and append the script element when the component mounts
    const script = document.createElement("script");
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    document.body.appendChild(script);
    setLoading(false);
    // Clean up by removing the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <>
          {/* <div className="mainDiv"> */}
          <div className="mainDiv">
            <p className="component-name">
              {" "}
              {component.display_name === "Form" && component.name === "Form"
                ? ''
                : component.name}
                               
            </p>
          </div>
          {/* <div
          className="outter-section"
          style={{
            marginTop: "15px",
            backgroundColor: brand_color && brand_color,
          }}
        > */}
          <div
            className="outter-section-banner-subcomponent"
            style={{
              marginTop: "0px",
              backgroundColor: brand_color,
              width: "100%",
            }}
          >
            {
              !isMobile ? (
                <div
                  style={{ width: "100%", maxHeight: "143rem", height: "143rem", overflow: "hidden" }}
                  data-fillout-id={filloutId}
                  data-fillout-embed-type="standard"
                  data-fillout-inherit-parameters
                  data-fillout-dynamic-resize
                ></div>
              ) : (
                <div
                  style={{ width: "100%", maxHeight: "158rem", height: "158rem", overflow: "hidden" }}
                  data-fillout-id={filloutId}
                  data-fillout-embed-type="standard"
                  data-fillout-inherit-parameters
                  data-fillout-dynamic-resize
                ></div>
              )}

          </div>
        </>
      )}
    </>
  );
};

export default FormData;
