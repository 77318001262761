import React, { useEffect, useState } from "react";
import { Avatar, Image, message, Modal, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd";
import { supabase } from "../../../../config/supabaseClient";
import { PlusOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ProfilePicture = (props: any) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: `${props.username}`,
      status: "done",
      url: `${props.userData?.picture}`,
    },
  ]);
  useEffect(() => {
    if (!props.userData?.picture) {
      const avatarBase64 = `data:image/svg+xml;base64,${btoa(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 1024 1024"><path d="M512 512m-192 0a192 192 0 1 0 384 0 192 192 0 1 0-384 0Z" fill="#BFBFBF"/><path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64m0-64C229.2 0 0 229.2 0 512s229.2 512 512 512 512-229.2 512-512S794.8 0 512 0z" fill="#BFBFBF"/></svg>`
      )}`;
      setFileList([
        {
          uid: "-1",
          name: `${props.username}`,
          status: "done",
          thumbUrl: avatarBase64, // Avatar SVG as base64
        },
      ]);
    } else {
      setFileList([
        {
          uid: "-1",
          name: `${props.username}`,
          status: "done",
          url: `${props.userData.picture}`,
        },
      ]);
    }
  }, [props.userData?.picture]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length > 0) {
      const updatedFile: any = newFileList[newFileList.length - 1];
      const timestamp = dayjs().format("YYYYMMDD_HHmmss");
      try {
        const { data, error }: any = await supabase.storage
          .from("profile-picture")
          .upload(`${props.username}.jpeg`, updatedFile.originFileObj, {
            contentType: updatedFile.type,
          });
        if (error && error.error == "Duplicate") {
          const { data: retryData, error: retryError } = await supabase.storage
            .from("profile-picture")
            .upload(
              `${props.username}/${props.username}__${timestamp}.jpeg`,
              updatedFile.originFileObj,
              { contentType: updatedFile.type }
            );
          if (retryError) throw retryError;
          const { data: urlData } = await supabase.storage
            .from("profile-picture")
            .getPublicUrl(
              `${props.username}/${props.username}__${timestamp}.jpeg`
            );
          await supabase
            .from("user")
            .update({
              picture: urlData.publicUrl,
              storage_url: urlData.publicUrl,
            })
            .eq("username", props.username);
          setFileList([
            { ...updatedFile, url: urlData.publicUrl, status: "done" },
          ]);
        } else if (data) {
          const { data: urlData } = await supabase.storage
            .from("profile-picture")
            .getPublicUrl(`${props.username}.jpeg`);
          await supabase
            .from("user")
            .update({
              picture: urlData.publicUrl,
              storage_url: urlData.publicUrl,
            })
            .eq("username", props.username);
          setFileList([
            { ...updatedFile, url: urlData.publicUrl, status: "done" },
          ]);
        }
        props.refetch();
        props.setIsEmail(true);
      } catch (uploadError) {
        message.error("An error occurred during the upload. Please try again.");
      }
    }
  };
  const uploadButton = (
    <button className="background-none" type="button">
      <PlusOutlined />
      <div className="mt-8">Upload</div>
    </button>
  );
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };
  return (
    <>
      <div className="account-description-wrapper">
        <div className="account-social-logins-heading mb-30">
          <h5>Upload Profile Picture </h5>
        </div>
        <Upload
          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
          listType="picture-circle"
          fileList={fileList ? fileList : []}
          onPreview={handlePreview}
          onChange={handleChange}
          className="mb-30"
          data-testid="circular-picture"
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
            showDownloadIcon: false,
            previewIcon: <EyeOutlined />,
          }}
        >
          {fileList.length > 0 || fileList[0].url ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {uploadButton}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar size={35} icon={<UserOutlined />} />
              <button className="background-none" type="button">
                <div className="mt-8">Upload Image</div>
              </button>
            </div>
          )}
        </Upload>
        {previewImage && (
          <Modal
            open={previewOpen}
            onCancel={handleClosePreview}
            footer={null}
            height="100%"
          >
            <div className="profile-upload">
              <Image
                style={{
                  marginTop: "5%",
                  objectFit: "contain",
                  height: "100%",
                }}
                src={previewImage}
                preview={false}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default ProfilePicture;
