import React, { useEffect } from "react";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import AuthTerm from "../components/AuthTerm/authTerm";
import "../components/Footer/Footer.css";
import { BsColumns } from "react-icons/bs";
import { Card, Col, Row } from 'antd';


function HowItWorks() {

// const video =  require("../public/assets/out.mp4'");

  return (
    // <div>
      <div className="auth-container how-it-works">
         {/* <Header /> */}
        <div className='navbar_container'>
          <a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
            <div className='logo-text'>
              <img
                className='homepage-icon'
                src='/assets/output-onlinepngtools-edited.png'
                alt='thesearchbubble logo'
              />
            </div>
          </a>
          <nav className='navbar_menu w-nav-menu'>
            <div className='navbar_menu-link-wrapper'>
              <a href="https://calendly.com/searchbubble/demo" target="_blank" className="navbar_link w-nav-link login">book a demo</a>
			        <a href="/subscribe" className="navbar_link w-nav-link sign-up">sign up for free</a>
            </div>
          </nav>
        </div>

        <div className="howItWorksGrid">

          {/* Feature 1  */}
            <div>
              <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
              <span className="featureDescription">
                affiliate links <br />
                your website <br />
              </span>
            </div>
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

          {/* Feature 2  */}
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>
            <div>
              <span className="featureTitle">All of your content. Saved & Organized. In one place. </span><br /><br />
              <span className="featureDescription">
                absolutely no work on your end after you sign up<br />
                we automatically pull all of your content for your social playforms <br />
                inlcuding your stories (everyday!) and your highlights!
              </span>
            </div>

            {/* Feature 3  */}
            <div>
              <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
              <span className="featureDescription">
                affiliate links <br />
                your website <br />
              </span>
            </div>
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

          {/* Feature 4  */}
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>
            <div>
              <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
              <span className="featureDescription">
                affiliate links <br />
                your website <br />
              </span>
            </div>
        
          {/* Feature 5  */}
            <div>
              <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
              <span className="featureDescription">
                affiliate links <br />
                your website <br />
              </span>
            </div>
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

          {/* Feature 6  */}
            <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>
            <div>
              <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
              <span className="featureDescription">
                affiliate links <br />
                your website <br />
              </span>
            </div>

        </div>

        <div className="howItWorksGridMobile">

        {/* Feature 1  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

        {/* Feature 2  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

          {/* Feature 3  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

        {/* Feature 4  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>


          {/* Feature 5  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

        {/* Feature 6  */}
          <div>
            <span className="featureTitle">Drive more traffic to revenue generating content </span><br /><br />
            <span className="featureDescription">
              affiliate links <br />
              your website <br />
            </span>
          </div>
          <video autoPlay playsInline className="featureVideo" src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/out%20(3).mp4" muted loop></video>

        </div>

      <div>
        <AuthTerm />
      </div>
    </div>
  );
}

export default HowItWorks;