import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Alert, Button, Input, Modal, Spin, Switch } from "antd";
import "./IntegrationsBox.css";
import { useLoadIntegrations } from "./useLoadIntegrations";
import PodcastIntegrationModal from "./PlatformIntegrationModal/PodcastIntegrationModal";
import NewsletterIntegrationModal from "./PlatformIntegrationModal/NewsletterIntegrationModal";
import TiktokIntegrationModal from "./PlatformIntegrationModal/TiktokIntegrationModal";

import {
  updateFilloutToggle,
  updateFlowdeskPopupToggle,
  updateFlowdeskToggle,
  updateInstagramToggle,
  updateNewsletterToggle,
  updatePinterestToggle,
  updatePodcastToggle,
  updateTiktokToggle,
  updateTwitterToggle,
  updateYoutubeToggle,
} from "../../Utils/SupabaseServices/UserDatabase";
import IntegrationPlatforms from "./IntegrationPlatforms";
import YoutubeIntegrationModal from "./PlatformIntegrationModal/YoutubeIntegrationModal";
import PinterestIntegrationModal from "./PlatformIntegrationModal/PinterestIntegrationModal";
import InstagramIntegrationModal from "./PlatformIntegrationModal/InstagramIntegrationModal";
import TwitterIntegrationModal from "./PlatformIntegrationModal/TwitterIntegrationModal";
import FlowdeskIntegrationModal from "./PlatformIntegrationModal/FlowdeskIntegrationModal";
import FlowdeskPopupIntegrationModal from "./PlatformIntegrationModal/FlowdeskPopupIntegrationModal";
import FilloutIntegrationModal from "./PlatformIntegrationModal/FilloutIntegrationModal";

export default function IntegrationsBox(props: any) {
  const { subscription_type, first_connected_platform } = props;
  const { user } = useAuth();

  const [showChannelIdModal, setShowChannelIdModal] = useState(false);
  const [showFormIdModal, setShowFormIdModal] = useState(false);
  const [showPopupFormIdModal, setShowPopupFormIdModal] = useState(false);

  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const [showPinterestModal, setShowPinterestModal] = useState(false);
  const [showFlowdeskModal, setShowFlowdeskModal] = useState(false);
  const [showFilloutModal, setShowFilloutModal] = useState(false);

  const [showPodcastModal, setShowPodcastModal] = useState(false);
  const [showTiktokModal, setShowTiktokModal] = useState(false);
  const [showTwitterModal, setShowTwitterModal] = useState(false);

  const [showInstagramModal, setShowInstagramModal] = useState(false);
  const {
    isLoading,
    // isError,
    // error,
    data,
    isFetching,
    refetch,
  } = useLoadIntegrations(user?.id as string);
  //Instagram -------------------------------------------------
  const isInstagramConnected = Boolean(data?.instagram_username);
  const showInstagram = Boolean(data?.show_instagram);
  const isInstagramEnabled = Boolean(data?.is_instagram_on);
  const toggleInstagram = async (checked: boolean) => {
    const toggle = checked;

    updateInstagramToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Youtube-------------------------------------------------------
  const isYoutubeConnected = Boolean(data?.youtube_channel_id);
  const showYoutube = Boolean(data?.show_youtube);
  const isYoutubeEnabled = Boolean(data?.is_youtube_on);
  const toggleYoutube = async (checked: boolean) => {
    const toggle = checked;

    updateYoutubeToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Podcast--------------------------------------------------------
  const isPodcastConnected = Boolean(data?.podcast_link);
  const showPodcast = Boolean(data?.show_podcast);
  const isPodcastEnabled = Boolean(data?.is_podcast_on);
  const togglePodcast = async (checked: boolean) => {
    const toggle = checked;

    updatePodcastToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };
  //Newsletter ------------------------------------------------------
  const isNewsletterConnected = Boolean(data?.newsletter_email);
  const showNewsletter = Boolean(data?.show_newsletter);
  const isNewsletterEnabled = Boolean(data?.is_newsletter_on);
  const toggleNewsletter = async (checked: boolean) => {
    const toggle = checked;

    updateNewsletterToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Tiktok ---------------------------------------------------------
  const isTiktokConnected = Boolean(data?.tiktok_user);
  const showTiktok = Boolean(data?.show_tiktok);
  const isTiktokEnabled = Boolean(data?.is_tiktok_on);
  const toggleTiktok = async (checked: boolean) => {
    const toggle = checked;

    updateTiktokToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Twitter ---------------------------------------------------------
  const isTwitterConnected = Boolean(data?.twitter_user);
  const showTwitter = Boolean(data?.show_twitter);
  const isTwitterEnabled = Boolean(data?.is_twitter_on);
  const toggleTwitter = async (checked: boolean) => {
    const toggle = checked;

    updateTwitterToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Pinterest ------------------------------------------------------
  const isPinterestConnected = Boolean(data?.pinterest_user);

  const showPinterest = Boolean(data?.show_pinterest);
  const isPinterestEnabled = Boolean(data?.is_pinterest_on);
  const togglePinterest = async (checked: boolean) => {
    const toggle = checked;

    updatePinterestToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Flowdesk ------------------------------------------------------
  const isFlowdeskConnected = Boolean(data?.flowdesk_user);

  const showFlowdesk = Boolean(data?.show_flowdesk);
  const isFlowdeskEnabled = Boolean(data?.is_flowdesk_on);
  const toggleFlowdesk = async (checked: boolean) => {
    const toggle = checked;

    updateFlowdeskToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //FlowdeskPopup ------------------------------------------------------
  const isFlowdeskPopupConnected = Boolean(data?.flowdeskpopup_user);

  const showFlowdeskPopup = Boolean(data?.show_flowdeskpopup);
  const isFlowdeskPopupEnabled = Boolean(data?.is_flowdeskpopup_on);
  const toggleFlowdeskPopup = async (checked: boolean) => {
    const toggle = checked;

    updateFlowdeskPopupToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  //Fillout ------------------------------------------------------
  const isFilloutConnected = Boolean(data?.fillout_user);

  const showFillout = Boolean(data?.show_fillout);
  const isFilloutEnabled = Boolean(data?.is_fillout_on);
  const toggleFillout = async (checked: boolean) => {
    const toggle = checked;

    updateFilloutToggle(user?.id, toggle).then(() => {
      refetch();
    });
  };

  // const isInstagramConnected = Boolean(data?.instagramId);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [platformTodelete, setPlatformToDelete] = useState("");

  const handleDeleteClick = (platform: any) => {
    setShowConfirmation(true);
    setPlatformToDelete(platform);
  };
  const onClick = async () => {
    setShowChannelIdModal(true);
  };

  return (
    <>
      <div className="account-social-content-connect">
        {isLoading || isFetching ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            <IntegrationPlatforms
              platformName="Instagram"
              isConnected={isInstagramConnected}
              isProcessing={showInstagram}
              isPlatformEnabled={isInstagramEnabled}
              onTogglePlatform={toggleInstagram}
              userId={user?.id}
              refetchData={refetch}
              onConnectPlatform={() => setShowInstagramModal(true)}
              onDeletePlatform={() => handleDeleteClick("Instagram")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
              // onDeletePlatform={/* Delete platform function */}
            />
            <IntegrationPlatforms
              platformName="Youtube"
              isConnected={isYoutubeConnected}
              isProcessing={showYoutube}
              isPlatformEnabled={isYoutubeEnabled}
              onTogglePlatform={toggleYoutube}
              onConnectPlatform={() => onClick()}
              onDeletePlatform={() => handleDeleteClick("Youtube")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            {/* <IntegrationPlatforms
        platformName="Podcast"
        isConnected={isPodcastConnected}
        isProcessing={showPodcast}
        isPlatformEnabled={isPodcastEnabled}
        onTogglePlatform={togglePodcast}
        onConnectPlatform=
        onDeletePlatform=
      /> */}
            <IntegrationPlatforms
              platformName="Podcast"
              isConnected={isPodcastConnected}
              isProcessing={showPodcast}
              isPlatformEnabled={isPodcastEnabled}
              onTogglePlatform={togglePodcast}
              onConnectPlatform={() => setShowPodcastModal(true)}
              onDeletePlatform={() => handleDeleteClick("Podcast")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Newsletter"
              isConnected={isNewsletterConnected}
              isProcessing={showNewsletter}
              isPlatformEnabled={isNewsletterEnabled}
              onTogglePlatform={toggleNewsletter}
              onConnectPlatform={() => setShowNewsletterModal(true)}
              onDeletePlatform={() => handleDeleteClick("Newsletter")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Tiktok"
              isConnected={isTiktokConnected}
              isProcessing={showTiktok}
              isPlatformEnabled={isTiktokEnabled}
              onTogglePlatform={toggleTiktok}
              onConnectPlatform={() => setShowTiktokModal(true)}
              onDeletePlatform={() => handleDeleteClick("Tiktok")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Pinterest"
              isConnected={isPinterestConnected}
              isProcessing={showPinterest}
              isPlatformEnabled={isPinterestEnabled}
              onTogglePlatform={togglePinterest}
              onConnectPlatform={() => setShowPinterestModal(true)}
              onDeletePlatform={() => handleDeleteClick("Pinterest")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />

            <IntegrationPlatforms
              platformName="Twitter"
              isConnected={isTwitterConnected}
              isProcessing={showTwitter}
              isPlatformEnabled={isTwitterEnabled}
              onTogglePlatform={toggleTwitter}
              onConnectPlatform={() => setShowTwitterModal(true)}
              onDeletePlatform={() => handleDeleteClick("Twitter")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Flodesk Inline"
              isConnected={isFlowdeskConnected}
              isProcessing={showFlowdesk}
              isPlatformEnabled={isFlowdeskEnabled}
              onTogglePlatform={toggleFlowdesk}
              onConnectPlatform={() => setShowFormIdModal(true)}
              onDeletePlatform={() => handleDeleteClick("Flodesk Inline")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Flodesk Popup"
              isConnected={isFlowdeskPopupConnected}
              isProcessing={showFlowdeskPopup}
              isPlatformEnabled={isFlowdeskPopupEnabled}
              onTogglePlatform={toggleFlowdeskPopup}
              onConnectPlatform={() => setShowPopupFormIdModal(true)}
              onDeletePlatform={() => handleDeleteClick("Flodesk Popup")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}
            />
            <IntegrationPlatforms
              platformName="Fillout"
              isConnected={isFilloutConnected}
              isProcessing={showFillout}
              isPlatformEnabled={isFilloutEnabled}
              onTogglePlatform={toggleFillout}
              onConnectPlatform={() => setShowFilloutModal(true)}
              onDeletePlatform={() => handleDeleteClick("Fillout")}
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              platformTodelete={platformTodelete}
              userId={user?.id}
              refetchData={refetch}
              subscription_type={subscription_type}
              first_connected_platform={first_connected_platform}

            />
          </>
        )}
      </div>
      <YoutubeIntegrationModal
        showChannelIdModal={showChannelIdModal}
        setShowChannelIdModal={setShowChannelIdModal}
        user_id={user?.id}
        data={data}
        refetchData={refetch}
      />

      <NewsletterIntegrationModal
        showNewsletterModal={showNewsletterModal}
        setShowNewsletterModal={setShowNewsletterModal}
        user_id={user?.id}
        data={data}
        refetchData={refetch}
      />
      <PodcastIntegrationModal
        showPodcastModal={showPodcastModal}
        setShowPodcastModal={setShowPodcastModal}
        user_id={user?.id}
        data={data}
      />
      <TiktokIntegrationModal
        showTiktokModal={showTiktokModal}
        setShowTiktokModal={setShowTiktokModal}
        user_id={user?.id}
        data={data}
      />
      <TwitterIntegrationModal
        showTwitterModal={showTwitterModal}
        setShowTwitterModal={setShowTwitterModal}
        user_id={user?.id}
        data={data}
      />
      <InstagramIntegrationModal
        showInstagramModal={showInstagramModal}
        setShowInstagramModal={setShowInstagramModal}
        user_id={user?.id}
        data={data}
      />
      <PinterestIntegrationModal
        showPinterestModal={showPinterestModal}
        setShowPinterestModal={setShowPinterestModal}
        user_id={user?.id}
        data={data}
      />
      <FlowdeskIntegrationModal
        // showFlowdeskModal={showFlowdeskModal}
        // setShowFlowdeskModal={setShowFlowdeskModal}
        // user_id={user?.id}
        // data={data}
        showFormIdModal={showFormIdModal}
        setShowFormIdModal={setShowFormIdModal}
        user_id={user?.id}
        data={data}
        refetchData={refetch}
      />
      <FlowdeskPopupIntegrationModal
        // showFlowdeskModal={showFlowdeskModal}
        // setShowFlowdeskModal={setShowFlowdeskModal}
        // user_id={user?.id}
        // data={data}
        showPopupFormIdModal={showPopupFormIdModal}
        setShowPopupFormIdModal={setShowPopupFormIdModal}
        user_id={user?.id}
        data={data}
        refetchData={refetch}
      />
        <FilloutIntegrationModal
        // showFlowdeskModal={showFlowdeskModal}
        // setShowFlowdeskModal={setShowFlowdeskModal}
        // user_id={user?.id}
        // data={data}
        showFilloutModal={showFilloutModal}
        setShowFilloutModal={setShowFilloutModal}
        user_id={user?.id}
        data={data}
        refetchData={refetch}
      />
      {/* <FilloutIntegrationModal
      showFilloutModal={showFilloutModal}
      setShowFilloutModal={setShowFilloutModal}
      user_id={user?.id}
      data={data}
    /> */}
    </>
  );
}
