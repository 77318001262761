import React, { useEffect, useState } from "react";
import { useLoadPostIdsData } from "../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import { supabase } from "../../config/supabaseClient";
import { Button, Carousel, Input } from "antd";
import { HiCheck, HiPencil } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import VideoComponent from "../VideoComponent/VideoComponent";
import "./RemovedContent.css"

const RemovedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    contentData,
    refetchLinks,
  } = props;
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  const onAdd = (post_id: string) => {
    setDeletePostIds((prev: any) => {
      const idsArray = prev.split(",").filter(Boolean);
      const filteredArray = idsArray.filter((elem: any) => elem !== post_id);

      return filteredArray.join(",");
    });

    props.handleRemovedPostIds(post_id, "add");
  };
  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,

    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(
    username,
    user_id,

    deletePostIds?.split(",")
  );
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    // const { data: user, error } = await supabase
    //   .from(`${username}`)
    //   .update({ product_name: value })
    //   .eq("post_id", post_id)
    //   .eq("user_id", user_id);
    // const { data: newContent, error: newErroor } = await supabase
    //   .from("content_new")
    //   .update({ product_name: value })
    //   .eq("post_id", post_id)
    //   .eq("user_id", user_id);
    const { data: user, error } = await supabase
    .from(`${username}`)
    .select("*")
    .eq("post_id", post_id)
    .eq("user_id", user_id);

  const { data } = await supabase
    .from(`${username}`)
    .update({
      product_name: value,
      combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
    })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  const { data: newContent, error: newErroor } = await supabase
    .from("content_new")
    .update({
      product_name: value,
      combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
    })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
    const { data: bankContent, error: bankErroor } = await supabase
    .from("product_bank")
    .update({
      name: value,
      // combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
    })
    .eq("product_id", post_id)
    .eq("user_id", user_id);
    setEdit(false);
    setEditIndex("");
    // refetchLinks();

    refetchDashboard();
    PostIdDataRefetch()
  };

  const SkeletonStatBox = () => {
    return (
      <div
        className="skeleton-stat"
      >
        <Skeleton
          className="skeleton-style"
        />
      </div>
    );
  };
  return (
    <>
      <div
        className="removed-content"
      >
        <h5>
          Removed Content
        </h5>

        {PostIdData?.filter((element: any) => element !== undefined) ? (
          <div
            className="addedSvg"
          >
            {PostIdData.filter((element: any) => element !== undefined)
              .length ? (
              PostIdData.filter((element: any) => element !== undefined).map(
                (post: any, index: any) => (
                  <React.Fragment key={index}>
                    <div
                      key={index}
                      className="feed-tab-post-container post-container"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          post?.platform === "newsletter"
                            ? {
                                backgroundColor: "#efeded",
                                textAlign: "center",
                              }
                            : {}
                        }
                      >
                        <Button
                          onClick={() => onAdd(post.post_id)}
                          className="carousel-content-add-btn"
                        >
                          Add
                        </Button>

                        {post?.platform !== "newsletter" ? (
                          <>
                            {post?.media_url ? (
                              <>
                                {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {post?.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            // <video
                                            //   style={{
                                            //     maxHeight: "300px",
                                            //   }}
                                            //   className="feed-tab-media"
                                            //   autoPlay
                                            //   playsInline
                                            //   muted
                                            //   loop
                                            //   poster={
                                            //     post?.thumbnail &&
                                            //     post?.thumbnail.split(",")
                                            //       .length &&
                                            //     post?.thumbnail.split(",")[
                                            //       index
                                            //     ]
                                            //       ? post?.thumbnail.split(",")[
                                            //           index
                                            //         ]
                                            //       : "/assets/dummy_video.png"
                                            //   }
                                            // >
                                            //   <source src={media_url} />
                                            // </video>
                                            <div className="h-255">
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                post.thumbnail.split(",").length &&
                                                post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[index]
                                                  : "/assets/dummy_video.png"
                                              }
                                            />
                                          </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mh-300"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {post.media_url.includes("video") ? (
                                      // <video
                                      //   style={{
                                      //     maxHeight: "300px",
                                      //     whiteSpace: "nowrap",
                                      //     overflowY: "scroll",
                                      //     borderRadius: "1rem",
                                      //   }}
                                      //   className="feed-tab-media-check"
                                      //   autoPlay
                                      //   playsInline
                                      //   muted
                                      //   loop
                                      //   poster={
                                      //     post.thumbnail
                                      //       ? post.thumbnail
                                      //       : "/assets/dummy_video.png"
                                      //   }
                                      // >
                                      //   <source src={post.media_url} />
                                      // </video>
                                      <div className="h-255">
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={
                                          post.thumbnail &&
                                          post.thumbnail.split(",").length &&
                                          post.thumbnail.split(",")[index]
                                            ? post.thumbnail.split(",")[index]
                                            : "/assets/dummy_video.png"
                                        }
                                      />
                                    </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          post.media_url
                                            ? post.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        ) : (
                          <div>
                            <h4 className="mt-7">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post?.post_date))}
                            </h4>
                            <h4> Newsletter</h4>
                          </div>
                        )}
                      </div>
                      {edit === true && editIndex === index ? (
                        <>
                          <Input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                          />{" "}
                          <HiCheck
                            onClick={() => {
                              handleDone(post.post_id);
                            }}
                          />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign:
                              post.product_name !== "" ? "left" : "center",
                          }}
                        >
                          {post?.product_name}{" "}
                          <HiPencil
                            onClick={() => {
                              setValue(post.product_name);
                              setEdit(true);
                              setEditIndex(index);
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </React.Fragment>
                )
              )
            ) : (
              <React.Fragment>
                <>
                  <div
                    className="skeleton-stat-box"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default RemovedContentTable;
