// import React, { useState } from "react";
// import { BsInfoCircle } from "react-icons/bs";
// import { updateName } from "../../../../Utils/SupabaseServices/UserDatabase";
// import { Button } from "antd";

// const Name = (props: any) => {
//   const { username, userData, setIsName } = props;
//   const [name, setName] = useState(userData.name || "");
//   const [editName, setEditName] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalTitle, setModalTitle] = useState("");
//   const [nameError, setNameError] = useState("");
//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   function handleUpdateValue(e: any, option: any) {
//     if (option === "name") {
//       if (e.target.value === "") {
//         setName(e.target.value);

//         setNameError("Name cannot be empty");
//       } else {
//         setName(e.target.value);
//         setNameError("");
//       }
//     }
//   }
//   //
//   const doneEdit = async (option: any) => {
//     if (option === "name") {
//       if (name === "" || name === "null") {
//         setModalTitle("Enter Name!");
//         showModal();
//         await updateName(username, name);
//         setIsName(false);
//       } else {
//         await updateName(username, name);
//         setIsName(true);
//       }
//     }
//   };
//   return (
//     <div className="account-grid-item">
//       <div>
//         <h3 className="account-grid-item-key">
//           Name{" "}
//           <BsInfoCircle
//             className="info-tooltip"
//             data-tooltip-id="displayName"
//           />
//         </h3>

//         <h3 className="account-grid-item-value" id="name">
//           {!editName ? (
//             name ? (
//               name
//             ) : (
//               " "
//             )
//           ) : (
//             <input
//               type="text"
//               className="account-value-input"
//               onChange={(e: any) => handleUpdateValue(e, "name")}
//               value={name}
//             />
//           )}
//           {!editName ? (
           
//             <Button
//               className="changeButton"
//               id="name"
//               onClick={() => {
//                 setEditName(true);
//               }}
//             >
//               Change
//             </Button>
//           ) : (
//             <>
//               <div className="saveButtonDiv">
//                 <Button
//                   className="saveButton"
//                   onClick={() => {
//                     setEditName(false);
//                     doneEdit("name");
//                   }}
//                 >
//                   Save
//                 </Button>
//                 <Button
//                   className="saveButton"
//                   onClick={() => {
//                     setEditName(false);
//                   }}
//                 >
//                   Cancel
//                 </Button>
//               </div>
//             </>
            
//           )}
//         </h3>
//         {nameError ? <p className="nameError">{nameError}</p> : ""}
//       </div>
//     </div>
//   );
// };

// export default Name;
import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { updateName } from "../../../../Utils/SupabaseServices/UserDatabase";
import { Button } from "antd";

const Name = (props: any) => {
  const { username, userData, setIsName } = props;
  const [name, setName] = useState(userData.name || "");
  const [originalName, setOriginalName] = useState(userData.name || ""); // Keep track of original name
  const [editName, setEditName] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [nameError, setNameError] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  function handleUpdateValue(e: any, option: any) {
    if (option === "name") {
      if (e.target.value === "") {
        setName(e.target.value);
        setNameError("Name cannot be empty");
      } else {
        setName(e.target.value);
        setNameError("");
      }
    }
  }

  const doneEdit = async (option: any) => {
    if (option === "name") {
      if (name === "" || name === "null") {
        setModalTitle("Enter Name!");
        showModal();
        await updateName(username, name);
        setIsName(false);
      } else {
        await updateName(username, name);
        setIsName(true);
      }
    }
  };

  return (
    <div className="account-grid-item">
      <div>
        <h3 className="account-grid-item-key">
          Name{" "}
          <BsInfoCircle
            className="info-tooltip"
            data-tooltip-id="displayName"
          />
        </h3>

        <h3 className="account-grid-item-value" id="name">
          {!editName ? (
            name ? (
              name
            ) : (
              " "
            )
          ) : (
            <input
              type="text"
              className="account-value-input"
              onChange={(e: any) => handleUpdateValue(e, "name")}
              value={name}
            />
          )}
          {!editName ? (
            <Button
              className="changeButton"
              id="name"
              onClick={() => {
                setEditName(true);
                setOriginalName(name); // Store the current name before editing
              }}
            >
              Change
            </Button>
          ) : (
            <>
              <div className="saveButtonDiv">
                <Button
                  className="saveButton"
                  onClick={() => {
                    setEditName(false);
                    doneEdit("name");
                  }}
                  disabled= {nameError == "Name cannot be empty"}
                >
                  Save
                </Button>
                <Button
                  className="saveButton"
                  onClick={() => {
                    setEditName(false);
                    setName(originalName); // Revert to the original name on cancel
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </h3>
        {nameError ? <p className="nameError">{nameError}</p> : ""}
      </div>
    </div>
  );
};

export default Name;
