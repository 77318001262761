import dayjs from "dayjs";
import { useQuery, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadCustomContent(
  username: any,
  user_id: any,
  postIds: any,
  title:any
) {
const queryClient=useQueryClient()
  
  async function getPaginatedFeed() {

    let data,
      error = null;
    let finalData: any = [];
    let post_ids = [...new Set(postIds?.split(","))];
  

    const { data: rpc, error: rpcError }: any = await supabase
      .from(`${username}`)
      .select("*")
      .eq("user_id", user_id)
      .in("post_id", post_ids)
      .ilike("index", "%active%");
    const { data: productData = [] } = await supabase.rpc(
      "get_productbankpostidd",
      {
        userid: user_id,
        post_ids: post_ids,
      }
    );
    finalData = productData !== null ? [...rpc, ...productData] : [...rpc];
    data = Array.from(
      new Map(finalData.map((post: any) => [post.post_id, post])).values()
    );
    data.sort(
      (a: any, b: any) =>
        post_ids.indexOf(a.post_id) - post_ids.indexOf(b.post_id)
    );

    error = rpcError;

    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  // return useQuery(["customContent", user_id,title, postIds], getPaginatedFeed, {
  //   refetchOnMount: true,
  //   enabled:Boolean(title)  && Boolean(postIds),
  // });
  const queryResult = useQuery(["customContent", user_id, title, postIds], getPaginatedFeed, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(title) && Boolean(postIds) && Boolean(postIds.length>=0),
  });

  // Force refetch if needed
  const forceRefetch = () => {
    queryClient.invalidateQueries(["customContent", user_id, title, postIds]);
  };

  return { ...queryResult, forceRefetch };
}
