import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/skeleton.css";
const DashboardSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <div className="skeleton-dashboard-grid-item">
        <Skeleton />
      </div>
    );
  }
  return (
    <div className="content-container">
      <div className="row">
        <div id="12" className="left-panel box">
          <div className="drawer">
            <div className="drawer-menu">
              {/* <div className="skeleton-drawer">
                <Skeleton />
              </div> */}
            </div>
          </div>
        </div>
        <div className="right-panel box">
          <div className="row">
            <div className="skeleton-dashboard">
            {[...Array(4)].map((_, index) => <SkeletonStatBox key={index} />)}

              {/* {Array(4).fill(<SkeletonStatBox/>)} */}
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
