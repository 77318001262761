import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../StaticVariables";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadProductBankPages(
  userIds: any,

  postsPerPage: number,
  filter: any,
  usernames: any,
  productFilter?: any
) {
  async function getCount() {
    let feedCount;
    let postIds;

    const input = filter
      ? filter
          .split(" ") // Split filter string into words
          .filter(
            (word: string) =>
              !StaticVariables().connectingWords.includes(word.toLowerCase())
          ) // Remove connecting words
          .map((elem: string) => `%${elem}%`)
      : [];
    if (!productFilter || (productFilter && productFilter === "All")) {
      const { data, error } = await supabase.rpc("get_productbankcounttt", {
        query_string: input,
        userid: userIds,
        query_tagg: input,
      });
      feedCount = data?.[0]?.count;
    } else {
      const { data, error } = await supabase.rpc("get_productbankcountfil", {
        query_string: input,
        userid: userIds,
        query_tagg: input,
        filter: productFilter,
      });
      feedCount = data?.[0]?.count;
    }

    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(
    ["totalProductBankCount", userIds, filter, productFilter],
    getCount,
    {
      // enabled: Boolean(userIds),
    }
  );
}
