import { useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

/**
 * Load User info
 */
export function useLoadIncludedUsers() {
	async function getIncludedUsers() {
		const { data, error } = await supabase
		.from("user")
		.select("username,user_id")
		.eq("global_view", true);

	if (data) {
		const users: any = [];
		const userIds: any = [];
		data.map((data: any) => {
			users.push(data.username);
			userIds.push(data.user_id);
		});
		return { users, userIds };
	}
	}

	return useQuery(["getIncludedUsers"], getIncludedUsers, 
    {
      refetchOnWindowFocus: true
    });
}
