import { useQuery, useQueryClient } from 'react-query';


export function useLoadParams(queryKey: any, category: any,location:any) {
  const queryClient = useQueryClient();

  const fetchQueryData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const fromParam = params.from || '';
    const termParam = params.term ? decodeURIComponent(params.term) : '';

    return { from: fromParam, term: termParam };
  };

  return useQuery(
    ['fetchQueryData', queryKey, category,location],
    fetchQueryData,
    {
      refetchOnWindowFocus: false, // Disable automatic refetch
    }
  );
}
