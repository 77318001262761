import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export default function useUpdatePostStatus() {
  const queryClient = useQueryClient();

  const updateIndexValue = async ({
    newPostStatus,
    postId,
    username,
    user_id,
  }: {
    newPostStatus: "archived" | "active" | "active,ad" | "active,featured";
    postId: string;
    activeKey: string;
    pageIndex: number;
    postIndex: number;
    filter: string | null;
    username: any;
    user_id: any;
  }) => {
    const { error: creatorError } = await supabase
      .from(`${username}`)
      .update({ index: newPostStatus })
      .eq("post_id", postId)
      .eq("user_id", user_id);

    if (newPostStatus === "active,ad") {
      const { data: adPost }: any = await supabase
        .from(`${username}`)
        .select("*")
        .eq("post_id", postId)
        .eq("user_id", user_id)

        .limit(1);
      if (adPost && adPost.length) {
        delete adPost[0].fts;
        delete adPost[0].id;
        delete adPost[0]?.combined_text;

        adPost[0]["username"] = username;
        adPost[0]["thumbnail"] = adPost[0]?.thumbnail
          ? adPost[0].thumbnail
          : "";

        const { error: adInsertError } = await supabase
          .from("ads_content")
          .insert([adPost[0]]);

        if (adInsertError) {
        }
      }
    }
    if (newPostStatus === "active,featured") {
      const { error: creatorError } = await supabase
        .from("ads_content")
        .delete()
        .eq("post_id", postId)
        .eq("user_id", user_id);
    }

    if (creatorError) {
      throw new Error(creatorError?.message);
    } else {
      return true;
    }
  };

  return useMutation(updateIndexValue, {
    onMutate: async ({ newPostStatus, postId }) => {
      await queryClient.cancelQueries("paginatedPosts");

      const previousData = queryClient.getQueryData(["paginatedPosts"]);

      queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
        if (oldData) {
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              post.post_id === postId ? { ...post, index: newPostStatus } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["paginatedPosts"], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries("paginatedPosts");
    },
  });
}
