import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";

import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";

import TagsBox from "../../../components/TagsBox/TagsBox";
import "../Feed/feedTab.css";
import { updateCommentsData } from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useUpdateTags from "./useUpdateTags";
import useUpdatePostAdIndex from "../Feed/useUpdatePostIndex";

import FeedActionButtons from "./SubComponents/FeedActionButtons";
import FeedContentPost from "./SubComponents/FeedContentPost";
const ContentSearchTable = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenLinksContent,
  isLoading,
  loadingRef,
  totalSize,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentsData, setCommentsData] = useState<any>([]);
  const [noCommentsMsg, setNoCommentsMsg] = useState("");
  const [loadingComments, setLoadingComments] = useState(true);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();
  const [localData, setLocalData] = useState<any>(data);
  // useEffect(() => {

  //   setLocalData(data);
  // }, [data]);
  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePostAdIndex } = useUpdatePostAdIndex();

  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    updateTags({
      activeKey,
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
    });
  }
  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();
      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;
      updateTags({
        activeKey,
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
      });
      // });

      e.target.value = "";
    }
  }

  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];
    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;
    try {
      await updatePostAdIndex({
        user_id,
        sourceIndex,
        targetIndex,
        activeKey,
        post_id,
        username,
        newData,
      });

      // Update the new data order after mutation is complete
      const [removed] = newData.splice(result.source.index, 1);
      newData.splice(result.destination.index, 0, removed);

      refetchData();
      setLocalData(newData);

    } catch (error) {
      console.error("Error updating post ad index:", error);
    }
  };
  return (
    <>
      <DragDropContext
        onDragStart={() => {
          document.documentElement.style.scrollBehavior = "unset";
          document.body.style.scrollBehavior = "unset";
        }}
        onDragEnd={(result: any) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="ad">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="feed-tab-posts-table">
                {loadingRef === false && !isLoading ? (
                  <>
                    {localData && localData.length >= 0 ? (
                      <Fragment key={1}>
                        {localData.map((image: any, index: number) => {
                          return (
                            <Draggable
                              key={image.post_id}
                              draggableId={image.post_id}
                              index={index}
                              isDragDisabled={
                                activeKey === "active,ad" ? false : true
                              }
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`feed-tab-post-row ${activeKey === "active,ad"
                                    ? "feed-tab-post-row-grab"
                                    : ""
                                    } ${draggedIndex === index
                                      ? "feed-tab-post-row-dragging"
                                      : ""
                                    }`}
                                >
                                  {activeKey === "active,ad" && (
                                    <div className="feed-tab-drag-btn">
                                      <MdOutlineDragIndicator />
                                    </div>
                                  )}

                                  <div className="feed-tab-row-media">
                                    <FeedContentPost image={image} />

                                    <div className="feed-tab-row-actions-mobile">
                                      <FeedActionButtons
                                        activeKey={activeKey}
                                        post={image}
                                        postIndex={image.index}
                                        postId={image.post_id}
                                        pageIndex={image.pageIndex}
                                        index={index}
                                        type={image.type}
                                        url={image.media_url}
                                        filter={filter}
                                        username={username}
                                        adsOrder={image?.ads_order}
                                        subscription_type={subscription_type}
                                        user_id={user_id}
                                        setLimitError={setLimitError}
                                        refetchData={refetchData}
                                        setLoadingComments={setLoadingComments}
                                        setSelectedPostId={setSelectedPostId}
                                        setIsModalOpen={setIsModalOpen}
                                        setCommentsData={setCommentsData}
                                        setNoCommentsMsg={setNoCommentsMsg}
                                      />
                                    </div>
                                  </div>

                                  <div className="feed-tab-row-tags">
                                    <div className="search-container-tagInput">
                                      <input
                                        type="text"
                                        id="tagInput"
                                        enterKeyHint="enter"
                                        placeholder="Enter tags here"
                                        className="tagInput"
                                        onKeyUp={(e: any) => {
                                          submitTag(
                                            e,
                                            image.tagg,
                                            e.target.value,
                                            image.post_id,
                                            1,
                                            1
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="image-table-tags">
                                      {image.tagg || image.table_tags ? (
                                        <TagsBox
                                          tagsArray={
                                            image.tagg
                                              ? image.tagg.split(",")
                                              : image.table_tags
                                                ? image.table_tags.split(",")
                                                : [""]
                                          }
                                          removeTag={(tag: string) => {
                                            removeTag(
                                              image.tagg
                                                ? image.tagg.split(",")
                                                : image.table_tags
                                                  ? image.table_tags.split(",")
                                                  : [""],
                                              tag,
                                              image.post_id,
                                              1,
                                              index
                                            );
                                          }}
                                          page="content"
                                        />
                                      ) : (
                                        <p className="noTags">No Tags</p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="feed-tab-actions">
                                    <FeedActionButtons
                                      activeKey={activeKey}
                                      post={image}
                                      postIndex={image.index}
                                      postId={image.post_id}
                                      pageIndex={image.pageIndex}
                                      index={index}
                                      type={image.type}
                                      url={image.media_url}
                                      filter={filter}
                                      username={username}
                                      subscription_type={subscription_type}
                                      user_id={user_id}
                                      setLimitError={setLimitError}
                                      refetchData={refetchData}
                                      setLoadingComments={setLoadingComments}
                                      setSelectedPostId={setSelectedPostId}
                                      setIsModalOpen={setIsModalOpen}
                                      setCommentsData={setCommentsData}
                                      setNoCommentsMsg={setNoCommentsMsg}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <p className="">
                        {filter && filter !== "" ? (
                          <>
                            {activeKey === "archived" ? (
                              <i>
                                No archived posts found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : activeKey === "active,ad" ? (
                              <i>
                                No ads found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : activeKey === "active,featured" ? (
                              <i>
                                No featured posts found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : (
                              <i>No search results found.</i>
                            )}
                          </>
                        ) : (
                          <>
                            {activeKey === "archived" ? (
                              <i>No posts have been archived yet.</i>
                            ) : activeKey === "active,ad" ? (
                              <i>No posts have been marked as an ad yet.</i>
                            ) : activeKey === "active,featured" ? (
                              <i>No posts have been marked as featured yet.</i>
                            ) : (
                              <i>
                                Your data has not been processed yet. Come back
                                soon!
                              </i>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="">
                      {filter && filter !== "" ? (
                        <>
                          {activeKey === "archived" ? (
                            <i>
                              No archived posts found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : activeKey === "active,ad" ? (
                            <i>
                              No ads found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : activeKey === "active,featured" ? (
                            <i>
                              No featured posts found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : (
                            <i>No search results found.</i>
                          )}
                        </>
                      ) : (
                        <>
                          {activeKey === "archived" ? (
                            <i>No posts have been archived yet.</i>
                          ) : activeKey === "active,ad" ? (
                            <i>No posts have been marked as an ad yet.</i>
                          ) : activeKey === "active,featured" ? (
                            <i>No posts have been marked as featured yet.</i>
                          ) : (
                            <i>
                              Your data has not been processed yet. Come back
                              soon!
                            </i>
                          )}
                        </>
                      )}
                    </p>
                  </>
                )}
              </div>

              <Modal
                title="Show Relevant Comments"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 300px)",
                }}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                  </Button>,
                ]}
              ></Modal>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ContentSearchTable;
