import { useState } from "react";
import { supabase } from "../../config/supabaseClient";
import { useQuery } from "react-query";
import {
  // fetchSearchResultsCount,
  getEmbedding,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { StaticVariables } from "../StaticVariables";
// import { useSearchData } from "./useLoadSearchbarData";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archive"
 * @param postsPerPage
 * @returns
 */
export function useFeedPostsPaginationEmbedding(
  username: any,
  user_id: any[],
  queryTerm: any,
  category: any,
  postsPerPage: number,
  filter: any[],
  activeHighlights: any,
  openAiSearch: boolean,
  extraId: any[],
  trending: any,
  showSocials: any[],
  threshold: any
) {
  postsPerPage = 10;
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  trending = trending?.trim();
  async function getCount() {
    let feedCount;
    let postIds: any = [];
    const filteredArray = filter.filter((item: any) => item !== "ALL");

    if (user_id && user_id.length === 1) {
      if (category && category === "searchbar") {
        const elementsCount = 5000;
        // const embedding = await getEmbedding(queryTerm);
        const connectingWords = StaticVariables().connectingWords


        // Split the sentence into words
        let words = queryTerm.split(" ");
        if(words.includes("shop") || words.includes("buy")){
          // contentOrder="shoppable"

          words = words.filter((word:any) => word !== "shop" && word !== "buy");
        }
     
        // Filter out connecting words
        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );
       
        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const embedding = await getEmbedding(pgQuery);
        const { data: countResult, error: countError } = await supabase.rpc(
          // "semantic_searchfiltercount",
          // "semantic_ssearchcounthybrid",
          // "semantic_ssearchcountembed",
          // "semantic_ssearchcountembedpostid",
          "semantic_ssearchcountembedpostidsim",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            // similarity_threshold: 0.3146,
            similarity_threshold: threshold,

            // match_count: elementsCount,
            userid: user_id[0],
            username: username,
            socials: showSocials,
            // filter:filter
          }
        );

        let resCount = countResult.length;
        postIds = countResult.map((item: any) => item.post_id);
        // if (countResult?.[0]?.count === 0) {
        //   resCount = 0;
        // } else {
        //   resCount = countResult?.[0]?.count ;
        // }
        feedCount = resCount;

        // if (feedCount) {
        return {
          totalPages: Math.ceil(feedCount / postsPerPage),
          feedCount: feedCount,
          postIds: postIds || null,
        };
        // } else {
        //   return 0;
        // }
      }
    }
  }

  return useQuery(
    ["getFeedPageEmbeddingCount", filter, queryTerm, trending, category],
    getCount,
    {
      enabled: Boolean(threshold),
      // refetchOnWindowFocus: true,
    }
  );
}
