// import React from "react";
// import Skeleton from "react-loading-skeleton";

// const DataSkeleton = () => {
//   function SkeletonStatBox() {
//     return (
//       <div className="skeleton-account-grid-item">
//         <Skeleton
//           style={{
//             flex: "0 0 auto",
//             width: "100px",
//             height: "300px",
//             marginRight: "30px",
//             cursor: "pointer",
//             borderRadius: "15px",
//           }}
//         />
//       </div>
//     );
//   }
//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           paddingTop: "20px",
//           paddingBottom: "10px",
//         }}
//       >
//         <div className="outer-container-reels-skeleton">
//         <div className="links-grid-container-reels-skeleton">
//         {[...Array(3)].map((_, index) => (
//           <SkeletonStatBox key={index} />
//         ))}
//         </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DataSkeleton;


import React from "react";
import Skeleton from "react-loading-skeleton";

const DataSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <div className="m-10px">
        <Skeleton
        className="skeleton"
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="mainDiv"
      >
        <div
          className="data-skeleton"
        >
          {[...Array(3)].map((_, index) => (
            <SkeletonStatBox key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataSkeleton;
