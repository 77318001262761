import { Session, User } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext } from "react";
import { supabase } from "../config/supabaseClient";

// create a context for authentication
const AuthContext = createContext<{
	isOnboardingFinished: boolean;
	username: string | null;
	session: Session | null;
	user: User | null;
	signOut: () => void;
	currentOnboardingStep: number;
}>({
	isOnboardingFinished: false,
	username: null,
	session: null,
	user: null,
	signOut: () => {},
	currentOnboardingStep: 1,
});

export const AuthProvider = ({ children }: any) => {
	const [username, setUsername] = useState<string | null>(null);
	const [user, setUser] = useState<User | null>(null);
	const [session, setSession] = useState<Session | null>(null);
	const [loading, setLoading] = useState(true);
	const [currentOnboardingStep, setCurrentOnboardingStep] = useState(1);
	const [isOnboardingFinished, setIsOnboardingFinished] = useState(false);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			setUser(session?.user || null);
			if (session?.user && session.user.user_metadata) {
				setCurrentOnboardingStep(
					session.user.user_metadata?.currentOnboardingStep || 1
				);
				setUsername(session.user.user_metadata?.username || null);
				setIsOnboardingFinished(
					session.user.user_metadata?.isOnboardingFinished || false
				);
			}
			setLoading(false);
		});

		const { data: listener } = supabase.auth.onAuthStateChange(
			(event, session) => {
				if (event === "SIGNED_OUT") {
					setUsername(null);
					setUser(null);
					setSession(null);
					setIsOnboardingFinished(false);
					setCurrentOnboardingStep(1);
				} else {
					setSession(session);
					setUser(session?.user || null);
					if (session?.user && session.user.user_metadata) {
						setCurrentOnboardingStep(
							session.user.user_metadata?.currentOnboardingStep || 1
						);
						setUsername(session.user.user_metadata?.username || null);
						setIsOnboardingFinished(
							session.user.user_metadata?.isOnboardingFinished || false
						);
					}
				}
				setLoading(false);
			}
		);

		return () => {
			listener?.subscription.unsubscribe();
		};
	}, []);

	const value = {
		isOnboardingFinished,
		username,
		session,
		user,
		signOut: () => supabase.auth.signOut(),
		currentOnboardingStep,
	};

	// use a provider to pass down the value
	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
};

// export the useAuth hook
export const useAuth = () => {
	return useContext(AuthContext);
};
