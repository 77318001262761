import React, { useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { Button } from "antd";
import Card from "react-bootstrap/esm/Card";
import PuffLoader from "react-spinners/PuffLoader";
import "../SubComponents.css";
import { useLocation, useParams } from "react-router-dom";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import ContentSkeletonTemplateWrapper from "../ContentSkeletonTemplateWrapper";
import { useLoadCustomProduct } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
const ProductBankWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,

    postIds,
    brand_color,
  } = props;
  const location = useLocation();
const param=useParams()
  const { isLoading: CustomProductLoading, data: CustomProduct }: any =
    useLoadCustomProduct(user_id, username, title);

  function handleRedirect(
    postId: any,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists

      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }
  return (
    <>
      <div>
        {CustomProductLoading ? (
          <div className="outer-container-subcomponents">
            <ContentSkeletonTemplateWrapper />
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomProduct && CustomProduct.length ? (
              <div className="links-grid-container-subcomponents">
                {CustomProduct &&
                  CustomProduct.map((link: any,index:any) => (
                    <React.Fragment key={index}>
                      <a
                        href={link?.table_url}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect(
                            link?.table_id,
                            link?.table_image,
                            title,
                            link?.table_name
                          );
                          if (link?.table_url && link?.table_url !== "") {
                            window.open(link?.table_url, "_blank");
                          }
                        }}
                      >
                        <div className={"creator-feed-image-wrapper-links"}>
                          <Card.Img
                            variant="top"
                            src={link?.table_image}
                            className={"creator-feed-card-links-image"}
                            loading="eager"
                            style={{
                              backgroundColor: brand_color && brand_color,
                            }}
                          />
                        </div>
                        {link?.table_name && link?.table_image && (
                          <p
                          className="product-name"
                          >
                            {link?.table_name}
                          </p>
                        )}
                        {isLinks && (
                          <Button className={"template-shop-btn-links"}>
                            Shop
                          </Button>
                        )}
                      </a>
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductBankWrapper;
