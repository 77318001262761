import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Hashtags = () => {
  return (
    <div className="skeleton-hashtag-container">
      <Skeleton className="skeleton-hashtag"/>
    </div>
  );
};

export default Hashtags;
