import { useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

export function useLoadTopHashtags(user_id: any[], max: any) {
  async function getFeedTopHashtags() {
    let { data: topHashtags } = await supabase
      .from("searches")
      .select("*")
      .eq("type", `hashtag`)
      .in("user_id", user_id)
      .order("occurrences", { ascending: false })
      .range(0, max - 1);

    if (!topHashtags) {
      return false;
    } else {
      let topHashtagsArr:any=[]
      topHashtags.map((elem:any)=>{
        topHashtagsArr.push(elem.query)
      })
      return topHashtagsArr;
    }
  }
  return useQuery(["getFeedTopHashtags", user_id, max], getFeedTopHashtags, 
  {
    refetchOnWindowFocus: true
  });
}
