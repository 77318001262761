import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ContentSkeletonTemplateWrapper = () => {
    const SkeletonStatBox = () => {
        return (
            
            <div className='skeleton-loader'>
                <Skeleton
                    className='skeletonStyle'
                />
            </div>
        );
    }

    return (
       <div className='stat-box'>
            {[...Array(5)].map((_, index) => (
                <SkeletonStatBox key={index} />
            ))}
       </div>
    );
}

export default ContentSkeletonTemplateWrapper;
