import React from "react";
import {Link} from 'react-router-dom'
const AuthBackBtn = (props:any) => {
  return (
    <>
      <div className="back-btn">
        <Link to={props.route}>&#10140;</Link>
      </div>
    </>
  );
};

export default AuthBackBtn;
