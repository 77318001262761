import { useState } from "react";
import "./DateSelector.css";

function DateSelector({
  handleChange,
}: {
  handleChange: (days: number) => void;
}) {
  const [activeTab, setActiveTab] = useState(0);

  const items = [
    {
      label: "All",
      key: 0,
    },
    {
      label: "Last 24 hours",
      key: 1,
    },
    {
      label: "Last 7 days",
      key: 7,
    },
    {
      label: "Last 30 days",
      key: 30,
    },
  ];

  const handleMenuClick = (key: number) => {
    setActiveTab(key);
    handleChange(key);

  };

  return (
    <div
      className="date-scroll date-selector"
    >
      {items.map((item) => (
        <p
          key={item.key}
          className={`date-selector-btn ${
            item.key === activeTab ? "active" : ""
          }`}
          onClick={() => handleMenuClick(item.key)}
        >
          {item.label}
        </p>
      ))}
    </div>
  );
}

export default DateSelector;
