import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadTrendingProducts(
  user_id: string,
  username: any,
  title: any,
  numberOfClicks: any,
  removedPostIds:any,
  startDate:any,
  endDate:any
) {
  const fetchTrendingProducts = async () => {
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",").filter((elem: any) => elem !== ""))
      );
    }
    const isNotDateFormat = (dateString:any) => {
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      return !dateFormatRegex.test(dateString);
    };
    if(startDate && endDate && !isNotDateFormat(startDate) && !isNotDateFormat(endDate)){
    const { data,error } = await supabase.rpc("get_post_clicks_analyticss_trending_date", {
      route_name: `/${username}`,
      userid: user_id,
      start_date:startDate,
      end_date:endDate
    });

    if (data) {
      const transformedData = data.reduce((acc: any, item: any) => {
        const post = JSON.parse(item.post);

        if (!acc[post.title]) {
          acc[post.title] = [];
        }

        acc[post.title].push({
          post_id: post.post_id,
          media_url: post.media_url,
          count: item.count,
          name: post.name,
        });

        return acc;
      }, {});

      const postIds = Array.from(
        new Set(
          Object.keys(transformedData)
            .flatMap((key: any) =>
              transformedData[key].filter(
                (post: any) =>
                  post.count >= numberOfClicks &&
                  typeof post.post_id === "string"
              )
            )
            .sort((a: any, b: any) => b.count - a.count)
            .map((post: any) => post.post_id)
        )
      );

      const [
        { data: searchResult = [], error: searchError },
        { data: com = [], error },
      ] = await Promise.all([
        supabase.rpc("searchresultt", {
          userid: user_id,
          username: username,
          postids: postIds,
        }),
        supabase.rpc("productbankresult", {
          userid: user_id,
          postids: postIds,
        }),
      ]);
            const result = [...searchResult, ...com];

      let filteredArray = result;

      if (removedPostIds) {
        filteredArray = result.filter(
          (obj: any) => !removedPostIds.includes(obj.post_id)
        );
      }
      
      
      // Return the first 25 items after filtering
      return filteredArray.slice(0, 25);
      // return result.slice(0, 25);
    }
  }
  };
  return useQuery(
    ["TrendingProducts", user_id, numberOfClicks,removedPostIds,startDate,endDate],
    fetchTrendingProducts,
    {
      enabled: Boolean(title) && Boolean(numberOfClicks) && Boolean(removedPostIds?.length >= 0),
    }
  );
}
