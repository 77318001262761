import { Button, Input, Modal, Upload, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { updateComponentSingle } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { supabase } from "../../../../../config/supabaseClient";

const EditCustomBanner = (props: any) => {
  const {
    isBannerEditModalOpen,
    user_id,
    username,
    type,
    component,
    setSelectedComponent,
  } = props;
  const [name, setName] = useState(component?.name || "");
  const [link, setLink] = useState(component?.details?.split(",")[1] || "");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cta, setCTA] = useState(component?.cta_placeholder);

  const [imageUrl, setImageUrl] = useState(
    component?.details?.split(",")[0] || ""
  );
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    setName(component?.name);
    setLink(component?.details?.split(",")[1]);
    setImageUrl(component?.details?.split(",")[0]);
    setCTA(component?.cta_placeholder);
  }, [component]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    setIsButtonDisabled(!name || !link || !imageUrl ||!cta);
  }, [name, link, imageUrl,cta]);
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponentSingle(
      name,
      component,
      [imageUrl, link].toString(),
      username,
      user_id,
      cta,
      "banner"
    );
    if (res === "true") {
      props.refetch();
      props.setIsBannerEditModalOpen(false);
      setName("");
      setLink("");
      setImageUrl("");
      setErrorMsg("");
      setCTA("");
      setSelectedComponent("");
      setConfirmLoading(false);

    } else {
      setErrorMsg("Banner with this name already exists.");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsBannerEditModalOpen(false);
    setName("");
    setLink("");
    setImageUrl("");
    setErrorMsg("");
    setCTA("");
    setSelectedComponent("");
  };

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
  };

  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.staus = "uploaading";
        }
        return file;
      });

      setTimeout(() => {
        info.file.status = "done";
      }, 1000);

      const { data: highlightslist }: any = await supabase.storage
        .from("banner_section")
        .list(`${username}/${name}`, { limit: 5000 });

      for (let item of highlightslist) {
        await supabase.storage
          .from("banner_section")
          .remove([`${username}/${name}/${item.name}`]);
      }
      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("banner_section")
        .upload(
          `${username}/${name}/${info.file.type.split("/")[0]}${post_id}`,
          info.file.originFileObj
            ? info.file.originFileObj
            : info.fileList[info.fileList.length - 1].originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("banner_section")
        .getPublicUrl(
          `${username}/${name}/${info.file.type.split("/")[0]}${post_id}`
        );
      setImageUrl(url.publicUrl);
    },
  };
  return (
    <>
      <Modal
        open={isBannerEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: (!name || !cta || !link || !imageUrl) }}
      >
        <div className="title">
          <h5>
            Editing {name}
          </h5>

          <div className="preview">
            <div>
              <div className="previewHeading">{name}</div>
              <div>
                <Button
                  className="ant-btn css-dev-only-do-not-override-1nimcbs ant-btn-primary mw-250"
                >
                  {cta}
                </Button>
              </div>
            </div>
            <div className="previewImage">
              <img
                src={imageUrl}
              />
            </div>
          </div>
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <div className="fieldGroup">
            <label
               className="bold-label"
            >
              Banner Title
            </label>
            <Input
              placeholder="Enter Title"
              value={name}
              onChange={(e: any) => {
                handleInputChange(e.target.value, setName);
                setErrorMsg("");
              }}
              className="mb-20"
            />
            <label
               className="bold-label"
            >
              Button Text
            </label>
            <Input
              placeholder="Check it Out!"
              value={cta}
              onChange={(e: any) => handleInputChange(e.target.value, setCTA)}
              className="mb-20"
            />
            <label
               className="bold-label"
            >
              Button Link
            </label>
            <Input
              placeholder="www.yourlink.com"
              value={link}
              onChange={(e: any) => handleInputChange(e.target.value, setLink)}
              className="mb-20"
            />

            <div
            className="uploadDiv"
            >
              <Upload {...uploadProps} multiple={false} maxCount={1}>
                <Button
                  className="uploadButton"
                  disabled={!name}

                >
                  <div className="text-center">
                    <p
                      className="textContainerHeading"
                    >
                      Click to select image or drag & drop to this area to upload
                    </p>
                    <p
                      className="upload-text"
                    >
                      {" "}
                      JPG, JPEG, PNG, HEIC, or WEBP
                    </p>
                  </div>
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditCustomBanner;
