import React, { useEffect, useState } from "react";
import { Checkbox, Divider } from "antd";

const CheckboxGroup = Checkbox.Group;

const plainOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "This Weekend",
];

const CheckboxComponent = (props: any) => {
  const { daysNameArray, StayUpToDateComponents,setErrorMsg} = props;
  const [checkedList, setCheckedList] = useState<any>(daysNameArray);
  useEffect(() => {
    setCheckedList(daysNameArray);
  }, [daysNameArray]);

  const onChange = (list: any) => {
    props.setErrorMsg("");

    if (list.length <= 3) {
      setCheckedList(list);
      // setErrorMsg("You can")
      props.handleDays(list);

    }
  };

  return (
    <>
      <p  className="text-center">You can select up to 3 days for this carousel.</p>
      <Divider />

      <CheckboxGroup
        options={plainOptions}
        value={checkedList}
        onChange={onChange}
        className="checkbox"
        data-testid="days-checkbox"
      />
    </>
  );
};

export default CheckboxComponent;
