export const TabTitle = (newTitle: any) => {
	return (document.title = newTitle);
};
export function milisecondsToTimestamp(offset: number): string {
	var totalSeconds = Math.floor(offset / 1000);
	var hours = Math.floor(totalSeconds / 3600);
	var minutes = Math.floor((totalSeconds % 3600) / 60);
	var seconds = totalSeconds % 60;

	var formattedHours = hours.toString().padStart(2, "0");
	var formattedMinutes = minutes.toString().padStart(2, "0");
	var formattedSeconds = seconds.toString().padStart(2, "0");

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
