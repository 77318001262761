import { Button, Input, Modal, Popconfirm, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillTrashFill, BsInfoCircle } from "react-icons/bs";
import useUpdateCategory from "../useUpdateCategoryQueries";
import {
  addCategory,
  changeCustomCategoriesOrder,
  deleteCategory,
  editCategory,
  getCategories,
  toggleCategoryValue,
  updateDefaultSubcategory,
  updateExistingSubcategory,
} from "../../../../Utils/SupabaseServices/CustomCategoriesDatabase";
import TagsBox from "../../../../components/TagsBox/TagsBox";
import { useLoadCustomCategories } from "../../../../Utils/customHooks/useLoadCustomCategories";
import { HiCheck, HiPencil } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useUpdateCategoriesIndex from "./useUpdateCategoriesIndex";
import { MdOutlineDragIndicator } from "react-icons/md";
import { supabase } from "../../../../config/supabaseClient";
import { useParams } from "react-router-dom";

const CustomCategoriesSection = (props: any) => {
  const { username } = useParams();
  const { isModalOpen, user_id, page, categoryAdded, showCategories } = props;
  let {
    isLoading,
    data,

    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(user_id, page);

  const [localData, setLocalData] = useState<any>(data);
  useEffect(() => {
    setLocalData(data);
  }, [data]);
  const [currentCategory, setCurrentCategory] = useState<any>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isEditDefaultCategory, setIsEditDefaultCategory] = useState(false);
  const [newDefaultCategory, setNewDefaultCategory] = useState("");
  const [editDefaultCategoryIndex, seteditDefaultCategoryIndex] = useState(-1);

  const handleDeleteOk = () => {
    // deleteCategory(user_id, currentCategory)
    // setIsDeleteModalOpen(false);
    // refetchCustomCategories()
    deleteCategory(user_id, currentCategory).then(() => {
      setIsDeleteModalOpen(false);
      refetchCustomCategories();
      setIsDragging(false);
      setLocalData(data);
    });
  };
  const handleEditOk = () => {
    // editCategory(user_id, currentCategory, newCategoryName)
    // setIsEditModalOpen(false);
    // refetchCustomCategories()
    editCategory(user_id, currentCategory, newCategoryName, username).then(
      () => {
        setIsEditModalOpen(false);
        refetchCustomCategories();
      }
    );
    setNewCategoryName("");
    setCurrentCategory("");
  };
  const handleCancel = () => {
    setNewCategoryName("");

    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
  };

  // const {
  //   isLoading,
  //   data,

  //   refetch: refetchCustomCategories,
  // } = useLoadCustomCategories(user_id, page);

  useEffect(() => {
    if (categoryAdded) {
      refetchCustomCategories();
      props.setCategoryAdded(false);
    }
  }, [categoryAdded]);

  const { mutate: updateCategory } = useUpdateCategory();
  const { mutate: updateCategoriesIndex } = useUpdateCategoriesIndex();

  async function removeTag(
    tagArr: any,
    tag: any,
    categoryName: any,
    defaultTag: any
  ) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    const index = tagArr.indexOf(defaultTag);

    if (index !== -1) {
      tagArr.splice(index, 1);
    } else {
    }

    // Remove empty elements
    tagArr = tagArr.filter((word: any) => word !== "");

    if (tagArr.length > 0) {
      tagArr.unshift(defaultTag);
    } else {
      const { error: customError } = await supabase
        .from("custom_categories")
        .update({ default_query: "" })
        .eq("category_name", categoryName)
        .eq("user_id", user_id);
    }
    // Count the elements in the filtered array
    // const count = filteredArr.length;

    updateCategory({
      categoryName,
      tag,
      tagString: tagArr.toString(),
      user_id,
      action: "remove",
      pageIndex: 1,
      index: 1,
    });
    setLocalData(data);
  }
  async function submitTag(
    e: any,
    categoryName: any,
    tagArray: any,
    tag: any,
    type: any
  ) {
    e.preventDefault();
    tag = tag.trim();
    if (e.keyCode === 13) {
      if (categoryName) {
        if (type === "default") {
          await updateDefaultSubcategory(categoryName, tag, user_id);
        }

        let arrString = "";
        if (!tagArray) {
          arrString = tag;
        } else {
          if (type === "default") {
            if (!tagArray.split(",").includes(tag)) {
              arrString = [tag, ...tagArray.split(",")].toString();
            } else {
              tagArray = tagArray
                .split(",")
                .filter((item: any) => item.trim() !== tag);
              tagArray = tagArray.toString();
              arrString = [tag, ...tagArray.split(",")].toString();
              updateExistingSubcategory(categoryName, tag, user_id, true);
            }
          } else {
            if (!tagArray.split(",").includes(tag)) {
              arrString = [...tagArray.split(","), tag].toString();
            } else {
              arrString = [...tagArray.split(",")].toString();
            }
          }
        }

        updateCategory({
          categoryName,
          tag,
          tagString: arrString,
          user_id,
          action: "submit",
          pageIndex: 1,
          index: 1,
        });
      }
      setLocalData(data);

      e.target.value = "";
    }
  }

  const toggleValues = async (
    checked: boolean,
    categoryName: any,
    pageName: any
  ) => {
    await toggleCategoryValue(checked, categoryName, pageName, user_id).then(
      () => {
        refetchCustomCategories();
      }
    );
  };

  function deleteCustomCategory(category: any) {
    setIsDeleteModalOpen(true);
    setCurrentCategory(category);
  }

  function editCustomCategory(category: any) {
    setNewCategoryName(category?.name);
    setIsEditModalOpen(true);
    setCurrentCategory(category);
  }

  // const { isModalOpen, user_id, page, refetchCustomCategories } = props;
  const [categoryName, setCategoryName] = useState("");
  const [terms, setTerms] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // const { mutate: createCustomCategory } = useCreateCustomCategory();

  const handleOk = async () => {
    addCategory(user_id, categoryName).then((res: any) => {
      if (!res) {
        setErrorMsg("Category with this name already exists");
      } else {
        setErrorMsg("");
        setCategoryName("");

        props.handleCategoryAdded(true);
        props.setIsModalOpen(false);
        refetchCustomCategories();
      }
    });
  };

  const handleCreateCancel = () => {
    setCategoryName("");
    props.handleCategoryAdded(false);

    props.setIsModalOpen(false);
    setErrorMsg("")
  };
  const [isDragging, setIsDragging] = useState(false);
  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    // setIsCategoryLoading(true);
    // setIsDragging(false);

    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;
    const draggedCategory = result.draggableId;
    const newData = localData ? [...localData] : [];
    // const newData = [...data];
    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);
    // data = [...newData];
    setLocalData(newData);

    updateCategoriesIndex({
      user_id,
      newData,
    });
    setTimeout(() => {
      setIsDragging(false);
    }, 4000);
  };

  async function handleEditDefaultCategory(
    categoryName: any,
    defaultTag: any,
    tagArr: any,
    isExisting: any
  ) {
    const updateTags = async (isExistingCategory: boolean) => {
      await updateDefaultSubcategory(categoryName, newDefaultCategory, user_id);
      updateExistingSubcategory(
        categoryName,
        newDefaultCategory,
        user_id,
        isExistingCategory
      );

      let arrString = tagArr?.split(",") || [];
      arrString.unshift(newDefaultCategory);
      tagArr = [...new Set(arrString)].toString();
    };

    if (!isExisting) {
      if (!tagArr?.split(",").includes(newDefaultCategory)) {
        tagArr = tagArr.replace(defaultTag, newDefaultCategory);
      } else {
        await updateTags(true);
      }
    } else {
      if (!tagArr?.split(",").includes(newDefaultCategory)) {
        await updateTags(false);
      } else {
        let arrString = tagArr?.split(",") || [];
        arrString.unshift(newDefaultCategory);
        tagArr = [...new Set(arrString)].toString();
      }
    }

    // Update category and database
    updateCategory({
      categoryName,
      tag: newDefaultCategory,
      tagString: tagArr,
      user_id,
      action: "updateDefault",
      pageIndex: 1,
      index: 1,
    });

    const { error }: any = await supabase
      .from("searches")
      .update({ query: newDefaultCategory })
      .eq("query", defaultTag)
      .eq("type", categoryName.split(" ").join(""))
      .eq("user_id", user_id);
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <>
        {showCategories ? (
          <div className="controls">
            {/* <DragDropContext onDragEnd={onDragEnd}>*/}
            <Droppable droppableId="categories">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="category-grid"
                  // style={{
                  //   display: "grid",
                  //   gridTemplateColumns: "1fr 1fr",
                  //   gridGap: "25px",
                  //   paddingTop: "1.25rem",
                  //   paddingBottom: "2rem",
                  // }}
                >
                  {(isDragging ? localData : data) &&
                    (isDragging ? localData : data).length &&
                    (isDragging ? localData : data).map(
                      (element: any, index: any) => (
                        <Draggable
                          key={element.name}
                          draggableId={element.name}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="dashboard-grid-item"
                            >
                              {/* {isCategoryLoading ? (
                              <Skeleton height="120px" />
                            ) : ( */}
                              <>
                                <span>
                                  <p>
                                    <MdOutlineDragIndicator className="outline-indicator" />
                                    {element.name}{" "}
                                    <BsInfoCircle
                                      className="info-tooltip"
                                      // style={{height:"12px"}}
                                      data-tooltip-id="categorySearches"
                                      data-tooltip-content={`Controls whether or not ${element.name} category is shown to followers`}
                                    />{" "}
                                    <span className="custom-category-functions">
                                      <HiPencil
                                        onClick={() => {
                                          editCustomCategory(element);
                                        }}
                                      />
                                      <AiOutlineDelete
                                        className="error-msg"
                                        onClick={() =>
                                          deleteCustomCategory(element)
                                        }
                                      />
                                    </span>
                                  </p>
                                  <Switch
                                    style={{
                                      backgroundColor: element.value
                                        ? "green"
                                        : "rgb(241, 98, 98)",
                                      marginLeft: "4%",
                                    }}
                                    checked={element.value}
                                    onChange={(e: any) =>
                                      toggleValues(e, element.name, page)
                                    }
                                  />
                                </span>

                                <div className="controls-tab-row-queries">
                                  {element.queries &&
                                  element.queries.split(",").length > 0 ? (
                                    element.default ? (
                                      isEditDefaultCategory === false ? (
                                        <p className="default-subcategory">
                                          Default Subcategory: {element.default}
                                          <HiPencil
                                            className="ml-2"
                                            onClick={() => {
                                              setNewDefaultCategory(
                                                element.default
                                              );
                                              seteditDefaultCategoryIndex(
                                                index
                                              );
                                              setIsEditDefaultCategory(true);
                                            }}
                                          />
                                          {/* Edit
                                        </Button> */}
                                        </p>
                                      ) : editDefaultCategoryIndex === index ? (
                                        <p>
                                          <Input
                                            value={newDefaultCategory}
                                            onChange={(e: any) =>
                                              setNewDefaultCategory(
                                                e.target.value
                                              )
                                            }
                                            className="default-input-category"
                                          />
                                          <HiCheck
                                            className="ml-2"
                                            onClick={() => {
                                              // setNewDefaultCategory(
                                              //   element.default
                                              // );
                                              handleEditDefaultCategory(
                                                element.name,
                                                element.default,
                                                element.queries,
                                                element.isExisting
                                              );
                                              seteditDefaultCategoryIndex(-1);
                                              setIsEditDefaultCategory(false);
                                            }}
                                          />
                                        </p>
                                      ) : (
                                        <p className="font-1 mb-8">
                                          Default subcategory :-{" "}
                                          {element.default}
                                          <HiPencil
                                            className="ml-2"
                                            onClick={() => {
                                              setNewDefaultCategory(
                                                element.default
                                              );
                                              seteditDefaultCategoryIndex(
                                                index
                                              );
                                              setIsEditDefaultCategory(true);
                                            }}
                                          />
                                          {/* Edit
                                        </Button> */}
                                        </p>
                                      )
                                    ) : (
                                      <div className="custom-category-queryInput">
                                        <input
                                          style={{
                                            backgroundColor: "white",
                                            width: "80%",
                                          }}
                                          type="text"
                                          id="tagInput"
                                          enterKeyHint="enter"
                                          placeholder="Enter default subcategory"
                                          className="tagInput"
                                          onKeyUp={(e: any) => {
                                            setIsDragging(false);
                                            submitTag(
                                              e,
                                              element.name,
                                              element.queries,
                                              e.target.value,
                                              "default"
                                            );
                                          }}
                                        />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}

                                  <div className="custom-category-queryInput">
                                    <input
                                      style={{
                                        backgroundColor: "white",
                                        width: "80%",
                                      }}
                                      type="text"
                                      id="tagInput"
                                      enterKeyHint="enter"
                                      placeholder="Enter subcategories here"
                                      className="tagInput"
                                      onKeyUp={(e: any) => {
                                        setIsDragging(false);
                                        submitTag(
                                          e,
                                          element.name,
                                          element.queries,
                                          e.target.value,
                                          "regular"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="tag-box">
                                    {element.queries ? (
                                      <TagsBox
                                        tagsArray={
                                          element.queries
                                            ? element.queries.split(",")
                                            : [""]
                                        }
                                        removeTag={(tag: string) => {
                                          removeTag(
                                            element.queries
                                              ? element.queries.split(",")
                                              : [""],
                                            tag,
                                            element.name,
                                            element.default
                                          );
                                        }}
                                        defaultTag={element.default}
                                        page="category"
                                        setIsDragging={setIsDragging}
                                      />
                                    ) : (
                                      <p className="noTags font-1">
                                        No subcategories added yet.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                              {/* // )} */}
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {/*   </DragDropContext> */}
          </div>
        ) : (
          <></>
        )}

        <Modal
          open={isDeleteModalOpen}
          onOk={handleDeleteOk}
          onCancel={handleCancel}
          okText="Delete"
        >
          <div className="delete-question-wrapper">
            <p>
              Are you sure you want to delete the "{currentCategory?.name}"
              custom category?
            </p>
          </div>
        </Modal>
        <Modal
          title={`Edit Category Name`}
          open={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleCancel}
          okText="Save"
          okButtonProps={{ disabled: !newCategoryName }} // Disable "Create" button if no category name
        >
          <div className="edit-input-wrapper">
            <Input
              type="text"
              value={newCategoryName} // Ensure that you are using the state value here
              // placeholder={currentCategory.name}
              // value={currentCategory.name?currentCategory.name:newCategoryName}
              onChange={(e): any => {
                setNewCategoryName(e.target.value);
              }}
              // value={newCategoryName}
            />
          </div>
        </Modal>
        <Tooltip id="categorySearches" place={"top"} offset={4} />
        <Modal
          title="Create Custom Category"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCreateCancel}
          okText="Create"
          okButtonProps={{ disabled: !categoryName }} // Disable "Create" button if no category name
        >
          <Input
            required
            value={categoryName}
            placeholder="Enter category name here"
            onChange={(e: any) => {
              setCategoryName(e.target.value);
              setErrorMsg("")
            }}
          />

          {errorMsg ? <p className="error-msg">{errorMsg}</p> : ""}
        </Modal>
      </>
      {/* {provided.placeholder}
                </div>
              )}
            </Droppable> */}
    </DragDropContext>
  );
};

export default CustomCategoriesSection;

{
  /* {data &&
                    data.length &&
                    data.map((element: any, index: any) => ( */
}
