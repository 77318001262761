import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadTypeContentData(
  username: any,
  user_id: any,
  platform: any,
  type: any
) {
  async function getPaginatedFeed() {
    let data,
      error = null;

    if (type === "TIKTOK") {
      const { data: rpc, error: rpcError } = await supabase.rpc(
        "get_tiktoktypecontenttt",
        {
          userid: user_id,
          user_name: "content_new",
          platform_name: platform,
          feed_type: type,
        }
      );
      data = rpc;
      error = rpcError;
    } else if (type === "STORY") {
      const today = dayjs().format("YYYY-MM-DD");
      const yesterday = dayjs().subtract(7, "day").format("YYYY-MM-DD");
      const { data: rpc, error: rpcError } = await supabase.rpc(
        "get_storytypecontenttt",
        {
          userid: user_id,
          user_name: "content_new",
          platform_name: platform,
          feed_type: type,
          start_date: yesterday,
          end_date: today,
        }
      );
      data = rpc;
    
      error = rpcError;
    } else {
      const { data: rpc, error: rpcError } = await supabase.rpc(
        "get_typecontentt",
        {
          userid: user_id,
          user_name: "content_new",
          platform_name: platform,
          feed_type: type,
        }
      );
      data = rpc;
      error = rpcError;
    }
    // Get yesterday's date
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
    // return resultArray
  }

  return useQuery(["typeContentPosts", user_id, type], getPaginatedFeed, {});
}
