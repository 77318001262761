import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import {
  updateContentOrder,
} from "../../../Utils/SupabaseServices/ControlsDatabase";

const ContentOrderRadio = (props: any) => {
  const { username, checkedType, user_id, page } = props;
  const typeList = ["Default", "Time Based", "Shoppable First"];

  const [selectedType, setSelectedType] = useState(checkedType || "");

  const handleChange = (e: any) => {
    const type = e.target.value;
    setSelectedType(type);
    updateContentOrder(user_id, type, page);
  };
  return (
    <div className="content-type-radios">
      <Radio.Group
        onChange={handleChange}
        value={selectedType}
        className="radio-group"
      >
        {typeList.map((type, index) => (
          <Radio.Button
            key={type}
            value={type}
            className="radioButton"
            style={{
              marginRight: "10px",
              backgroundColor: selectedType === type ? "black" : "white",
              color: selectedType === type ? "white" : "black",
            }}
          >
            {type}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ContentOrderRadio;
