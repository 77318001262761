import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../Utils/SupabaseServices/UserDatabase";
import { useAuth } from "../../hooks/useAuth";

import "./Header.css";
import { BsList } from "react-icons/bs";
import { Avatar, Dropdown, Space } from "antd";
import { isMobile } from "react-device-detect";
import { UserOutlined } from "@ant-design/icons";
import { IoShareOutline } from "react-icons/io5";
import React from "react";
import ShareProfile from "./ShareProfile";
import { supabase } from "../../config/supabaseClient";

const ImageSidebar = ({
  username,
  isCreator,
  isDashboard,
  isFollower,
  pictureUrl,
}: {
  username: string;
  isCreator?: boolean;
  isDashboard?: boolean;
  isFollower?: boolean;
  pictureUrl?: string;
}) => {
  const { signOut: signOutSupabase } = useAuth();
  const navigate = useNavigate();

  // const [displayPicture, setDisplayPicture] = useState(pictureUrl);
  // useEffect(() => {
  //   if (pictureUrl === "/assets/userhead.png") {
  //     fetchDetails();
  //   }
  // }, []);

  // async function fetchDetails() {
  //   const user = await fetchUser(username);
  //   if (user) {
  //     setDisplayPicture(user.picture);
  //   }
  // }
  const goToFollower = () => {
    navigate("/follower/" + username, { state: { from: "dashboard" } });
  };
  const goToDashboard = () => {
    navigate("/dashboard/" + username);
  };

  const goToCreator = () => {
    navigate("/creator/" + username, { state: { from: "dashboard" } });
  };
  async function signOut() {
    const data = localStorage.getItem("email_verify");
    if (data === "false") {
      await supabase
        .from("user")
        .update({ email_verify: false })
        .eq("username", username);
    }
    localStorage.clear();
    sessionStorage.clear();
    signOutSupabase();
    navigate("/");
  }

  const creatorMenu :any= [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToDashboard}>
          Dashboard
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },
    {
      key: "2",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToFollower}>
          Live Page Preview
        </p>
      ),
    },
    {
      key: "2.1",
      type: "divider",
    },
    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={signOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const dashboardMenu = [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToFollower}>
          Live Page Preview
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },

    // {
    //   key: "2",
    //   label: (
    //     <p className="header-dashboard-menu-item" onClick={goToCreator}>
    //       Staging View
    //     </p>
    //   ),
    // },
    // {
    //   key: "2.1",
    //   type: "divider",
    // },
    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={signOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const followeMenu = [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToDashboard}>
          Dashboard
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },
    // {
    //   key: "2",
    //   label: (
    //     <p className="header-dashboard-menu-item" onClick={goToCreator}>
    //       Staging View
    //     </p>
    //   ),
    // },
    // {
    //   key: "2.1",
    //   type: "divider",
    // },
    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={signOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const items = isCreator
    ? creatorMenu
    : isDashboard
    ? dashboardMenu
    : isFollower
    ? followeMenu
    : [];

  return (
    <>
      {
        !isMobile ? (
          <>
            <Dropdown menu={{ items }} placement="bottomRight">
              <div className="header-nav-image">
                <BsList />
                {pictureUrl ? (
                  <img
                    src={pictureUrl}
                    className="header-dashboard-img"
                    alt="profile"
                  />
                ) : (
                  <Avatar
                    size={35}
                    // className="header-dashboard-img"
                    icon={<UserOutlined />}
                  />
                )}
              </div>
            </Dropdown>
            {/* </div> */}
          </>
        ) : (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className="header-nav-image">
              <BsList />
              {pictureUrl ? (
                <img
                  src={pictureUrl}
                  className="header-dashboard-img"
                  alt="profile"
                />
              ) : (
                <Avatar
                  size={10}
                  // className="header-dashboard-img"
                  icon={<UserOutlined />}
                />
              )}
            </div>
          </Dropdown>
        )
        //  : (
        //   <Dropdown menu={{ items }} trigger={["click"]}>
        //     <div className="header-nav-image">
        //       <BsList />
        //       <img
        //         src={pictureUrl}
        //         className="header-dashboard-img"
        //         alt="profile"
        //       />
        //     </div>
        //   </Dropdown>
        // )
      }
    </>
  );
};

export default ImageSidebar;
