import { Link } from "react-router-dom";
import "./ErrorPages.css";
import HeaderDark from "../components/Header/headerDark";

export default function Error400() {
	async function signOut() {
		localStorage.clear();
	}
	return (
		<div className='error-page'>
			<div className='error-page-header'>
				<HeaderDark/>
			</div>

			<div className='error-page-error-msg'>
				You are not authorized to access this page 😥<br/><br/>
				{/* <button className='error-page-back-btn' onClick={signOut}>
					<Link to='/'>back</Link>
				</button> */}
			</div>
		</div>
	);
}
