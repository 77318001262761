import { Button, Col, Pagination, Row, Tabs } from "antd";
import React, { useState } from "react";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadProductBankPages } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import ProductBankSearchTable from "./ProductBankSearchTable";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { useLoadProductBankPlatform } from "./useLoadProductBankPlatform";

const POSTS_PER_PAGE = 10;

const ProductBank = (props: any) => {
  const { user } = useAuth();
  const { subscription_type } = props;

  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("Product Bank");
  const [isLimitError, setLimitError] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProductFilter, setSelectedProductFilter] = useState("All"); // Default page size

  const {
    isLoading: pagesLoading,
    data: pages,
    refetch: refetchCount,
  } = useLoadProductBankPages(
    user_id || "",

    POSTS_PER_PAGE,
    query,
    username || "",
    selectedProductFilter
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query,
      selectedProductFilter
    );

  const handlePageDataChange = (page: any) => {
    setCurrentPage(page);
  };

  const handleProductFilter = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productFilter: string
  ) => {
    e.preventDefault();
    setSelectedProductFilter(productFilter);
    setCurrentPage(1);
  };
  const { data: productFilterItems } = useLoadProductBankPlatform(user_id);

  return (
    <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide mb-20px">
          {StaticVariables().PRODUCT_BANK}
        </h1>
        <div className="detail-margin">
          <Row gutter={16} align="middle">
            {/* <Col>
              <Button className="product-bank-add-btn">Add Product</Button>
            </Col> */}
            <Col flex="auto">
              <SearchInput
                placeholder={`Search all product bank content`}
                id="search-feed"
                handleKeyUp={(e: any) => {
                  e.preventDefault();
                  if (e.keyCode === 13) {
                    const elem: any = document.getElementById("search-feed");
                    const searchVal = e.target.value;
                    if (searchVal.length === 0) {
                      elem.blur();
                      setCurrentPage(1);
                      setSelectedProductFilter("All");

                      setQuery(null);
                      return;
                    }
                    elem.blur();
                    setQuery(searchVal);
                    setCurrentPage(1);
                    setSelectedProductFilter("All");
                  }
                }}
                isActiveFilter={Boolean(query)}
                onSearchClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  const searchVal = elem.value;
                  if (searchVal.length === 0) {
                    return;
                  }
                  elem.blur();
                  setQuery(searchVal);
                  setCurrentPage(1);
                  setSelectedProductFilter("All");
                }}
                onClearClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  elem.value = "";
                  setQuery(null);
                  setCurrentPage(1);
                  setSelectedProductFilter("All");
                }}
              />
            </Col>
            </Row>{" "}
            <div className="segmented-container">
              {productFilterItems &&
                productFilterItems.map(
                  (elem: { key: string; label: string }) => (
                    <React.Fragment key={elem.key}>
                      <Button
                        data-testid={elem.key}
                        onClick={(e) => handleProductFilter(e, elem.key)}
                        style={{
                          background:
                            selectedProductFilter === elem.key
                              ? "#3b3b3b"
                              : "white",
                          color:
                            selectedProductFilter === elem.key
                              ? "white"
                              : "black",
                        }}
                      >
                        {elem.label}
                      </Button>
                    </React.Fragment>
                  )
                )}
            </div>
        
        </div>

        {pagesLoading || isLoading ? (
          // <PuffLoader
          <ContentSkeleton />
        ) : (
          <>
            <ProductBankSearchTable
              username={username}
              user_id={user_id}
              activeKey={activeKey}
              data={data?.pages?.[0]}
              setLimitError={setLimitError}
              filter={query}
              refetchData={refetch}
              currentPage={currentPage}
              // setIsContentLoading={setIsContentLoading}
              // subscription_type={subscription_type}
            />
            {
              // Check if feedCount exists and is greater than POSTS_PER_PAGE
              pages?.feedCount > POSTS_PER_PAGE && pages?.feedCount !== 0 ? (
                <Pagination
                  showQuickJumper
                  current={currentPage}
                  pageSize={POSTS_PER_PAGE}
                  total={pages?.feedCount || 0} // Ensure feedCount is a valid number
                  onChange={handlePageDataChange}
                  showSizeChanger={false}
                  className="pagination-data-change"
                />
              ) : (
                ""
                // Optional: Log if feedCount is invalid or zero
            )
            }
          </>
        )}
      </div>
    </div>
  );
};

export default ProductBank;
