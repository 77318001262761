import React from "react";
import CustomPagination from "../CustomPagination";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import ImagePostTag from "../../GeneralPostTag/ImagePostTag";
import VideoPostTag from "../../GeneralPostTag/VideoPostTag";
import { Col, Row } from "react-bootstrap";
import IndividualPostCarouselTag from "../../GeneralPostTag/IndividualPostCarouselTag";
import IndividualImagePostTag from "../../GeneralPostTag/IndividualImagePostTag";
import IndividualVideoPostTag from "../../GeneralPostTag/IndividualVideoPostTag";

function RenderIndividualUI(props: any) {
  const {
    data,
    currentIndex,
    title,
    handleNavigate,
    component,
    handleNextPage,
    handlePrevPage,
    containerRef,
    handleShop,
    brand_color,
    type,
  } = props;
  const param = useParams();
  return (
    <div>
      {data && data.length ? (
        <div>
          <div className="mainDiv">
            <p
              className="component-name"
              style={param?.id ? { cursor: "auto" } : {}}
              onClick={(e) => handleNavigate(component)}
            >
              {title}
            </p>
            {!param?.id && (
              <CustomPagination
                totalItems={data?.length}
                currentIndex={currentIndex}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
              />
            )}
          </div>
          <div
            className={
              !param?.id
                ? "outer-container-reels individual-outer-container"
                : "individual-outer-container"
            }
            ref={containerRef}
          >
            <Row
              style={{ rowGap: "12px" }}
              className={!param?.id ? "" : isMobile ? " " : " "}
            >
              {data &&
                data.map((link: any, index: number) => {
                  return (
                    <Col
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={6}
                      key={link.id}
                      className={
                        link.platform != "youtube"
                          ? ""
                          : " youtube-link-position"
                      }
                      style={{ justifyContent: "center" }}
                    >
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                        link.media_url
                          .split(",")
                          .every((url: any) => url.trim() !== "") ? (
                          <>
                            <IndividualPostCarouselTag
                              post={link}
                              handleRedirect={handleShop}
                              brand_color={brand_color}
                              isLinks={true}
                              isDashboard={false}
                              isHasMediaUrl={
                                link.type === "FEED" ? true : false
                              }
                              isYoutube={
                                link.platform == "youtube" ? true : false
                              }
                            />
                          </>
                        ) : (
                          <>
                            {link.product_image && link.product_image !== "" ? (
                              <>
                                <IndividualImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={false}
                                  isHasMediaUrl={false}
                                  type={type}
                                  isYoutube={
                                    link.platform == "youtube" ? true : false
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {link.media_url.includes("video") ? (
                                  <>
                                    <IndividualVideoPostTag
                                      post={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={false}
                                      thumbnail={link.thumbnail}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      isYoutube={
                                        link.platform == "youtube"
                                          ? true
                                          : false
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <IndividualImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={false}
                                      type={type}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      isYoutube={
                                        link.platform == "youtube"
                                          ? true
                                          : false
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <IndividualImagePostTag
                            data={link}
                            title={title}

                            handleRedirect={handleShop}
                            brand_color={brand_color}
                            isLinks={true}
                            isDashboard={false}
                            type={type}
                            isHasMediaUrl={link.type === "FEED" ? true : false}
                            isYoutube={
                              link.platform == "youtube" ? true : false
                            }
                          />
                        </>
                      )}{" "}
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}{" "}
    </div>
  );
}

export default RenderIndividualUI;
