import Card from "react-bootstrap/Card";
import {
  BsFillPlayFill,
  BsHourglassSplit,
  BsInstagram,
  BsLink45Deg,
} from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { Post } from "../../FeedPost";
import { updateRefClick } from "../../../../Utils/SupabaseServices/ContentDatabase";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import Caption from "../Caption";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import Comments from "../Comments";
import PostCarousel from "../PostCarousel";
import { RiShoppingBagLine } from "react-icons/ri";
import { isMobile } from "react-device-detect";
import { FiAtSign } from "react-icons/fi";
import { useLoadUser } from "../../../../Utils/customHooks/useLoadUser";
import { Button } from "antd";
import "../CommentsPost/CommentsPost.css";
import "../PostCarousel.css"

export default function InstagramPost({
  post,
  showCaption,
  showComments,
  isGlobalView,
  username,
  showAsAd,
  shoppableContent,
}: {
  post: Post;
  showCaption: any;
  showComments: any;
  isGlobalView: any;
  username: string;
  showAsAd: any;
  shoppableContent: any;
}) {
  const location = useLocation();
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);

  const user_id = userData ? userData.user_id : "";
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);
  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);

  const placeholderImage = "/assets/dummy_video.png";
  function handleRedirect(postId: string, media_url: string) {
    track("Redirect to original post", TRACKING_TYPES.SEARCH, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
    updateRefClick(postId, user_id);
  }

  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
  }
  function handleTagged(postId: string, media_url: string) {
    track("Redirect to tagged link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
  }
  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>{!hover && <BsFillPlayFill className="creator-feed-card-play-btn" />}</>
    );
  };

  const VideoTag = (props: any) => {
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const {
      media_url,
      showAsAd,
      post_date,
      permalink,
      post_id,
      username,
      thumbnail,
      shop_link,
      tagged_link,
    } = props;
    return (
      <>
        {!Boolean(media_url) ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <HoverVideoPlayer
              videoRef={videoRef}
              videoSrc={media_url}
              className="creator-feed-card-video hover-play"
              muted
              preload={"metadata" || "auto"}
              contextMenu="return false;"
              hoverOverlay={
                <div
                  className="video-hover"
                ></div>
              }
              pausedOverlay={
                <div className="pause-overlay">
                  <img
                    src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                    alt=""
                    className="creator-feed-card-video"
                    loading="eager"
                  />

                  <OverlayStatus
                    hover={hover}
                    showClickablePlayBtn={showClickablePlayBtn}
                  />
                </div>
              }
              loadingOverlay={
                <img
                  src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                  alt=""
                  className="creator-feed-card-video"
                  loading="eager"
                />
              }
              unloadVideoOnPaused
              onHoverStart={() => {
                setHover(true);
              }}
              onHoverEnd={() => {
                setHover(false);
                setCustomPause(false);
              }}
              disableDefaultEventHandling={customPause}
            />

            <RefTag
              media_url={media_url}
              showAsAd={showAsAd}
              post_date={post_date}
              permalink={permalink}
              shop_link={shop_link}
              post_id={post_id}
              username={username}
              tagged_link={tagged_link}
              isVideo={true}
            />
          </div>
        )}
      </>
    );
  };

  const ImagePostTag = React.memo((props: any) => {
    const {
      media_url,
      showAsAd,
      post_date,
      permalink,
      post_id,
      username,
      shop_link,
      tagged_link,
    } = props;
    const style: any = {
      width: "100%",
      height: "500px",
      overflow: "hidden",
      borderRadius: "5px",
      position: "relative",
    };
    return (
      <>
        {media_url === "" ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div className="creator-feed-image-wrapper">
            <Card.Img
              variant="top"
              src={media_url ? media_url : placeholderImage}
              className="creator-feed-card-video-image"
              loading="eager"
              style={{
                backgroundColor: "#f1f1f1",
                ...(media_url.includes("highlight") ||
                  media_url.includes("stories")
                  ? style
                  : {}),
              }}
            />
            <RefTag
              media_url={media_url}
              showAsAd={showAsAd}
              post_date={post_date}
              permalink={permalink}
              shop_link={shop_link}
              post_id={post_id}
              username={username}
              tagged_link={tagged_link}
            />
          </div>
        )}
      </>
    );
  });
  const RefTag = ({
    media_url,
    showAsAd,
    post_date,
    permalink,
    shop_link,
    post_id,
    username,
    tagged_link,
    isVideo,
  }: any) => {
    return (
      <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            {new Date(post_date).getFullYear() > 2000 ? (
              <p className="creator-feed-content-date">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  day: "numeric",
                  month: "long",
                }).format(new Date(post_date))}
              </p>
            ) : (
              ""
            )}
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}

              {shop_link ? (
                <a
                  href={shop_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  {shoppableContent === "icon" ? (
                    <RiShoppingBagLine
                      className="perma-links"
                      onMouseEnter={(e: any) => {
                        e.target.style.color = "#888";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#000";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    />
                  ) : shoppableContent === "text" ? (
                    <Button
                      className="shoppable-btn"
                      onMouseOver={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    >
                      Shop
                    </Button>
                  ) : (
                    ""
                  )}
                </a>
              ) : (
                ""
              )}
              {tagged_link ? (
                <a
                  href={tagged_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <FiAtSign
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleTagged(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}

              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsInstagram
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id, media_url)}
                  />
                </a>
              ) : (
                <BsInstagram
                  className="ml-6"
                />
              )}

              {isGlobalView && username && (
                <p className="creator-feed-username">@{username}</p>
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-content-date">Sponsored</p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}

              {shop_link ? (
                <a href={shop_link} target="_blank" rel="noreferrer">
                  {shoppableContent === "icon" ? (
                    <RiShoppingBagLine
                      className="perma-links"
                      onMouseEnter={(e: any) => {
                        e.target.style.color = "#888";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#000";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    />
                  ) : shoppableContent === "text" ? (
                    <Button
                      className="shoppable-btn"
                      onMouseOver={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    >
                      Shop
                    </Button>
                  ) : (
                    ""
                  )}
                </a>
              ) : (
                ""
              )}
              {tagged_link ? (
                <a
                  href={tagged_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <FiAtSign
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleTagged(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}

              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsInstagram
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id, media_url)}
                  />
                </a>
              ) : (
                <BsInstagram
                  className="ml-6"
                />
              )}
              {isGlobalView && username && (
                <p className="creator-feed-username">@{username}</p>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="feed-post">
      {post.media_url ? (
        post.media_url.split(",").length > 1 &&
          post.media_url.split(",").every((url) => url.trim() !== "") ? (
          <PostCarousel
            post={post}
            username={username}
            user_id={user_id}
            isGlobalView={isGlobalView}
            showAsAd={showAsAd}
            shop_link={post.shop_link}
            shoppableContent={shoppableContent}
          />
        ) : (
          <>
            {post.media_url.includes("video") ? (
              <VideoTag
                media_url={post.media_url}
                showAsAd={showAsAd}
                post_date={post.post_date}
                permalink={post.permalink}
                post_id={post.post_id}
                username={username}
                thumbnail={
                  post.thumbnail && post?.thumbnail?.split(",")[0]
                    ? post.thumbnail.split(",")[0]
                    : "/assets/dummy_video.png"
                }
                shop_link={post.shop_link}
                tagged_link={post.tagged_link}
              />
            ) : (
              <ImagePostTag
                media_url={post.media_url}
                showAsAd={showAsAd}
                post_date={post.post_date}
                permalink={post.permalink}
                post_id={post.post_id}
                username={username}
                shop_link={post.shop_link}
                tagged_link={post.tagged_link}
              />
            )}
          </>
        )
      ) : (
        <div style={{ alignSelf: "center" }}>
          <ImagePostTag
            media_url={
              post.thumbnail ? post.thumbnail : "/assets/dummy_video.png"
            }
            showAsAd={showAsAd}
            post_date={post.post_date}
            permalink={post.permalink}
            post_id={post.post_id}
            username={username}
            shop_link={post.shop_link}
            tagged_link={post.tagged_link}
          />
        </div>
      )}

      {showCaption && (
        <>{post.caption ? <Caption caption={post.caption} /> : ""}</>
      )}

      {showComments && (
        <>{post.comments ? <Comments comments={post.comments} /> : ""}</>
      )}
    </div>
  );
}
