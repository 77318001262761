import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./TagsBox.css";
import React from "react";

type Props = {
  tagsArray: string[];
  removeTag: (tag: string) => void;
  page?: any;
  defaultTag?: any;

  setIsDragging?: any;
};

export default function TagsBox({
  tagsArray,
  removeTag,
  defaultTag,
  setIsDragging,
  page,
}: Props) {
  const [showAllTags, setShowAllTags] = useState(false);
const tagsLimit=page==="account"?7:5
  return (
    <>
      <div
        className="tags"
      >
        {tagsArray &&
          tagsArray
            .slice(0, showAllTags ? tagsArray.length : tagsLimit)
            .map((tag: any, keyIndex: any) => {
              return (
				<React.Fragment key={keyIndex}>
                {tag.replace(/\|/g, "") && <div
                  id={`tag${keyIndex}`}
                  className="tags-container"
                  key={`tag${keyIndex}`}
                >
                  <p>
                    {tag.replace(/\|/g, "")}
                  </p>
                  {defaultTag !== tag.replace(/\|/g, "") && (
                    <CloseIcon
                    className="closeIcon"
                      onClick={() => {
                        if (page === "category") {
                          setIsDragging(false);
                          removeTag(tag);
                        } else {
                          removeTag(tag);
                        }
                      }}
                    />
                  )}
                </div>}
				</React.Fragment>
              );
            })}
      </div>
      {!showAllTags && tagsArray.length > tagsLimit && (
        <p
          className="tags-box-see-all"
          onClick={() => {
            setShowAllTags(true);
          }}
        >
          {page === "category" ? (
            <>Show all subcategories</>
          ) : (
            <>Show all tags</>
          )}
        </p>
      )}
      {showAllTags && tagsArray.length > 5 && (
        <p
          className="tags-box-see-less"
          onClick={() => {
            setShowAllTags(false);
          }}
        >
          Show less
        </p>
      )}
    </>
  );
}
