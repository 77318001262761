import { useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";
import {
  extractKeywords,
  // fetchSearchResults,
  getAdLength,
  getEmbedding,
  getExtraNecIds,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { useRef, useState } from "react";
// import { useSearchData } from "./useLoadSearchbarData";

/**
 * Load content
 */

export function useLoadAdData(
  username: any,
  user_id: any,
  queryTerm: any,
  category: any,
  adsIndex: any,
  showAds: any
) {
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  // const isNecCalledRef = useRef(false);
  // const isNecCalledRef = useRef({
  //   current: false,
  //   currentQueryTerm: "",
  // });

  async function getPaginatedFeed() {
    let data,
      error = null;
if(showAds){
    if (user_id && user_id.length === 1) {

      // if (category && category === "searchbar") {
        const { data: ads, error: adsError }: any = await supabase
          .from("ads_content")
          .select("*")
          .eq("user_id", user_id[0])
          .order("ads_order");
        data = ads;
        error = adsError;
      // }
    }
  }


    const resultArray =data?[...data]:[];

    if (resultArray && resultArray.length >= 0 && Array.isArray(resultArray)) {
      return resultArray;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
    // }
  }

  category = category;
  return useQuery(["adPosts", queryTerm, category, user_id], getPaginatedFeed, {
    // enabled: Boolean(queryTerm),
  });
}
