import { List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../StayUpToDateControls.css";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const LinksComponents = (props: any) => {
  const { username, user_id, isLinksModalOpen, StayUpToDateComponents } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "My Links",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "My Links"
    );
    if (res === "true") {
      props.refetch();
      props.setIsLinksModalOpen(false);
      props.setIsModalOpen(false);
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsLinksModalOpen(false);
    props.setIsModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsLinksModalOpen(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isLinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="mylink-add"
      >
        <div>
          <h5 className="title mb-15">
            My Links
          </h5>
          <p>These are all of the links added under the <i>My Links</i> section of the dashboard will show up in this section. Basically, all of your tranditional link-in-bio links! </p>
        </div>
      </Modal>
    </>
  );
};

export default LinksComponents;
