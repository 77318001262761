import { useEffect } from "react";
import Header from "../../components/Header/header";
import { updateGlobalCount } from "../../Utils/SupabaseServices/AnalyticsDatabse";
import { TabTitle } from "../../Utils/GeneralFunctions";
import GlobalViewSkeleton from "../../components/skeleton/Views/globalView";
import { useLocation, useNavigate } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { useLoadIncludedUsers } from "../../Utils/customHooks/useLoadIncludedUsers";
import FeedControls from "../../components/FeedPage/FeedControls/FeedControls";

const GlobalView = () => {
  TabTitle("All Creators • Bubble");
  const location = useLocation();

  const { isLoading: usersLoading, data: users }: any = useLoadIncludedUsers();

  useEffect(() => {
    updateGlobalCount();
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
    });
  }, []);

  return (
    <>
      {usersLoading ? (
        <GlobalViewSkeleton />
      ) : (
        <>
          <div id="app-wrapper">
            <Header />
          </div>
          {users && users?.users && users?.users.length ? (
            <>
              <FeedControls
                username={users?.users}
                page="global"
                name="All Creator"
                userId={users?.userIds}
                avgWords={users[0].avg_words}
                liveView={false}
              />
            </>
          ) : (
            <div className="feed-no-user-msg">
              <p>No user selected for Global view</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GlobalView;
