import { useQuery } from "react-query";
import { getPopularSearches } from "../SupabaseServices/SearchesDatabase";

export function useLoadPopularSearches(user_id: any,username:any, max: any) {
	async function getFeedPopularSearches() {
		const popularSearches = await getPopularSearches(user_id,username, max);

		if (!popularSearches) {
			return false;
		  } else {
			let popularSearchesArr:any=[]
			popularSearches.map((elem:any)=>{
			  popularSearchesArr.push(elem.query)
			})
			return popularSearchesArr;
		  }
	}
	return useQuery(
		["getFeedPopularSearches", user_id, max],
		getFeedPopularSearches, 
		{
		  refetchOnWindowFocus: true
		}
	);
}
