const Header = ({ extraComponent = null }: { extraComponent?: any }) => {
	return (
		<div className='header-wrapper'>
			<p className='header-logo-tittle'>		
				<img
					className='homepage-icon'
					src='/assets/icon-black.png'
					alt='thesearchbubble logo'
				/>
			</p>
			{extraComponent}
		</div>
	);
};

export default Header;
