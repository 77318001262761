import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { googleCallback } from "../../Utils/backendServices/youtube";
import SelectGoogleAccount from "../SelectGoogleAccount/SelectGoogleAccount";
import { Spin } from "antd";
import "../StayUpToDateComponent/StayUpToDate.css";

export default function GoogleCallback() {
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const user_id = user?.id || "";
	const urlSearchParams = new URLSearchParams(location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	const [youtubeChannelData, setYoutubeChannelData] = useState({
		name: "",
		videos: "",
		views: "",
		id: "",
		img: "",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const { code, scope, error } = params;
		if (error) {
			navigate("/signup");
		} else if (code && scope) {
			googleCallback(code, scope, user_id).then((data) => {
				if (data) {
					setYoutubeChannelData(data);
					setLoading(false);
				} else {
					navigate("/signup");
				}
			});
		}
	}, [params]);

	return (
		<div className='auth-container'>
			{loading ? (
				<div
					className="google-auth"
				>
					<Spin size='large' className='white-spinner' />
					<p className="loading">Loading...</p>
				</div>
			) : (
				<SelectGoogleAccount
					name={youtubeChannelData.name}
					videos={youtubeChannelData.videos}
					views={youtubeChannelData.views}
					imgUrl={youtubeChannelData.img}
					changeAccount={() => {
						navigate("/signup");
					}}
				/>
			)}
		</div>
	);
}
