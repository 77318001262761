// // import { Link } from "react-router-dom";
// // import Header from "../components/Header/header";
// // import Footer from "../components/Footer/footer";
// // import "../components/Footer/Footer.css";;

// // function stripeEmbedTable() {
// //     return {
// //       __html: `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
// //       <stripe-pricing-table pricing-table-id="prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
// //       publishable-key="pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN">
// //       </stripe-pricing-table>`,
// //       // `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
// //       // <stripe-pricing-table pricing-table-id="prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
// //       // publishable-key="pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN">
// //       // </stripe-pricing-table>`,
// //     };
// //   }

// // export default function Subscribe() {
// // 	async function signOut() {
// // 		localStorage.clear();
// // 	}
// // 	return (
// //         <div>
// //             <div className='error-page-header'>
// //                 <Header />
// //             </div>
// //             <div className="stripe-table" dangerouslySetInnerHTML={stripeEmbedTable()} />
// //             <div>
// //                 <Footer />
// //             </div>
// //         </div>
// // 	);
// // }

// import React, { useEffect } from "react";
// import Header from "../components/Header/header";
// import Footer from "../components/Footer/footer";
// import "../components/Footer/Footer.css";

// function Subscribe() {

//   return (
//     <div>
//       <div className="error-page-header">
//         <Header />
//       </div>
//       <div className="stripe-table">
//         {/* Paste your Stripe Pricing Table embed code here */}
//         <div dangerouslySetInnerHTML={{ __html: `
//           <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
//           <stripe-pricing-table
//             pricing-table-id="prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
//             publishable-key="pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN"
//           ></stripe-pricing-table>
//         ` }} />
//       </div>
//       <div>
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default Subscribe;
import React, { useEffect } from "react";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import AuthTerm from "../components/AuthTerm/authTerm";
import "../components/Footer/Footer.css";
import { useLocation } from "react-router-dom";
import track, { TRACKING_TYPES } from "./SupabaseServices/Track";

function Subscribe() {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  // Retrieve UTM parameters
  const utmSource = queryParams.get("utm_source");

  if (utmSource) {
  
    track(location.pathname, TRACKING_TYPES.PAGE, {
		  pathname: location.pathname,
      details: { source: utmSource },
      // user_id: "",


		});
  }else{
    track(location.pathname, TRACKING_TYPES.PAGE, {
		  pathname: location.pathname,
      // user_id: "",


		});
  }
  // const utmMedium = queryParams.get('utm_medium');
  // const utmCampaign = queryParams.get('utm_campaign');

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    script.onload = () => {
      // Stripe script has loaded successfully
      // Now, create the pricing table component
      const pricingTable = document.createElement("stripe-pricing-table");
      pricingTable.setAttribute(
        "pricing-table-id",
        "prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
      );
      pricingTable.setAttribute(
        "publishable-key",
        "pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN"
      );

      // Find the container element
      const container = document.querySelector(".stripe-table");

      // Check if the container exists before appending the pricing table
      container?.appendChild(pricingTable);
    };

    script.onerror = () => {};

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    // <div>
    <div className="auth-container">
      {/* <Header /> */}
      <div className="navbar_container">
        <a href="/" className="navbar_logo-link w-nav-brand" aria-label="home">
          <div className="logo-text">
            <img
              className="homepage-icon"
              src="/assets/output-onlinepngtools-edited.png"
              alt="thesearchbubble logo"
            />
          </div>
        </a>
        <nav className="navbar_menu w-nav-menu">
          <div className="navbar_menu-link-wrapper">
            {/* <a href="/subscribe" className="navbar_link w-nav-link pricing">pricing</a> */}
            {/* <a href="/about" className="navbar_link w-nav-link">about</a> */}
            {/* <a href="/how-it-works" className="navbar_link w-nav-link">how it works</a> */}
            {/* <a href="/login" className="navbar_link w-nav-link login">log in</a> */}
            <a
              href="https://calendly.com/searchbubble/demo"
              target="_blank"
              className="navbar_link w-nav-link sign-up"
            >
              book a demo
            </a>
          </div>
        </nav>
      </div>
      <div className="stripe-table"></div>
      <div>
        {/* <Footer /> */}
        <AuthTerm />
      </div>
    </div>
  );
}

export default Subscribe;
