import axios from "axios";
import { supabase } from "../../config/supabaseClient";

type Res = {
	error: boolean;
	message: string;
};

const handleError = (error: any) => {
	if (
		error &&
		error?.response &&
		error.response?.data &&
		error.response.data?.message
	) {
		return error.response.data.message;
	} else {
		return "Server error: please try again later or contact our support team.";
	}
};

export async function googleCallback(
	code: string,
	scope: string,
	user_id: string
): Promise<null | any> {
	try {
		const url = `${
			process.env.ENV === "production"
				? process.env.REACT_APP_API_URL_PRODUCTION
				: process.env.REACT_APP_API_URL_DEV
		}api/auth/googleCallback`;

		const resServer = await axios.post(
			url,
			{ code, scope, user_id },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		const { success, data, message } = resServer.data;
		if (!success) {
			return null;
		}
		const { channelInfo } = data;

		return channelInfo;
	} catch (error: any) {
		return null;
	}
}

export async function getGoogleAuthUrl(): Promise<null | string> {
	try {
		const url = `${
			process.env.ENV === "production"
				? process.env.REACT_APP_API_URL_PRODUCTION
				: process.env.REACT_APP_API_URL_DEV
		}api/auth/google`;

		const resServer = await axios.get(url);

		const { url: googleUrl } = resServer.data;

		if (!googleUrl) {
			return null;
		}

		return googleUrl;
	} catch (error: any) {

		return null;
	}
}

export async function storeYoutubeChannel(
	user_id: string
): Promise<{ error: true; message: string } | { error: false; count: number }> {
	try {
		const url = `${
			process.env.ENV === "production"
				? process.env.REACT_APP_API_URL_PRODUCTION
				: process.env.REACT_APP_API_URL_DEV
		}api/youtube/storeChannelVideos`;

		const resServer = await axios.post(
			url,
			{ userId: user_id },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		const { success, message, count } = resServer.data;
		if (!success) {
			return { error: true, message };
		}

		return { error: false, count };
	} catch (error: any) {
		const msg = handleError(error);
		return { error: true, message: msg };
	}
}

export async function storeYoutubeVideosByChannelId(
	user_id: string,
	channelId: string,
	username:any
): Promise<{ error: true; message: string } | { error: false; count: number }> {
	try {
		const url = `${
			process.env.ENV === "production"
				? process.env.REACT_APP_API_URL_PRODUCTION
				: process.env.REACT_APP_API_URL_DEV
		}api/youtube/storeVideosByChannelId`;

		const resServer = await axios.post(
			url,
			{ userId: user_id, channelId },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		const { success, message, count } = resServer.data;
		if (!success) {
			return { error: true, message };
		}
		const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
		// const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
	
		try {
		  const resEmbed = await axios.post(
			embedUrl,
			{
			  id: user_id,
			  user: username,
			}
			// {
			//   headers: {
			//     "content-type": "application/json",
			//   },
			// }
		  );
		  if (resEmbed.data === "done") {
			// setTimeout(async () => {
			  const { data: update, error } = await supabase
				.from("user")
				.update({ show_youtube: true,is_youtube_on:true })
				.eq("user_id", user_id);
			// }, 10000);
		  }
	
		  // Continue with the next steps if needed
		} catch (error) {}
		return { error: false, count };
	} catch (error: any) {
		const msg = handleError(error);
		return { error: true, message: msg };
	}
}
