import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

/**
 * Load content
 */
export function useLoadRenderComponent(user_id: string, unique_id: any) {
  async function getComponent({ pageParam = 1 }) {
    const { data, error }: any = await supabase
      .from("stayuptodate_components")
      .select("*")
      .eq("unique_id", unique_id)

      .eq("user_id", user_id);

    if (data && data.length >= 0 && Array.isArray(data)) {
      // return data[0];

      return data[0];
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  return useQuery(["getComponent", user_id, unique_id], getComponent, {
    enabled: Boolean(user_id),
  });
}
