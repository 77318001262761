import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import "./loading.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { supabase } from "../../config/supabaseClient";
const LoadingDummyPage = () => {
  const location = useLocation();
  const { user } = useAuth();
  const user_id = user?.id || "";
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const username = location.pathname.substring(
    location.pathname.indexOf("/loading/") + 9
  );

  useEffect(() => {
    checkPageIndexing();
  }, []);

  async function checkPageIndexing() {
    const { data, error }: any = await supabase
      .from("user")
      .select("*")
      .eq("user_id", user_id);

    if (data && data[0]) {
      const isPageIndexed = data[0].is_indexed;
      if (isPageIndexed) {
        navigate(`/${username}`, { state: { indexPages: true } });
      } else {
        navigate(`/loading/${username}`);
      }
    }
  }

  return (
    <div>
      {" "}
      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)" }}
        // title="Newsletter"
        centered={true}
        closable={false}
        open={isModalOpen}
        footer={null}
      >
        <div className="loading-text">
          <p>There seems to be an issue while setting up your page</p>
          <p>We will have it ready within next 24 hours.</p>
          <BsHourglassSplit className="loading-icon" />
        </div>

        {/* <div dangerouslySetInnerHTML={iframe()} /> */}
      </Modal>
    </div>
  );
};

export default LoadingDummyPage;
