import { Button, Segmented, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLoadStayUpToDateComponents } from "../../../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import "../Charts.css";
import ChartClicksTable from "./ChartClicksTable";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import StatBox from "../../../../../components/StatBox/StatBox";

const ContentCharts = (props: any) => {
  const {
    username,
    user_id,
    postClicksAnalytics,
    loadingPostClicksAnalytics,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
    carouselClicksViewsAnalytics,
    loadingCarouselClicksViewsAnalytics,
    carouselClicksAnalytics,
    loadingCarouselClicksAnalytics,
    daysNumber,
    brand_color,
    carouselViews,
    loadingCarouselViews,
  } = props;
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, user_id);
  const [selectedComponent, setSelectedComponent] = useState<any>("");
  const [defaultCurrentPage, setDefaultCurrentPage] = useState(1);
  useEffect(() => {
    if (StayUpToDateComponents?.data) {
      const filteredComponents = StayUpToDateComponents.data.filter(
        (elem: any) => elem.name !== "My Links"
      );
      setSelectedComponent(filteredComponents[0].name);
    }
  }, [StayUpToDateComponents]);

  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="m-2 p-3px">{data.formatted_date}</p>
          <p className="user-clr p-3px">Clicks: {data.clicks}</p>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = () => (
    <div className="clicks">
      <div className="user-clr">
        <ul>
          <li> Clicks</li>
        </ul>
      </div>
    </div>
  );
  return (
    <>
      <div className="segmented-container">
        {StayUpToDateComponents && StayUpToDateComponents?.data
          ? StayUpToDateComponents?.data
              .filter((elem: any) => elem.name !== "My Links")
              .filter((elem: any) => elem.status === "active")
              .map((elem: any, index: any) => (
                <React.Fragment key={index}>
                  <Button
                    onClick={(e: any) => {
                      e.preventDefault();
                      setSelectedComponent(elem.name);
                      setDefaultCurrentPage(1);
                    }}
                    style={{
                      background:
                        selectedComponent === elem.name ? "#3b3b3b" : "white",
                      color:
                        selectedComponent === elem.name ? "white" : "black",
                    }}
                  >
                    {elem.name}
                  </Button>
                </React.Fragment>
              ))
          : ""}
      </div>
      <div className="fullDiv">
        {loadingCarouselClicksAnalytics ||
        loadingCarouselClicksViewsAnalytics ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <>
            {selectedComponent ? (
              <>
                {carouselClicksAnalytics[selectedComponent] &&
                carouselClicksAnalytics[selectedComponent].length ? (
                  <>
                    {daysNumber !== 1 && (
                      <>
                        {" "}
                        {carouselClicksViewsAnalytics[selectedComponent] &&
                          carouselClicksViewsAnalytics[selectedComponent]
                            .length && (
                            <ResponsiveContainer width="100%" height={550}>
                              <LineChart
                                data={
                                  carouselClicksViewsAnalytics[
                                    selectedComponent
                                  ]
                                }
                                margin={{
                                  top: 15,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <XAxis dataKey="formatted_date" stroke="grey" />
                                <YAxis stroke="grey" />
                                <Tooltip content={renderCustomTooltip} />
                                <Legend content={CustomLegend} />
                                <Line
                                  type="monotone"
                                  dataKey="clicks"
                                  stroke="#8685EF"
                                  activeDot={{ r: 8 }}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          )}
                      </>
                    )}

                    <div>
                      <ChartClicksTable
                        loadingPostClicksAnalytics={
                          loadingCarouselClicksAnalytics
                        }
                        chartClicks={carouselClicksAnalytics[selectedComponent]}
                        defaultCurrentPage={defaultCurrentPage}
                        setDefaultCurrentPage={setDefaultCurrentPage}
                        brand_color={brand_color}
                        carouselViews={carouselViews}
                        loadingCarouselViews={loadingCarouselViews}
                        selectedComponent={selectedComponent}
                      />
                    </div>
                  </>
                ) : (
                  
                    !loadingCarouselViews &&
                      carouselViews[selectedComponent] &&
                      carouselViews[selectedComponent]?.[0]?.clicks > 0 ? (
                        <StatBox
                          isLoading={loadingCarouselViews}
                          name="Page Views"
                          number={carouselViews[selectedComponent]?.[0]?.clicks}
                          // icon={
                          //   <BsInfoCircle
                          //     className="info-tooltip"
                          //     data-tooltip-id="totalSum"
                          //     data-tooltip-content="Number of times your page has been visited."
                          //   />
                          // }
                          classname="stat-page"
                          brand_color={brand_color}
                        />
                      )
:
                    <h5 style={{ textAlign: "center", fontWeight: "500" }}>
                      No data to show for "{selectedComponent}".
                    </h5>
                  
                )}
              </>
            ) : (
              <div className="spin">
                <Spin />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContentCharts;
