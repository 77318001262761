import React from "react";
import { useLoadLinks } from "../../../../../Utils/customHooks/useLoadLinks";
import MyLinksSkeletonTemplateWrapper from "../MyLinksSkeletonTemplateWrapper";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";

const MyLinksWrapper = (props: any) => {
  const location = useLocation();
  const { user_id, username, title, brand_color } = props;
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  function linkAnalytics(link: any, url: any) {
    track(`my links link click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
      details: { link: link, url: url },
      title: "Stay Up To Date",
    });
  }
  return (
    <>
      {isLoading ? (
        <div
          className="creator-card-grid-wrp-subcomponents p-20"
        >
          <MyLinksSkeletonTemplateWrapper />
        </div>
      ) : (
        <div className="creator-card-grid-wrp-subcomponents">
          {data &&
            data.length > 0 &&
            data.split(",").map((link: any, index: number) => (
              <React.Fragment key={index}>
                <CardGridItem
                  link={link}
                  username={username}
                  brand_color={brand_color}
                  linkAnalytics={linkAnalytics}
                />
              </React.Fragment>
            ))}
        </div>
      )}
    </>
  );
};
const CardGridItem = ({ link, username, linkAnalytics, brand_color }: any) => (
  <a
    href={
      link?.split(":-")[1]?.startsWith("http")
        ? link?.split(":-")[1]
        : `http://${link?.split(":-")[1]}`
    }
    target="_blank"
    onClick={() => linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])}
    className="card-link"
  >
    {link?.split(":-")[2] ? (
      <>
        <div
          className="creator-card"
          style={{ backgroundColor: brand_color && brand_color }}
        >
          <div className="creator-card-cover">
            <img
              className="creator-card-img"
              alt="example"
              src={link?.split(":-")[2]}
            />
            <a
              href={
                link?.split(":-")[1]?.startsWith("http")
                  ? link?.split(":-")[1]
                  : `http://${link?.split(":-")[1]}`
              }
              target="_blank"
              className="card-link"
              onClick={() =>
                linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])
              }
            >
              {" "}
              <div className="creator-card-meta">{link?.split(":-")[0]}</div>
            </a>
          </div>
        </div>
      </>
    ) : (
      <div
        className="creator-card"
        style={{ backgroundColor: brand_color && brand_color, height: "100%" }}
      >
        <a
          href={
            link?.split(":-")[1]?.startsWith("http")
              ? link?.split(":-")[1]
              : `http://${link?.split(":-")[1]}`
          }
          target="_blank"
          className="card-link h-100"
          onClick={() =>
            linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])
          }
        >
          {" "}
          <div className="creator-card-meta">{link?.split(":-")[0]}</div>
        </a>
      </div>
    )}
  </a>
);

export default MyLinksWrapper;
