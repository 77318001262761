import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/skeleton.css";
export const ContentSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <div className="app-wrapper">
        <div className="skeleton-tab-posts-table">
          <div className="skeleton-tab-post-row">
            <div className="skeleton-tab-row-media">
              <Skeleton className="skeleton-tab-row-media-wrapper" />
              <div className="skeleton-tab-row-actions-mobile">
                <Skeleton className="skeleton-tab-index-btn" />
                <Skeleton className="skeleton-tab-index-btn" />
              </div>
            </div>
            <div className="skeleton-tab-row-tags">
              <div className="skeleton-search-container-tagInput">
                <Skeleton />
              </div>
              <div
                className="skeleton-tag"
              >
                {/* Tags skeleton */}
              </div>
            </div>
            <div className="skeleton-tab-actions">
              <Skeleton className="skeleton-tab-index-btn" />
              <Skeleton className="skeleton-tab-index-btn" />

              <Skeleton className="skeleton-tab-index-btn" />

              <Skeleton className="skeleton-tab-index-btn"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="table-responsive">
        <table className="table">
          <tbody>
  {/* <tr>
    <td><SkeletonStatBox /> </td>
    <td><SkeletonStatBox /></td>
    <td><SkeletonStatBox /></td>
  </tr> */}

            <tr>
              
              {[...Array(3)].map((_, index) => (
                <SkeletonStatBox key={index} />
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
