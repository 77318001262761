import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

// Hook for adding or removing tags
export default function useUpdateSearchBarTags() {
  const queryClient = useQueryClient();

  const submitQuery = async ({
    tag,
    tagString,
    user_id,
    action,
  }: // post_id,
  {
    // activeKey: string;
    tag: string;
    tagString: string;
    user_id: any;
    action: string;
    // post_id: string;
    pageIndex: number;
    index: number;
    // filter: string | null;
  }) => {
    const { error: customError } = await supabase
      .from("user")
      .update({search_bar_tags: tagString })
      .eq("user_id", user_id);
   

    if (customError) {
      throw new Error(customError?.message);
    } else {
      return true;
    }
  };

  return useMutation(submitQuery, {
    // When mutate is called:
    onMutate: async ({ user_id, tagString }) => {
      await queryClient.cancelQueries(["SearchBarTags", tagString]);
      const previousData = queryClient.getQueryData([
        "SearchBarTags",
        user_id,
        tagString,
        // activeKey,
        // filter,
      ]);

      // if (previousData) {
      // 	// Deep clone the object, otherwise the objects inside will have the same reference in memory
      // 	const newData = JSON.parse(JSON.stringify(previousData)) as {
      // 		pageParams: any;
      // 		pages: any[];
      // 	};
      // 	newData.pages[pageIndex][index].custom_queries = tagString;
      // 	queryClient.setQueryData(
      // 		["SearchBarTags", ],
      // 		newData
      // 	);
      // }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, {}, context) => {
      queryClient.setQueryData(["SearchBarTags"], context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, { pageIndex }) => {
      queryClient.invalidateQueries(["SearchBarTags"], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
