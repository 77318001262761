import React from 'react'
import Skeleton from 'react-loading-skeleton';

export const BannerSkeletonTemplateWrapper = () => {
  const SkeletonStatBox = () => {
    return (
        
        <div className='banner-skeleton'>
            <Skeleton
                className="skeletonStyle"
            />
        </div>
    );
}

  return (
    // <>{[...Array(1)].map((_, index) => (
      <>
      <SkeletonStatBox />
      </>
  )
    // ))}</>  )
}
