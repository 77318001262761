import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const PodcastContentComponent = (props: any) => {
  const { username, user_id, isPodcastContentComponentOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Podcast",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",

      "podcast"
    );
    if (res === "true") {
      props.refetch();
      props.setIsContentModalOpen(false);
      props.setIsPodcastContentComponentOpen(false);
      setConfirmLoading(false);

    }
  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
    props.setIsPodcastContentComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsPodcastContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
                            className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isPodcastContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="add-podcast"
      >
        <h5 className="title mb-20 nt-4">
          Your latest Podcasts will be shown.{" "}
        </h5>
      </Modal>
    </>
  );
};

export default PodcastContentComponent;
