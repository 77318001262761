import { Button, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { GrNext } from "react-icons/gr";
import YesterdayLinksComponents from "./SubListComponents/YesterdayLinksComponents";
import ThisWeekLinksComponents from "./SubListComponents/ThisWeekLinksComponents";
import DayWiseLinksComponents from "./SubListComponents/DayWiseLinksComponents";
import CustomLinksComponents from "./SubListComponents/CustomLinksComponents";
import "../../StayUpToDateControls.css"
import QueryLinksComponents from "./SubListComponents/QueryLinksComponents";
import TrendingProductsComponent from "./SubListComponents/TrendingProductsComponent/TrendingProductsComponent";

const ShopLinksComponents = (props: any) => {
  const { isShoplinksModalOpen, username, user_id, StayUpToDateComponents } = props;

  const [isYesterdayLinksComponentOpen, setIsYesterdayLinksComponentOpen] =
    useState(false);
  const [isMonthLinksComponentOpen, setIsMonthLinksComponentOpen] =
    useState(false);
  const [isThisWeekLinksComponentOpen, setIsThisWeekLinksComponentOpen] =
    useState(false);
  const [isDayWiseLinksComponentOpen, setIsDayWiseLinksComponentOpen] =
    useState(false);
  const [isCustomLinksComponentOpen, setIsCustomLinksComponentOpen] =
    useState(false);
  const [isQueryLinksComponentOpen, setIsQueryLinksComponentOpen] =
    useState(false);
  const [isTrendingProductsModalOpen, setIsTrendingProductsModalOpen] =
    useState(false);

  const handleYesterdayLinks = () => {
    setIsYesterdayLinksComponentOpen(true);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false)
  };

  const handleMonthLinks = () => {
    setIsMonthLinksComponentOpen(true);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
    setIsYesterdayLinksComponentOpen(false)
    setIsThisWeekLinksComponentOpen(false);
  };
  const handleThisWeekLinks = () => {
    setIsThisWeekLinksComponentOpen(true);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false)
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleDayWiseLinks = () => {
    setIsDayWiseLinksComponentOpen(true);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleCustomLinks = () => {
    setIsCustomLinksComponentOpen(true)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleQueryLinks = () => {
    setIsQueryLinksComponentOpen(true)
    setIsMonthLinksComponentOpen(false);
    setIsCustomLinksComponentOpen(false)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  function handleTrendingProducts() {
    setIsTrendingProductsModalOpen(true)
    setIsQueryLinksComponentOpen(false)
    setIsMonthLinksComponentOpen(false);
    setIsCustomLinksComponentOpen(false)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  }
  const data = [
    {
      // icon:,
      title: "Yesterday's Shoppable Content",
      key: "yesterday-shoppable-content",
      description: "Automated",
      component: handleYesterdayLinks,
      disabled: StayUpToDateComponents?.data.some((obj: any) => obj.display_name === 'Yesterday')

      //   task: true,
    },
    {
      // icon:,
      title: "This Month's Shoppable Content",
      description: "Automated",
      component: handleMonthLinks,
      disabled: StayUpToDateComponents?.data.some((obj: any) => obj.display_name === 'This Month')

    },
    {
      // icon:,
      title: "This Week's Shoppable Content",
      key: "this-weeks-shoppable-content",
      description: "Automated",
      component: handleThisWeekLinks,
      disabled: StayUpToDateComponents?.data.some((obj: any) => obj.display_name === 'This Week')

    },
    {
      // icon:,
      title: "Shoppable Content by the Day",
      key: "shoppable-content-by-the-day",
      description: "Automated",
      task: true,
      component: handleDayWiseLinks,
    },
    {
      title: "Trending Products",
      key: "trending-products",
      description: "Automated",
      task: true,
      component: handleTrendingProducts,
      disabled: StayUpToDateComponents?.data.some((obj: any) => obj.display_name === 'Trending Products')

    },
    {
      // icon:,
      title: "Show Shoppable Content Based on a Specific Word or Phrase",
      key: "shoppable-content",
      description: "Automated",
      task: true,
      component: handleQueryLinks,
    },
    {
      // icon:,
      title: "Custom Shoppable Content Carousel",
      key: "custom-shoppable-content",
      description: "Manual | Create a carousel using any of your shoppable content",
      task: true,
      component: handleCustomLinks,
    },

    // {
    //   // icon:,
    //   title: "Ant Design Title 4",
    // },
  ];

  const handleOk = () => {
    props.setIsShoplinksModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsShoplinksModalOpen(false);
  };

  function handleTask(title: any) {
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton mr-auto">
              <FaArrowLeft />
            </button>
            {/* <span>Basic Modal</span> */}
          </div>
        }
        open={isShoplinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <h5 className="title mb-20" >Shoppable Carousel Options</h5>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item id={item.key}>
              <Button
                className="item-disabled"
                type="text"
                onClick={item.component}
                disabled={item.disabled}
              >
                <div
                  className="list-item"
                >
                  <p className="m-0">{item.title}</p>
                  <p className="item-disc">{item.description}</p>
                  {item.task && (
                    <button
                      onClick={() => handleTask(item.title)}
                      className="item-task"
                    >
                      {/* <GrNext /> */}
                    </button>
                  )}
                </div>
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      <YesterdayLinksComponents
        isYesterdayLinksComponentOpen={isYesterdayLinksComponentOpen}
        setIsYesterdayLinksComponentOpen={setIsYesterdayLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />{" "}
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={isThisWeekLinksComponentOpen}
        setIsThisWeekLinksComponentOpen={setIsThisWeekLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        title="This Week's Shoppable Content"
        description="All of your shoppable content posted in the last 7 days will always be shown in this carousel. Content will automatically be updated!"
      />
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={isMonthLinksComponentOpen}
        setIsThisWeekLinksComponentOpen={setIsMonthLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        title="This Month's Shoppable Content"
        description="All of your shoppable content posted in this month will always be shown in this carousel. Content will automatically be updated!"
      />
      <DayWiseLinksComponents
        isDayWiseLinksComponentOpen={isDayWiseLinksComponentOpen}
        setIsDayWiseLinksComponentOpen={setIsDayWiseLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        StayUpToDateComponents={StayUpToDateComponents}

      />
      <CustomLinksComponents
        isCustomLinksComponentOpen={isCustomLinksComponentOpen}
        setIsCustomLinksComponentOpen={setIsCustomLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
      <QueryLinksComponents
        isQueryLinksComponentOpen={isQueryLinksComponentOpen}
        setIsQueryLinksComponentOpen={setIsQueryLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
      <TrendingProductsComponent
        isTrendingProductsModalOpen={isTrendingProductsModalOpen}
        setIsTrendingProductsModalOpen={setIsTrendingProductsModalOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
      />

    </>
  );
};

export default ShopLinksComponents;
