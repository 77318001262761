import { BsSearch, BsX } from "react-icons/bs";

import "./SearchInput.css";

type Props = {
	
	placeholder: string;
	id: string;
	handleKeyUp: any;
	isActiveFilter: boolean;
	onSearchClick: () => void;
	onClearClick: () => void;
	value?:any;
	onChange?:any;
};
export default function SearchInput({
	value,
	placeholder,
	id,
	handleKeyUp,
	isActiveFilter,
	onSearchClick,
	onClearClick,
	onChange
}: Props) {
	return (
		<div className='search-input-wrapper'>
			<input
			value={value}
				type='text'
				id={id}
				enterKeyHint='search'
				placeholder={placeholder}
				onKeyUp={handleKeyUp}
				className='search-input-box'
				onChange={onChange}
			/>

			{!isActiveFilter ? (
				<button className='search-input-icon-btn' onClick={onSearchClick}>
					<BsSearch />
				</button>
			) : (
				<button className='search-input-icon-btn' onClick={onClearClick}>
					<BsX />
				</button>
			)}
		</div>
	);
}
