import { Tabs } from "antd";
import React from "react";
import LinksCharts from "./ChartComponents/LinksCharts";
import SocialCharts from "./ChartComponents/SocialCharts";
import useGraphicalAnalytics from "../Overview/useGraphicalAnalytics";
import PageCharts from "./ChartComponents/PageCharts";
import ContentCharts from "./ChartComponents/ContentCharts";

const StayUpToDateCharts = (props: any) => {
  const {
    username,
    user_id,
    startDate,
    endDate,
    isYesterday,
    daysNumber,
    brand_color,
  } = props;

  const {
    loadingPageAnalytics,
    pageAnalytics,
    loadingPostAnalytics,
    postAnalytics,
    uniqueUsers,
    loadingUniqueUsers,
    pageViews,
    loadingPageViews,
    carouselViews,
    loadingCarouselViews,
    socialLinksAnalytics,
    loadingSocialLinksAnalytics,
    myLinksAnalytics,
    loadingMyLinksAnalytics,
    postClicksAnalytics,
    loadingPostClicksAnalytics,
    carouselClicksAnalytics,
    loadingCarouselClicksAnalytics,
    loadingUserViewsAnalytics,
    userViewAnalytics,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
    carouselClicksViewsAnalytics,
    loadingCarouselClicksViewsAnalytics,
  } = useGraphicalAnalytics(
    user_id,
    startDate,
    endDate,
    isYesterday,
    daysNumber
  );
  const items = [
    {
      key: "Page",
      label: "Page",

      children: (
        <PageCharts
          loadingUniqueUsers={loadingUniqueUsers}
          loadingPageViews={loadingPageViews}
          data={[
            { key: "unique_users", count: uniqueUsers },
            { key: "page_views", count: pageViews },
          ]}
          uniqueUsers={uniqueUsers}
          pageViews={pageViews}
          loadingUserViewsAnalytics={loadingUserViewsAnalytics}
          userViewAnalytics={userViewAnalytics}
          brand_color={brand_color}
        />
      ),
    },
    {
      key: "Socials Charts",
      label: "Socials",
      children: (
        <SocialCharts
          socialLinksAnalytics={socialLinksAnalytics}
          loadingSocialLinksAnalytics={loadingSocialLinksAnalytics}
          brand_color={brand_color}
        />
      ),
    },
    {
      key: "My Links Charts",
      label: "My Links",
      children: (
        <LinksCharts
          myLinksAnalytics={myLinksAnalytics}
          loadingMyLinksAnalytics={loadingMyLinksAnalytics}
          brand_color={brand_color}
        />
      ),
    },
    {
      key: "Content Charts",
      label: "Content",

      children: (
        <ContentCharts
          user_id={user_id}
          username={username}
          postClicksAnalytics={postClicksAnalytics}
          loadingPostClicksAnalytics={loadingPostClicksAnalytics}
          carouselClicksAnalytics={carouselClicksAnalytics}
          loadingCarouselClicksAnalytics={loadingCarouselClicksAnalytics}
          postClicksViewsAnalytics={postClicksViewsAnalytics}
          loadingPostClicksViewsAnalytics={loadingPostClicksViewsAnalytics}
          carouselClicksViewsAnalytics={carouselClicksViewsAnalytics}
          loadingCarouselClicksViewsAnalytics={loadingCarouselClicksViewsAnalytics}
          brand_color={brand_color}
          daysNumber={daysNumber}
          carouselViews={carouselViews}
          loadingCarouselViews={loadingCarouselViews}
        />
      ),
    },
  ];
  return (
    <div>
      {" "}
      <Tabs defaultActiveKey="Overview" items={items} type="card" />
    </div>
  );
};

export default StayUpToDateCharts;
