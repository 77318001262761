import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoadStayUpToDateComponents } from "../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../Utils/SupabaseServices/ContentDatabase";
import StayUpToDateSkeleton from "../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import { RenderComponent } from "./StayUpToDateRenderComponent/RenderComponents";
import { isNavigable } from "../../Utils/StayUpToDate/RenderComponent";

const StayUpToDate = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user_id, username, brand_color, isFilloutOn } = props;
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, user_id, isFilloutOn);
  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  function handleShop(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }

 
  function handleEmptyCarousel(){
    
  }
  return (
    <div className="uptodate-wrapper" >
      {StayUpToDateComponentsLoading ? (
        <div className="loading-skeleton">
          <StayUpToDateSkeleton />
        </div>
      ) : (
        <>
          {StayUpToDateComponents &&
            StayUpToDateComponents.showingComponents.map((component: any, index: any) => {
              if (component?.status === "active") {
                const canNavigate = isNavigable(component);

                return (
                  <>
                    <div key={index}>
                      <RenderComponent
                        componentId={component?.unique_id}
                        user_id={user_id}
                        username={username}
                        brand_color={brand_color}
                        handleShop={handleShop}
                        handleRedirect={handleRedirect}
                        isHandleNavigate={canNavigate}
                        components={StayUpToDateComponents}
                        handleEmptyCarousel={handleEmptyCarousel}
                      />
                    </div>
                  </>
                );
              } else {
                return null;
              }
            })}
        </>
      )}
    </div>
  );
};

export default StayUpToDate;
