import { InlineWidget } from "react-calendly";

const CalendyInvite = () => {
	const url: any = process.env.REACT_APP_CALENDLY_URL;
	const styles = {
		height: "800px",
	};
	const pageSettings = {
		backgroundColor: "ffffff",
		hideEventTypeDetails: false,
		hideLandingPageDetails: false,
		primaryColor: "00a2ff",
		textColor: "4d5055",
	};

	return (
		<div className='scheduler-wrapper'>
			<InlineWidget url={url} styles={styles} pageSettings={pageSettings} />
		</div>
	);
};

export default CalendyInvite;
