import { supabase } from "../../config/supabaseClient";

export async function fetchComments(postId: any,user_id:any) {
    let options: any = [];
    const { data, error }: any = await supabase
      .from("comments")
      .select("*")
      .eq("post_id", postId)
      .eq("user_id",user_id)
  
    if (data && data.length ) {
      data.map((elem: any) => {
        
        options.push({
         text: elem.comment,
          value: elem.isActive,
          id: elem.comment_id,
        });
      });
      return options;
    } else {
      return false;
    }
  }

  
  
export async function updateCommentsData(item: any) {

    await supabase
      .from("comments")
      .update({ isActive:item.value})
      .eq("comment_id", item.id);
  }
  