import axios from "axios";
import { supabase } from "../../config/supabaseClient";

export enum TRACKING_TYPES {
  PAGE = "page",
  EVENT = "event",
  ERROR = "error",
  SEARCH = "search",
}

export default async function track(
  name: string,
  type: TRACKING_TYPES,

  extras?: { pathname?: string; details?: any; title?: string; user_id?: any }
) {
  // chack params, they should be in the right format
  if (
    typeof name !== "string" ||
    !Object.values(TRACKING_TYPES).includes(type)
  ) {
    return;
  }

  let success = false;
  const trackingObject = {
    ip: "",
    type,
    name,
    pathname: extras?.pathname || "",
    details: extras?.details || "",
    title: extras?.title || "",
    user_id: extras?.user_id || "",
  };
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    trackingObject.ip = res.data.ip;
    const { error } = await supabase.from("analytics").insert([trackingObject]);

    if (!error) {
      success = true;
    }
  } catch (err) {
    trackingObject.ip = "";
    trackingObject.type = TRACKING_TYPES.ERROR;
    trackingObject.name = "Tracking Error";
    const { error } = await supabase.from("analytics").insert([trackingObject]);
    if (!error) {
      success = true;
    }
  } finally {
    if (!success) {
    }
  }
}
