import ReactDOM from "react-dom";
import App from "./app";

import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./hooks/useAuth";
// import posthog from 'posthog-js'
// posthog.init('phc_xW4lt1dX1Soksy1r2BeeD9bnpjPiPx0DH9BPaybsG1T', { api_host: 'https://app.posthog.com' })


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});
ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<AuthProvider>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: "black",
					},
				}}
			>
				<App />
			</ConfigProvider>
		</AuthProvider>
	</QueryClientProvider>,

	document.getElementById("root")
);
