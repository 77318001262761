import { useEffect, useState } from "react";
import AuthPagesHeader from "../Header/AuthPagesHeader";
import { Progress } from "antd";
import { BsArrowRightShort } from "react-icons/bs";

import "./SelectGoogleAccount.css";
import { storeYoutubeChannel } from "../../Utils/backendServices/youtube";
import { useAuth } from "../../hooks/useAuth";
import { supabase } from "../../config/supabaseClient";
import { activateUser } from "../../Utils/SupabaseServices/UserDatabase";
import { useNavigate } from "react-router-dom";
import { storeUserControls } from "../../Utils/SupabaseServices/ControlsDatabase";

type Props = {
	name: string;
	videos: string;
	views: string;
	imgUrl: string;
	changeAccount: () => void;
};
export default function SelectGoogleAccount({
	name,
	videos,
	views,
	imgUrl,
	changeAccount,
}: Props) {
	const navigate = useNavigate();
	const authObj = useAuth();

	const usernameBubble = authObj.username as string;
	const user_id = authObj.user ? authObj.user.id : "";
	const [loading, setLoading] = useState(false);
	const [progressLoading, setProgressLoading] = useState(0);

	useEffect(() => {
		if (progressLoading > 0 && progressLoading <= 96) {
			setTimeout(() => {
				setProgressLoading(progressLoading + 4);
			}, 1000);
		}
	}, [progressLoading]);

	const fetchVideos = async () => {
		setLoading(true);
		setProgressLoading(4);
		const resApi = await storeYoutubeChannel(user_id);
		if (!resApi.error) {
			setProgressLoading(99);
			if (authObj.isOnboardingFinished) {
				navigate(`/dashboard/${usernameBubble}`);
			} else {
				await storeUserControls(user_id, 0);
				await supabase.auth.updateUser({
					data: { isOnboardingFinished: true },
				});
				await activateUser(user_id);
				// navigate(`/creator/${usernameBubble}`);
				navigate(`/follower/${usernameBubble}`);

			}
		}
	};

	return (
		<div className='select-account-container'>
			<AuthPagesHeader />
			<div className='select-account-content-box'>
				{loading ? (
					<div
						className="select-account-progress"
					>
						<Progress
							type='circle'
							percent={progressLoading}
							status='active'
							className='white-progress'
						/>
						<p>
							Setting up your account, don't refresh the page...
						</p>
					</div>
				) : (
					<>
						<p className='select-account-content-title'>
							We will link your account to all the videos on this channel:
						</p>
						<div className='select-account-username-box-flex'>
							<div className='yt-channel-account-box'>
								<div className='yt-channel-account-box-img-wrp'>
									<img
										src={imgUrl}
										alt='youtube channel profile'
										className='yt-channel-account-box-img'
									/>
								</div>
								<div>
									<p className='yt-channel-account-box-name'>{name}</p>
									<div className="yt-channel">
										<p className='yt-channel-account-box-details'>
											{videos} videos
										</p>
										<p className='yt-channel-account-box-details'>
											{views} views
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="bubble-back-btn"
						>
							<button className='bubble-black-button' onClick={changeAccount}>
								<p className="m-0">Select other account</p>
							</button>
							<button className='bubble-white-button' onClick={fetchVideos}>
								<p className="m-0">Continue</p>{" "}
								<BsArrowRightShort className="font-half" />
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
