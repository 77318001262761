import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import TextArea from "antd/es/input/TextArea";
import "../Feed/feedTab.css";

const ManageCaptionModal = (props: any) => {
  const { selectedPost, user_id, username, captionModal } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [newCaption, setNewCaption] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isCaptionChanged, setIsCaptionChanged] = useState(false);

  // useEffect(() => {
  //   setNewCaption(selectedPost?.caption);
  // }, [selectedPost]);

  useEffect(() => {
    const fetchLinks = async () => {
      if (selectedPost && selectedPost.post_id) {
        try {
          const { data, error } = await supabase
            .from(`${username}`)
            .select('*')
            .eq("post_id", selectedPost.post_id);
  
          if (error) {
          } else if (data && data.length > 0) {
            // Assuming data is an array and we're interested in the first item
            const fetchedData = data[0];
            setNewCaption(fetchedData.caption);
          }
        } catch (err) {
        }
      }
    };
  
    fetchLinks(); // Call fetchLinks inside useEffect
  
  }, [selectedPost, captionModal]);
  async function handleCaptionOk() {
    setConfirmLoading(true);
    const updatedPost = { ...selectedPost, caption: newCaption };

    await Promise.all([
      supabase
        .from("content_new")
        .update({ caption: newCaption })
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),

      supabase
        .from(username)
        .update({ caption: newCaption })
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),
    ]);

    const { data } = await supabase
      .from("content_new")
      .select("*")
      .eq("post_id", selectedPost.post_id)
      .eq("user_id", user_id);
    if (data && data.length) {
      let combined =
        (data[0].caption ? data[0].caption : "") +
        " " +
        (data[0].ocr ? data[0].ocr : "") +
        " " +
        (data[0].content_category ? data[0].content_category : "") +
        " " +
        (data[0].tags ? data[0].tags : "") +
        " " +
        (data[0].tagged_link
          ? "instagram.com/" + "" + data[0].tagged_link
          : "");
    }

    props.setSelectedPost(updatedPost);
    props.setCaptionModal(false);
    props.refetchData();

    setConfirmLoading(false);
    setIsCaptionChanged(false)
    setNewCaption("");
    setIsEdit(false);
  }

  function handleCaptionCancel() {
    props.setCaptionModal(false);
    setNewCaption(selectedPost?.caption);
    setIsEdit(false);
    setIsCaptionChanged(false)
  }

  return (
    <>
      <Modal
        open={captionModal}
        onOk={handleCaptionOk}
        onCancel={handleCaptionCancel}
        confirmLoading={confirmLoading}
        footer={[
          // <Button key="back" onClick={handleCaptionCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!isCaptionChanged} // Disable if no changes
            onClick={handleCaptionOk}
          >
            Save
          </Button>,
        ]}
      >
        {selectedPost && (
          <>
            <h5 className="text-center mb-4">Manage Caption</h5>
            <label className="bold-label">Caption:</label>
            {/* {selectedPost?.caption.trim() ? (
            <>
              {!isEdit && (
                <Button
                  style={{
                    background: "black",
                    color: "white",
                    fontSize: "0.6rem",
                    marginLeft: "1%",
                    verticalAlign: "top",
                    marginBottom: "4%",
                  }}
                  onClick={() => {
                    setNewCaption(selectedPost.caption || "");
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </>
          ) : (
            ""
          )} */}

            <div className="mt-2">
              <div className="display-flex">
                {/* <div className="width-20">Caption :{" "}</div> */}
                {/* {isEdit ? (
                <Input
                  value={newCaption}
                  onChange={(e: any) => {
                    setNewCaption(e.target.value);
                  }}
                  placeholder="Enter caption here"
                  style={{
                    width: "60%",
                    marginBottom: "2.7%",
                    marginLeft: "4%",
                  }}
                />
              ) : (
                <p
                  style={{
                    width: "60%",
                    marginBottom: "2.7%",
                    marginLeft: "3%",
                  }}
                >
                  {selectedPost.caption}
                </p>
              )} */}
                {/* {isEdit ? ( */}
                <TextArea
                  value={newCaption}
                  onChange={(e: any) => {
                    setNewCaption(e.target.value);
                    setIsCaptionChanged(true);
                  }}
                  placeholder="enter caption here"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  // className="text-area"
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageCaptionModal;
