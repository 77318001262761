import React from "react";
import Skeleton from "react-loading-skeleton";

const StayUpToDateSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <Skeleton
      className="stayUpToDate-skeleton"
      />
    );
  }

  return (
    <>
      {[...Array(3)].map((_, index) => (
        <SkeletonStatBox key={index} />
      ))}
    </>
  );
};

export default StayUpToDateSkeleton;
