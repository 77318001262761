import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  signInEmailOTP,
  signUpEmailOTP,
  signUpPhone,
  verifyEmailSignIn,
  verifyPhoneNumberSignIn,
} from "../../../Utils/SupabaseServices/UserDatabase";
import data from "../../../Utils/CountryCodes.json";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { Spin } from "antd";
import { useAuth } from "../../../hooks/useAuth";
import validator from "validator";
import { supabase } from "../../../config/supabaseClient";

const EmailOTPLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const user_id = user?.id || "";
  const [signedUp, setSignedUp] = useState(false);
  const [email, setEmail] = useState<any>();
  const [error, setError] = useState<any>("");
  // const [phnWithCode, setPhnWithCode] = useState<any>("");
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: user_id || "",
    });
  }, []);

  // const countryCodes = data;
  // const [code, setCode] = useState("+1");

  // const handleOption = (e: any) => {
  // 	const phn = e.target.value;
  // 	setCode(phn);
  // };

  function loginSuccess(username: string) {
    // navigate(`/creator/${username}`);
		navigate(`/dashboard/${username}`);

  }

  async function signUpWithEmail(e: any) {
    setLoading(true);
    // const phoneNumber = code + phone;

    // setEM(phoneNumber);
    e.preventDefault();
    if (validator.isEmail(email)) {
      setError("");
      const isSignUp = await signInEmailOTP(email);

      if (isSignUp===true) {
        setSignedUp(true);
        setTimeout(() => {
          setResendOTP(true);
        }, 0);
      } else {
        if(isSignUp==="time error"){
          setError("For security purposes, you can only request OTP once every 60 seconds. Please try again after every 60 secoonds");

        }else{
        const { data: login, error: errorLogin } = await supabase
          .from("user")
          .select("*")
          .eq("email", email);
        if (login) {
          if (login.length !== 0) {
            setError("Enter valid email.");
          }else {
            setError("No user found with this email.");
          }
        } 
      }
      }
    } else {
      setError("Enter valid email.");
    }
    setLoading(false);
  }

  function SubmitButton(props: any) {
    if (email) {
      return <button className="submit-btn">{props.name}</button>;
    } else {
      return <button className="submit-btn">{props.name}</button>;
    }
  }
  async function validateOtp(e: any) {
    setError("");
    e.preventDefault();
    const otpCode = e.target.value;

    if (otpCode.length === 6) {
      setLoading(true);
      const { error: verifyTokenError, message: verifyTokenMessage } =
        await verifyEmailSignIn(email, otpCode, loginSuccess);

      // if(data)
      if (verifyTokenError && verifyTokenMessage) {
        setError(verifyTokenMessage);
        setLoading(false);
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="auth-phone-signup-container">
        {!signedUp ? (
          <form className="input-form" onSubmit={signUpWithEmail}>
            <div className="input-div">
              {/* <Dropdown id='dropdown'>
								<select
									onChange={(e: any) => handleOption(e)}
									className='option-select'
								>
									{countryCodes.map((elem: any, idx: any) => (
										<option key={idx} value={elem.dial_code}>
											{elem.name}
										</option>
									))}
								</select>
							</Dropdown>{" "} */}
              <input
                placeholder="Enter Email"
                onChange={(e: any) => {
                  if (error) {
                    setError("");
                  }
                  setEmail(e.target.value);
                }}
                required
              />
            </div>

            {error ? <ErrorMsg error={error} /> : ""}

            <SubmitButton name="Send Verification Code" />
          </form>
        ) : (
          <form className="input-form">
            <div className="input-div">
              {" "}
              <input
                placeholder="Enter 6 Digit Verification Code"
                onChange={(e: any) => validateOtp(e)}
                required
              />
            </div>
            {resendOTP ? (
              <div className="resend-msg">
                <p>
                  Didn't get the code?{" "}
                  <button
                    className="resend-btn"
                    onClick={async (e) => {
                      e.preventDefault();
                      const res = await signUpEmailOTP(email);
                      if (!res) {
                        setError(
                          "You only can request a code once every 60 seconds"
                        );
                      }
                      setResendOTP(false);
                    }}
                  >
                    Resend OTP
                  </button>
                </p>
              </div>
            ) : (
              ""
            )}
            {error ? <ErrorMsg error={error} /> : ""}
          </form>
        )}
      </div>
    </Spin>
  );
};

export default EmailOTPLogin;
