// import { useEffect } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";

// const ProtectedRoute = (props: any) => {
//   const { Component } = props;
//   const navigate = useNavigate();
//   const {username} = useParams();
//   const location = useLocation();
//   const path = location.pathname.split("/")[1];
//   const { session, username: sessionUsername } = useAuth();
//   useEffect(() => {
//     const loggedIn = localStorage.getItem("loggedIn");
// if(loggedIn===username){
//     if (!session) {
//       navigate("/");
//     }
//     if (session) {
//       // if(sessionUsername !== username?.username){
//       // 	navigate(`/${path}/${sessionUsername}`);
//       // }
//       // else{
//       // navigate(`/${path}/${username.username}`);
//       // }
//     }
//   }else{
//     navigate("/");

//   }
//   },[session,username,path]);

//   return (
//     <div>
//       <Component />
//     </div>
//   );
// };

// export default ProtectedRoute;
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoute = (props: any) => {
  const { Component } = props;
  const navigate = useNavigate();
  const { username } = useParams();
  const { session, username: sessionUsername } = useAuth();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");


    // Use early return to simplify the code
    if (!loggedIn || loggedIn !== username) {
      navigate("/");
      // return;
    }

    // Use consistent navigation after checking conditions
    // navigate(`/${session ? sessionUsername : username}`);
  }, [session, username, navigate]);

  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectedRoute;
