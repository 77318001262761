import { Alert, Progress, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { BsArrowRightShort, BsCalendar3, BsInstagram } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CalendyInvite from "../../Screens/Auth/SignUpAuth/CalendyInvite";
import { storeUserContent } from "../../Utils/SupabaseServices/ContentDatabase";
import { storeUserControls } from "../../Utils/SupabaseServices/ControlsDatabase";
import { storeUserSearches } from "../../Utils/SupabaseServices/SearchesDatabase";
import {
  activateUser,
  storeUserDetails,
} from "../../Utils/SupabaseServices/UserDatabase";
import AuthPagesHeader from "../Header/AuthPagesHeader";
import "./SelectAccount.css";
import { useAuth } from "../../hooks/useAuth";
import { supabase } from "../../config/supabaseClient";

type Account = {
  username: string;
  id: string;
  name: string;
  profile_picture_url: string;
};

export default function SelectAccount({
  token,
  id,
  profiles,
}: {
  token: any;
  id: any;
  profiles: Account[] | null;
}) {
  const navigate = useNavigate();
  const authObj = useAuth();

  const usernameBubble = authObj.username as string;
  const auth_user_id = authObj.user ? authObj.user.id : "";

  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [scheduleCall, setScheduleCall] = useState(false);
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [progressLoading, setProgressLoading] = useState(0);

  useEffect(() => {
    if (scheduleCall) {
      setTimeout(() => {
        setVisibleCalendar(true);
      }, 2500);
    }
  }, [scheduleCall]);

  useEffect(() => {
    if (progressLoading >= 50 && progressLoading < 95) {
      setTimeout(() => {
        setProgressLoading(progressLoading + 4);
      }, 3000);
    }
  }, [progressLoading]);

  const handleSelectAccount = () => {
    if (selectedAccount) {
      localStorage.setItem("loggedIn", usernameBubble);
      getInstagramAccount(
        selectedAccount.id,
        selectedAccount.name,
        selectedAccount.profile_picture_url
      );
    } else {
    }
  };

  async function getInstagramAccount(
    instagramId: any,
    name: any,
    picture: any
  ) {
    const instagramFeedResult = await axios.get(
      `${process.env.REACT_APP_FACEBOOK_DOMAIN}/${instagramId}/media?fields=caption,like_count,comments_count,comments,media_product_type,media_url,permalink,thumbnail_url,id,timestamp&limit=10000&access_token=${token}`
    );

    const creatorMedia = instagramFeedResult.data.data;
    const mediaLength = creatorMedia.length;
    const url = `https://dev.thesearchbubble.com/createTable`;
    await axios.post(url, {
      id: auth_user_id,
      user: usernameBubble,
    });
    // await axios.post(
    //   url,
    //   {
    //     id: auth_user_id,
    //     user: usernameBubble,
    //   },
    //   {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }
    // );
    const hashtags: any[] = [];
    creatorMedia.map((media: any) => {
      if (media.caption) {
        const hashtagsArray = media.caption.match(/#\w+/g);
        if (hashtagsArray != null && hashtagsArray.length > 0) {
          hashtagsArray.map((hash: any) => {
            hashtags.push(hash);
          });
        }
      }
    });

    const occurrences = hashtags.reduce(function (acc: any, curr: number) {
      return acc[curr] ? (acc[curr] += 1) : (acc[curr] = 1), acc;
    }, {});

    const numOfHashtags = Object.keys(occurrences).length;
    Object.keys(occurrences).map(async (obj: any) => {
      const usage: any = (occurrences[obj] / mediaLength).toFixed(2);

      // storeSearches(obj, occurrences[obj], usage);
      await storeUserSearches(obj, occurrences[obj], auth_user_id, usage);
    });

    setLoading(true);
    await storeUserDetails(
      token,
      picture,
      id,
      usernameBubble,
      instagramId,
      auth_user_id
    );
    await storeUserControls(auth_user_id, numOfHashtags);
    // const url = `https://dev.thesearchbubble.com/createTable`;

    // await axios.post(
    //   url,
    //   {
    //     id: auth_user_id,
    //     user: usernameBubble,
    //   },
    //   {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }
    // );
    await dataInsertion(creatorMedia);
  }
  async function dataInsertion(media: any) {
    let count = 1;
    for (const postToStore of media) {
      setProgressLoading(Math.floor((count * 50) / media.length));
      await storeUserContent(
        id,
        usernameBubble,
        postToStore.caption,
        postToStore.like_count,
        postToStore.comments_count,
        postToStore.comments,
        postToStore.media_url,
        postToStore.permalink,
        postToStore.thumbnail_url,
        postToStore.id,
        postToStore.timestamp,
        postToStore.media_product_type,
        auth_user_id
      );
      count++;
    }
    const time = media.length * 700;
    setTimeout(async () => {
      // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
      // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
      const tagUrl = `https://dev.thesearchbubble.com/fetch/tags`;
      // const tagUrl = `https://dev.thesearchbubble.com/fetch/tags`;

      const resTags = await axios.post(
        tagUrl,
        { id: auth_user_id, user: usernameBubble },
        {
          // headers: {
          //   "content-type": "application/json",
          // },
        }
      );
      // const resThumbnail = await axios.post(
      // thumbnailUrl,
      // { id: auth_user_id, user: usernameBubble, from: "content" },
      // {
      //   headers: {
      // 	"content-type": "application/json",
      //   },
      // }
      // );
      if (resTags.data === "done") {
       
        setTimeout(async () => {
          const { data: update, error } = await supabase
            .from("user")
            .update({ show_instagram: true })
            .eq("user_id",auth_user_id);
        }, 10000);
      }
    });
    axios.get(
      `
			${
        process.env.REACT_APP_ENV === "production"
          ? process.env.REACT_APP_API_URL_PRODUCTION
          : process.env.REACT_APP_API_URL_DEV
      }
			
			api/thumbnail/${usernameBubble}`
    );
    await axios.get(
      `
			${
        process.env.REACT_APP_ENV === "production"
          ? process.env.REACT_APP_API_URL_PRODUCTION
          : process.env.REACT_APP_API_URL_DEV
      }
			
			api/user/download/${usernameBubble}`
    );

    setTimeout(async () => {
      setProgressLoading(99);
      await supabase.auth.updateUser({
        data: { isOnboardingFinished: true },
      });
      await activateUser(auth_user_id);
      // navigate(`/creator/${usernameBubble}`);
      navigate(`/follower/${usernameBubble}`);

    }, time);
  }

  return (
    <div className="select-account-container">
      <AuthPagesHeader />
      <div className="select-account-content-box">
        {loading ? (
          <div
            className="select-content"
           >
            <Progress
              type="circle"
              percent={progressLoading}
              status="active"
              className="white-progress"
            />
            <p
            >
              Setting up your account, don't refresh the page...
            </p>
          </div>
        ) : profiles && profiles.length > 0 && token && id ? (
          <>
            <p className="select-account-content-title">
              Please choose the Instagram account you want to continue with:
            </p>
            <div className="select-account-username-box-flex">
              {profiles.map((item) => (
                <div
                  key={item.username}
                  className={`select-account-username-box ${
                    item.username === selectedAccount?.username
                      ? "active"
                      : null
                  }`}
                  onClick={() => {
                    setSelectedAccount(item);
                  }}
                >
                  <div
                    className={`select-account-username-box-icon ${
                      item.username === selectedAccount?.username
                        ? "active"
                        : null
                    }`}
                  >
                    <BsInstagram className="font-1" />
                  </div>
                  <p>{item.username}</p>
                </div>
              ))}
            </div>
            <div
              className="bubble-btn"
            >
              <button
                className="bubble-white-button"
                disabled={!Boolean(selectedAccount)}
                onClick={handleSelectAccount}
              >
                <p className="m-0">Continue</p>{" "}
                <BsArrowRightShort className="font-half" />
              </button>
            </div>
          </>
        ) : scheduleCall ? (
          <>
            <p className="select-account-error-content-title">
              For guidance, Schedule a call with our CEO Milan Doshi.
            </p>
            {!visibleCalendar && (
              <div className="visible-calender">
                <Spin size="large" className="white-spinner" />
              </div>
            )}
            <div style={!visibleCalendar ? { opacity: 0 } : { opacity: 1 }}>
              <CalendyInvite />
            </div>
          </>
        ) : (
          <>
            <Alert
              message="There is an issue with Sign Up Process!"
              type="error"
              showIcon
              className="alert-msg"
            />
            <p className="select-account-error-content-title">
              For guidance, Schedule a call with our CEO Milan Doshi.
            </p>
            <div
              className="bubble-white-btn"
            >
              <button
                className="bubble-white-button"
                onClick={() => setScheduleCall(true)}
              >
                <BsCalendar3 className="font-half" />
                <p className="m-0">Schedule</p>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
