import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export default function useUpdateStayUpToDate() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    oldTitle,
    newTitle,
    componentId,
    pageIndex,
    index,
    user_id,
  }: {
    oldTitle: any;
    newTitle: any;
    componentId: any;
    pageIndex: number;
    index: number;
    user_id: any;
  }) => {
    let errorLink;
    const { error } = await supabase
      .from("stayuptodate_components")
      .update({ name: newTitle })
      .eq("name", oldTitle)
      .eq("id", componentId)
      .eq("user_id", `${user_id}`);

    errorLink = error;
    if (errorLink) {
      throw new Error(errorLink.message);
    } else {
      return true;
    }
  };

  return useMutation(submitTag, {
    onMutate: async ({ user_id }) => {
      await queryClient.cancelQueries(["StayUpTpDateComponents"]);
      const previousData = queryClient.getQueryData([
        "StayUpTpDateComponents",
        user_id,
      ]);

      return { previousData };
    },
    onError: (_error, {}, context) => {
      queryClient.setQueryData(
        ["StayUpTpDateComponents"],
        context?.previousData
      );
    },
    onSettled: (_data, _error, { pageIndex }) => {
      queryClient.invalidateQueries(["StayUpTpDateComponents"], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
