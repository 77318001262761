import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Header.css";
import {
  BsLink45Deg,
  BsThreeDots,
  BsPlusCircle,
  BsCheck,
} from "react-icons/bs";
import { Button, Dropdown, Space, Tooltip } from "antd";

const LiveMenu = (props: any) => {
  const { username } = props;
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const utmSource = username;
  const [alertVisible, setAlertVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const textToCopy = `https://www.searchbubble.com/${username}?utm_source=share`;
  // const textToCopy = `http://localhost:3000/${username}?utm_source=share`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setAlertVisible(true);
      setOverlayVisible(true);

      setTimeout(() => {
        setCopied(false);
        setAlertVisible(false);
        setOverlayVisible(false);
      }, 3000);
    });
  };
  const handleOverlayVisibleChange = (visible: boolean) => {
    if (!alertVisible) {
      setOverlayVisible(visible);
    }
  };
  function gotoSignUpPage() {
    const utmUrl = `/subscribe?utm_source=${utmSource}`;
    navigate(utmUrl);
  }

  const items: any = [
    {
      key: "1",
      label: (
        <>
          <p
            className="header-dashboard-menu-item dashboard-menu-font"
            onClick={copyToClipboard}
          >
            Share this Bubble page
          </p>{" "}
          <Button
            className="header-dashboard-menu-item share-signup-btn sign-up-btn"
            style={{
              // background: alertVisible ? "#52c41a" : "black",
              borderColor: alertVisible ? "#52c41a" : "black",
            }}
            onClick={copyToClipboard}
          >
            {alertVisible ? (
              <>
                <BsCheck className="font-16" />
                &nbsp; Copied
              </>
            ) : (
              <>
                <BsLink45Deg /> &nbsp; Copy Link
              </>
            )}
          </Button>
        </>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },

    {
      key: "2",
      label: (
        <>
          <p
            className="header-dashboard-menu-item header-menu"
          >
            Create your own Bubble page{" "}
          </p>
          <Button
            className="header-dashboard-menu-item share-signup-btn sign-up-btn"
            onClick={gotoSignUpPage}
          >
            <BsPlusCircle /> &nbsp; Sign up for free
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="live-menu-wrapper">
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          trigger={["click"]}
          open={overlayVisible}
          onOpenChange={handleOverlayVisibleChange}
        >
          <div className="header-nav-image-live">
            <BsThreeDots className="three-dots" />
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default LiveMenu;
