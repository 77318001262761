import React from "react";
import { Link } from "react-router-dom";
import { StaticVariables } from "../../Utils/StaticVariables";
const AuthPageSwitch = (props: any) => {
	return (
		<div>
			<h6 className='auth-small-text'>
				{props.loginPage ? (
					<>
						{StaticVariables().AUTH_LOGIN_TEXT}
						<Link to='/signup'> {StaticVariables().AUTH_LOGIN_LINK}</Link>{" "}
					</>
				) : (
					<>
						{StaticVariables().AUTH_SIGNUP_TEXT}
						<Link to='/login'> {StaticVariables().AUTH_SIGNUP_LINK}</Link>{" "}
					</>
				)}
			</h6>
		</div>
	);
};

export default AuthPageSwitch;
