import { useLocation, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FeedPosts from "./skeletonComponents/feedPosts";
import HashtagsFilter from "./skeletonComponents/hashtagsFilter";
import Hashtags from "./skeletonComponents/hashtags";
import { isMobile } from "react-device-detect";
import "../../../styles/skeleton.css";

const ProfileSkeleton = (props: any) => {
  const location = useLocation();
const params=useParams()
  return (
    <>
      <div id="app-wrapper">
        {props.controls ? (
          ""
        ) : (
          <div className="dash-row">
            {/* {location.pathname.includes("/follower") ? (<Header />) : ("")} */}
           {params?.id ? "" :<>{location.state || 
            props.isCreator ||
            location.pathname.includes("/follower") ? (
              <>
                {/* <Header /> */}
                <div className="header-skeleton-wrapper" style={{border:'1px solid red'}}>
                  <p className="header-skeleton-logo-tittle">
                    <img
                      className='homepage-icon'
                      src='/assets/icon-black.png'
                      alt='thesearchbubble logo'
                    />
                  </p>
                  {/* {extraComponent} */}
                </div>
                <div className="skeleton-img-div">
                  <Skeleton
                    circle
                    className="creator-img-skeleton hw-40"
                  />
                </div>
                <div className="empty-div">
                  {"     "} {"   "}
                </div>
              </>
            ) : (
              ""
            )}</>}
          </div>
        )}
        <div className="page-skeleton">
          <div
            className={
              !(props.isCreator || location.pathname.includes("/follower"))
                ? "skeleton-creator"
                : "skeleton-creator-page"
            }
          >
            <Skeleton
              circle
              className="creator-img-skeleton hw-100"
            />
          </div>
        
          {/* <div className="search-container-skeleton">
            <Skeleton
              className="search-wrapper-input"
              style={{
                width: "95%",
                height: "100%",
                border: "none",
                outline: "none",
                color: "#000",
                fontSize: "18px",
                borderRadius: "50px",
                paddingLeft: "25px",
                float: "left",
                background: "#f1f1f1",
                // marginTop: "16%",
              }}
            />
          </div>
      
          <div className="skeleton-hashtagsFilter">
            {Array<null>(4)
              .fill(null)
              .map((_, index) => (
                <HashtagsFilter key={index} />
              ))}
          </div> */}

          {/* :""}  */}
          {/* <div className="skeleton-hashtag">
            {" "}
            <div className="skeleton-hashtags">
              <div className="skeleton-hashtags-scroll">
                {!isMobile ? (
                  <>
                    {" "}
                    {Array<null>(8)
                      .fill(null)
                      .map((_, index) => (
                        <HashtagsFilter key={index} />
                      ))}
                  </>
                ) : (
                  <>
                    {" "}
                    {Array<null>(4)
                      .fill(null)
                      .map((_, index) => (
                        <HashtagsFilter key={index} />
                      ))}
                  </>
                )}
              </div>
            </div>
          </div> */}
          <FeedPosts />
        </div>
      </div>
    </>
  );
};

export default ProfileSkeleton;
