import { useLocation, useNavigate } from "react-router-dom";
import { StaticVariables } from "../../Utils/StaticVariables";
import "./HomePage.css";
import { useEffect } from "react";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";

const HomePage = () => {
	const navigate = useNavigate();
	const location=useLocation()
	useEffect(() => {
		track(location.pathname, TRACKING_TYPES.PAGE, {
		  pathname: location.pathname,
		});
	  }, []);
	const images = StaticVariables().HOMEPAGE_IMAGES.map((image: any) => {
		return (
			<img src={image} className='homepage-bubble' alt='bubble' key={image} />
		);
	});

	return (
		<div className='homepage-wrapper w-100'>
			<section className='sticky'>
				<div className='homepage-bubbles'>
					{images}
{/* 
					<img
						className='homepage-icon'
						src='/assets/output-onlinepngtools-edited.png'
						alt='thesearchbubble logo'
					/>
					<div className='homepage-btns'>
						<button
							className='homepage-signup-btn'
							onClick={() => navigate("/signup")}
						>
							Sign Up For Our Private Beta!
						</button>
						<button
							className='homepage-login-btn'
							onClick={() => navigate("/login")}
						>
							Login
						</button>
					</div> */}

					<div className='navbar_container'>
						<a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
							<div className='logo-text'>
								<img
									className='homepage-icon'
									src='/assets/output-onlinepngtools-edited.png'
									alt='thesearchbubble logo'
								/>
							</div>
						</a>
						<nav className='navbar_menu w-nav-menu'>
							<div className='navbar_menu-link-wrapper'>
								<a href="/subscribe" className="navbar_link w-nav-link pricing">pricing</a>
								{/* <a href="/about" className="navbar_link w-nav-link">about</a> */}
								{/* <a href="/how-it-works" className="navbar_link w-nav-link">how it works</a> */}
								<a href="/login" className="navbar_link w-nav-link login">log in</a>
								<a href="/subscribe" className="navbar_link w-nav-link sign-up">sign up for free</a>
							</div>
						</nav>
					</div>
					{/* <div className='mission_container'> */}
						{/* <p className='mission'>
							the next generation of the link-in-bio 
						</p> */}
						<div className="logo mission_container">
							<b><span>monetize </span><span>100% of </span><span>your content </span><span>with </span><br />the smartest link in bio</b> <br /> <br />
							<a href="/subscribe" className="navbar_link w-nav-link try">try us for free</a> <br /> <br />
							<a href="https://calendly.com/searchbubble/demo" target="_blank" className="navbar_link w-nav-link demo">book a demo</a>
						</div>
						{/* <p className='mission-description'>
							<a href="/signup" className="navbar_link w-nav-link sign-up">try us for free</a>
						</p> */}
					{/* </div> */}
				</div>
			</section>
		</div>
	);
};

export default HomePage;
