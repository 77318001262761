import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css"
import { Card } from "react-bootstrap";

const IndividualVideoPostTag = (props: any) => {
    const { post, title, handleRedirect, isLinks, brand_color, isDashboard, isYoutube, isHasMediaUrl, thumbnail } =
        props;

    return (
        <>
            <VideoTag
                media_url={post?.media_url}
                post={post}
                thumbnail={
                    thumbnail ? thumbnail : "/assets/dummy_video.png"
                }
                brand_color={brand_color}
                isYoutube={isYoutube}
                handleRedirect={handleRedirect}
                title={title}
                isLinks={isLinks}
                isHasMediaUrl={isHasMediaUrl}
            />
        </>
    );
};

const VideoTag = (props: any) => {
    const {
        media_url,
        brand_color,
        post,
        isYoutube,
        thumbnail,
        title,
        isLinks,
        isHasMediaUrl
    } = props;
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);
    useEffect(() => {
        if (
            navigator.userAgent.search("Safari") >= 0 &&
            navigator.userAgent.search("Chrome") < 0
        ) {
            setShowClickablePlayBtn(true);
        }
    }, []);
    useEffect(() => {
        videoRef.current.playsInline = true;
    }, []);

    const OverlayStatus = ({
        hover,
        showClickablePlayBtn,
    }: {
        hover: boolean;
        showClickablePlayBtn: boolean;
    }) => {
        return (
            <>
                {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
            </>
        );
    };

    return (
        <>
            <Card>
                <a
                    href={
                        post?.shop_link && post?.shop_link !== ""
                            ? post?.shop_link
                            : post?.permalink ? post?.permalink : post?.table_url
                    }
                    target="_blank"
                    onClick={(e) => {
                        e.preventDefault();
                        props.handleRedirect(
                            post?.post_id,
                            post?.media_url.includes("video")
                                ? post?.product_image
                                    ? post?.product_image
                                    : post?.thumbnail
                                : post?.product_image
                                    ? post?.product_image
                                    : post?.media_url,
                            title,
                            post?.product_name
                        );
                        if (
                            (post?.shop_link && post?.shop_link !== "") ||
                            (post?.permalink && post?.permalink !== "") || (post?.table_url && post?.table_url !== "")
                        ) {
                            window.open(
                                post?.shop_link && post?.shop_link !== ""
                                    ? post?.shop_link
                                    : post.permalink ? post?.permalink : post?.table_url,
                                "_blank"
                            );
                        }
                    }}
                >
                    {!Boolean(media_url) ? (
                        <div>
                            <BsHourglassSplit
                                className="hourglassSplit"
                            />
                            <p>
                                Work in progress: This content will be available in a few
                                minutes.
                            </p>
                        </div>
                    ) : (
                        <HoverVideoPlayer
                            videoRef={videoRef}
                            videoSrc={media_url}
                            muted
                            className={isYoutube ? " youtube-feed-height video-height-individual" : isHasMediaUrl ? " media-feed-height video-height-individual" : "video-height-individual"}
                            style={{
                                backgroundColor: brand_color,
                                display: "block",
                                marginBottom: "2%",
                            }}
                            videoClassName="card-image-individual"
                            preload={"metapost"}
                            contextMenu="return false;"
                            hoverOverlay={
                                <div
                                    className="video-hover"
                                ></div>
                            }
                            pausedOverlay={
                                <
                                    // className={"creator-feed-card-links-image"}
                                >
                                    {/* <img
                                        src={thumbnail && thumbnail.length > 0 ? thumbnail : "/assets/dummy_video.png"}
                                        alt=""
                                        className={"creator-feed-card-links-image"}
                                        loading="eager"
                                    /> */}
                                    <Card.Img
                                        variant="top"
                                        src={thumbnail && thumbnail.length > 0 ? thumbnail : "/assets/dummy_video.png"}
                                        className={isYoutube ? " youtube-feed-height card-image-individual" : isHasMediaUrl ? "card-image-individual not-available media-feed-height" : "card-image-individual"}
                                        loading="eager"
                                        style={{
                                            backgroundColor: brand_color,
                                        }}
                                    />

                                    <OverlayStatus
                                        hover={hover}
                                        showClickablePlayBtn={showClickablePlayBtn}
                                    />
                                </>
                            }
                            loadingOverlay={
                                <Card.Img
                                    variant="top"
                                    src={thumbnail && thumbnail.length > 0 ? thumbnail : "/assets/dummy_video.png"}
                                    className={isYoutube ? "card-image-individual youtube-feed-height" : isHasMediaUrl ? "card-image-individual not-available media-feed-height" : "card-image-individual"}
                                    loading="eager"
                                    style={{
                                        backgroundColor: brand_color,
                                        
                                    }}
                                />
                            }
                            unloadVideoOnPaused
                            onHoverStart={() => {
                                setHover(true);
                            }}
                            onHoverEnd={() => {
                                setHover(false);
                                setCustomPause(false);
                            }}
                            disableDefaultEventHandling={customPause}
                        />
                    )}
                    {post?.product_name && (
                        <p
                            className="product-name"
                        >
                            {post?.product_name}
                        </p>
                    )}
                    {isLinks && (
                        <Button className={"template-shop-btn-links-individual"}>Shop</Button>
                    )}
                </a>
            </Card>
        </>
    );
};

export default IndividualVideoPostTag;
