import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";

/**
 * Load content
 */
export function useLoadSocialLinks(user_id: string) {
  async function getSocialLinks({ pageParam = 1 }) {
    const { data, error }: any = await supabase
      .from("user")
      .select("*")

      .eq("user_id", user_id);

    const order = data?.[0]?.social_links_order?.split(",");
    const transformedObject = Object.entries(data[0])
      .filter(
        ([key, value]) =>
          key.startsWith("social_") &&
          key !== "social_links_order" &&
          value !== null &&
          value !== ""
      ) // Filter and exclude null or empty values
      .map(([key, value]) => [key.replace("social_", ""), value]) // Remove 'social_' prefix
      .sort(([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB)) // Sort based on the order array
      .reduce((acc: any, [key, value]: any) => {
        acc[key] = value;
        return acc;
      }, {});

    if (data && data.length >= 0 && Array.isArray(data)) {
      // return data[0];

      return { active: transformedObject, data: data[0], order: order };
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  return useQuery(["getSocialLinks", user_id], getSocialLinks, {
    enabled: Boolean(user_id),
  });
}
