import { useEffect, useState } from "react";
import validator from "validator";
import AuthBackBtn from "../../../components/AuthBackBtn/authBackBtn";
import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import AuthTitle from "../../../components/AuthTitles/authTitle";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import { resetUserPassword } from "../../../Utils/SupabaseServices/UserDatabase";
import { useLocation } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";
import "./ResetPassword.css";
import { isMobile } from "react-device-detect";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<any>("");
  const [msg, setMsg] = useState<any>("");
  const [error, setError] = useState<any>("");
  const location = useLocation();
  const { user } = useAuth();
  const user_id = user?.id || "";
  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: user_id,
    });
  }, []);
  async function resetPassword() {
    const reset = await resetUserPassword(email);
    if (reset) {
      setMsg("Link Sent!");
      setError("");
    } else {
      setMsg("");
      setError("Error in sending link!");
    }
  }

  const validateEmail = (email: any) => {
    if (validator.isEmail(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  function validateCredentials(e: any) {
    e.preventDefault();
    if (validateEmail(email)) {
      resetPassword();
    }
  }

  function SubmitButton(props: any) {
    if (email) {
      return <button className="submit-btn">{props.name}</button>;
    } else {
      return (
        <button className="submit-btn">
          {props.name}
        </button>
      );
    }
  }
  return (
    <>
      <div className="auth-container">
        <div
          className="auth-options-container"
          
        >
          <AuthPagesHeader />
          <div className="providers">
            <AuthBackBtn route="/login-email" />
            <AuthTitle loginPage={true} />
            <div>
              <form className="input-form" onSubmit={validateCredentials}>
                <div className="input-div">
                  {" "}
                  <input
                    type="email"
                    placeholder="Enter email for reset password"
                    onChange={(e: any) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {emailError ? <ErrorMsg error={emailError} /> : ""}
                <SubmitButton name="Submit" />
              </form>
              {msg ? (
                <div className="success-msg">
                  <p>{msg}</p>
                </div>
              ) : error ? (
                <ErrorMsg error={msg} />
              ) : (
                ""
              )}
            </div>

          </div>
          <AuthTerm />
        </div>
        {!isMobile ? (
          <>
            <div className="login-animation">
              <div className="auth-right-panel">
                <h1 className="auth-creator-quote">
                  &ldquo;Bubble helps my followers answer questions about my
                  shoots without having to DM me.&rdquo;
                </h1>
                <div className="auth-creator-info">
                  <img
                    className="auth-creator-image"
                    src="https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/profile-picture/throughthelensoflindsey.jpeg"
                    alt="creator"
                  ></img>
                  <p className="auth-creator-name">
                    Photographer
                    <br />
                    @throughthelensoflindsey
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ResetPassword;
