import React, { useEffect, useState } from "react";
import "./HighlightDataSection.css";
import { Button } from "antd";
import { BannerSkeleton } from "../../BannerSkeleton";
const HighlightDataSection = (props: any) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const { username, user_id, data, handleRedirect, handleShop, brand_color,isHandleNavigate } =
    props;
  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <>
          <div
            className="outter-section"
            style={{
              marginTop: "15px",
              backgroundColor: brand_color && brand_color,
            }}
          >
            <a
              href={
                data?.details?.split(",")[1]?.startsWith("http")
                  ? data.details.split(",")[1]
                  : `http://${data.details.split(",")[1]}`
              }
              className="highlight-link-tag"
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data.details.split(",")[2] === "link") {
                  handleShop(
                    data.id,
                    data.details.split(",")[0],
                    data?.name,
                    data?.name
                  );
                } else {
                  handleRedirect(
                    data.id,
                    data.details.split(",")[0],
                    data?.name,
                    data?.name
                  );
                }

                const url = data?.details?.split(",")[1];
                if (url && url !== "") {
                  const validUrl = url.startsWith("http")
                    ? url
                    : `http://${url}`;
                  window.open(validUrl, "_blank");
                }
              }}
            >
              <div className="highlight-data-section">
                <div className="highlight-media">
                  {data && data.details ? (
                    <>
                      {data.details.split(",")[0].includes("video") ? (
                        <video controls className="highlight-video">
                          <source
                            src={data.details.split(",")[0]}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={data.details.split(",")[0]}
                          alt="Media"
                          className="highlight-image"
                        />
                      )}
                    </>
                  ) : (
                    <span>No media available</span>
                  )}
                </div>
                <div className="highlight-details">
                  <div className="highlight-name">{data.name} </div>

                  <Button className="highlight-shop-button">
                    {data?.cta_placeholder}
                  </Button>
                </div>
              </div>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default HighlightDataSection;
