import React, { useState } from "react";
import "./PostCarousel.css";
import { BsFillChatDotsFill } from "react-icons/bs";

export default function comments(props: any) {

  const { comments } = props;
  const maxCharLength = 150;
  return (
    <div className="posts-comments">
      {comments.length ? (
        <details>
          <>
            {" "}
            <summary>View popular comments..</summary>
            <div>
              <>
                <ul>
                  {Array.from(comments).filter(
                    (key: any) => key.isActive === true
                  ).length ? (
                    <>
                      {comments.map((comment: any,index:any) => (
                        <React.Fragment key={index}>
                          {" "}
                          {comment.isActive ? (
                            <li
                              className="mt-1"
                            >
                              {comment.comment}
                            </li>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <p>No popular comments listed</p>
                  )}
                </ul>
              </>
            </div>{" "}
          </>
        </details>
      ) : (
        ""
      )}
    </div>
  );
}
