import { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import { getEmbedding } from "../../../Utils/SupabaseServices/ContentDatabase";
import { getPartialTableCount } from "../../../Utils/HelperFunction/HelperFunction";

/**
 *  Use this hook to control feed pagination
 * @param user_id
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadPartialTableCount(
  user_id: string,

  username: any
) {
const [value,setValue]=useState<any>(true)
  const getCount = async () => {
   const data=await getPartialTableCount(user_id,username)
   setValue(data)
  };

  useEffect(() => {
    getCount();
  }, []);

  return {value};
}
