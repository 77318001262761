import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css"

const VideoPostTag = (props: any) => {
  const { post, title, handleRedirect, isLinks, brand_color, isDashboard, isYoutube, isHasMediaUrl, thumbnail } =
    props;

  return (
    <>
      <div className={isYoutube ? "creator-feed-image-wrapper-links youtube-feed-wrapper" : isHasMediaUrl ? "creator-feed-image-wrapper-links media-feed-wrapper" : "creator-feed-image-wrapper-links"}>
        <VideoTag
          media_url={post?.media_url}
          post={post}
          thumbnail={
            thumbnail ? thumbnail : "/assets/dummy_video.png"
          }
          brand_color={brand_color}
          isYoutube={isYoutube}
          handleRedirect={handleRedirect}
          title={title}
          isLinks={isLinks}
          isHasMediaUrl={isHasMediaUrl}
        />
      </div>
    </>
  );
};

const VideoTag = (props: any) => {
  const {
    media_url,
    brand_color,
    post,
    isYoutube,
    thumbnail,
    title,
    isLinks,
    isHasMediaUrl
  } = props;
  const videoRef: any = useRef();
  const [hover, setHover] = useState(false);
  const [customPause, setCustomPause] = useState(false);
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);
  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);
  useEffect(() => {
    videoRef.current.playsInline = true;
  }, []);

  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>
        {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
      </>
    );
  };

  return (
    <>
      <a
        href={
          post?.shop_link && post?.shop_link !== ""
            ? post?.shop_link
            : post?.permalink ? post?.permalink : post?.table_url
        }
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          props.handleRedirect(
            post?.post_id,
            post?.media_url.includes("video")
              ? post?.product_image
                ? post?.product_image
                : post?.thumbnail
              : post?.product_image
                ? post?.product_image
                : post?.media_url,
            title,
            post?.product_name
          );
          if (
            (post?.shop_link && post?.shop_link !== "") ||
            (post?.permalink && post?.permalink !== "") || (post?.table_url && post?.table_url !== "")
          ) {
            window.open(
              post?.shop_link && post?.shop_link !== ""
                ? post?.shop_link
                : post.permalink ? post?.permalink : post?.table_url,
              "_blank"
            );
          }
        }}
      >
        {!Boolean(media_url) ? (
          <div>
            <BsHourglassSplit
              className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few
              minutes.
            </p>
          </div>
        ) : (
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            muted
            className={isYoutube ? "creator-feed-card-links-image youtube-feed-height" : isHasMediaUrl ? "creator-feed-card-video not-available media-feed-height" : "creator-feed-card-links-image"}
            style={{
              backgroundColor: brand_color,
              display: "block",
              marginBottom: "2%",
            }}
            preload={"metapost"}
            contextMenu="return false;"
            hoverOverlay={
              <div
                className="video-hover"
              ></div>
            }
            pausedOverlay={
              <div
                style={{ position: "relative" }}
                className={"creator-feed-card-links-image"}
              >
                <img
                  src={thumbnail && thumbnail.length > 0 ? thumbnail : "/assets/dummy_video.png"}
                  alt=""
                  className={"creator-feed-card-links-image"}
                  loading="eager"
                />

                <OverlayStatus
                  hover={hover}
                  showClickablePlayBtn={showClickablePlayBtn}
                />
              </div>
            }
            loadingOverlay={
              <img
                src={thumbnail && thumbnail.length > 0 ? thumbnail : "/assets/dummy_video.png"}
                alt=""
                className={"creator-feed-card-links-image"}
                loading="eager"
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />
        )}
        {post?.product_name && (
          <p
            className="product-name"
          >
            {post?.product_name}
          </p>
        )}
        {/* {isLinks && (
          <Button className={"template-shop-btn-links"}>Shop</Button>
        )} */}
      </a>
    </>
  );
};

export default VideoPostTag;
