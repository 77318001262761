import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerSkeletonTemplateWrapper } from "../BannerSkeletonTemplateWrapper";

const FormWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const { title, component, user_id, brand_color, cta, filloutId } = props;
  useEffect(() => {
    // Create and append the script element when the component mounts
    const script = document.createElement("script");
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    document.body.appendChild(script);
    setLoading(false);
    // Clean up by removing the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {loading ? (
        <BannerSkeletonTemplateWrapper />
      ) : (
        <div
          className="outter-section-banner-subcomponent"
          style={{
            backgroundColor: brand_color && `${brand_color}`,
          }}
        >
          <div
            style={{ width: "100%", height: "500px" }}
            data-fillout-id={filloutId}
            data-fillout-embed-type="standard"
            data-fillout-inherit-parameters
            data-fillout-dynamic-resize
          ></div>
        </div>
      )}
    </>
  );
};

export default FormWrapper;
