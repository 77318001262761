import { Button, Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import {
  BsFillCheckCircleFill,
  BsFillTrashFill,
  BsLink45Deg,
  BsPencil,
} from "react-icons/bs";
import { downloadContent } from "../../../Utils/SupabaseServices/StorageBuckets";
import { MdOutlineFileDownload } from "react-icons/md";
import { HiPencil } from "react-icons/hi";
import ProductBankEditModal from "./ProductBankEditModal";
import ProductBankDeleteModal from "./ProductBankDeleteModal";
import { FaAmazon } from "react-icons/fa";
import { StaticCompnents } from "../../../Utils/StaticComponent";

const ProductBankActionButtons = (props: any) => {
  const {
    post,
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    type,
    url,
    link,
    data,
    user_id,
    refetchData,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  async function downloadPost(
    post_id: any,
    platform: any,
    type: any,
    url: any
  ) {
    await downloadContent(post_id, type, url);
  }
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (textToCopy: any) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  const testLink = (link: any) => {
    const url = link;
    if (url && url !== "") {
      const validUrl = url.startsWith("http") ? url : `http://${url}`;
      window.open(validUrl, "_blank");
    }
  };
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    const IconComponent = platformIcons[platform];

    if (IconComponent) {
      return <IconComponent style={{ marginLeft: "6px" }} />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };
  return (
    <>
      <Button onClick={() => testLink(link)}>
        {" "}
        {platform && renderPlatformIcon(platform)}Test Link
      </Button>
      <Tooltip
        placement="bottom"
        open={copied}
        title={
          <div
            className="tooltip-style"
          >
            <BsFillCheckCircleFill />
            <p className="m-0">Copied!</p>
          </div>
        }
      >
        <Button onClick={() => copyToClipboard(link)} icon={<BsLink45Deg />}>
          Copy Link
        </Button>
      </Tooltip>
      <Button
        icon={<MdOutlineFileDownload />}
        onClick={() => downloadPost(postId, platform, type, url)}
      >
        Download
      </Button>{" "}
      <Button icon={<HiPencil />} onClick={() => setIsModalOpen(true)}
      data-testid="edit-product"
      >
        Edit Product
      </Button>
      <Button
        type="primary"
        danger
        icon={
          <BsFillTrashFill
            // className="iconSize"
          />
        }
        onClick={() => {
          setIsDeleteModalOpen(true);
        }}
      >
        Delete Product
      </Button>
      <ProductBankEditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={data}
        user_id={user_id}
        refetchData={refetchData}
      />
      <ProductBankDeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        post_id={data?.table_id}
        user_id={user_id}
        refetchData={refetchData}
      />
    </>
  );
};

export default ProductBankActionButtons;
