import { Button, Carousel } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import ImagePostTag from "./ImagePostTag";
import VideoPostTag from "./VideoPostTag";

const IndividualPostCarouselTag = (props: any) => {
  const {
    post,
    redirectVariable,
    isLinks,
    handleRedirect,
    title,
    brand_color,
    isDashboard,
    height,
    isYoutube,
    isHasMediaUrl,
  } = props;
  const mediaUrls = post.media_url
    .split(",")
    .filter((url: any) => url.trim() !== "");
  const onChange = (currentSlide: number) => {};
  const thumbnail = post.thumbnail
    .split(",")
    .filter((url: any) => url.trim() !== "");
  const handleButtonClick = (
    e: any,
    post: any,
    title: any,
    handleRedirect: any
  ) => {
    e.preventDefault();

    // Call handleRedirect with appropriate parameters
    handleRedirect(
      post?.post_id,
      post?.media_url.includes("video")
        ? post?.product_image
          ? post?.product_image
          : post?.thumbnail
        : post?.product_image
        ? post?.product_image
        : post?.media_url,
      title,
      post?.product_name
    );

    // Open the appropriate link in a new tab
    if (
      (post?.shop_link && post?.shop_link !== "") ||
      (post?.permalink && post?.permalink !== "") ||
      (post?.table_url && post?.table_url !== "")
    ) {
      window.open(
        post?.shop_link && post?.shop_link !== ""
          ? post?.shop_link
          : post?.permalink
          ? post?.permalink
          : post?.table_url,
        "_blank"
      );
    }
  };

  const ImagePostTagCarousel = React.memo((props: any) => {
    const { media_url, isVideo, post, handleRedirect } = props;

    return (
      <>
        <Card>
          <a
            href={
              post?.shop_link && post?.shop_link !== ""
                ? post?.shop_link
                : post?.permalink
                ? post?.permalink
                : post?.table_url
            }
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              handleRedirect(
                post?.post_id,
                post?.media_url.includes("video")
                  ? post?.product_image
                    ? post?.product_image
                    : post?.thumbnail
                  : post?.product_image
                  ? post?.product_image
                  : post?.media_url,
                title,
                post?.product_name
              );
              if (
                (post?.shop_link && post?.shop_link !== "") ||
                (post?.permalink && post?.permalink !== "") ||
                (post?.table_url && post?.table_url !== "")
              ) {
                window.open(
                  post?.shop_link && post?.shop_link !== ""
                    ? post?.shop_link
                    : post.permalink
                    ? post?.permalink
                    : post?.table_url,
                  "_blank"
                );
              }
            }}
          >
            {media_url === "" ? (
              <div
                className={
                  isYoutube
                    ? "creator-feed-card-video not-available youtube-feed-height"
                    : isHasMediaUrl
                    ? "creator-feed-card-video not-available media-feed-height"
                    : "creator-feed-card-video not-available"
                }
              >
                <BsHourglassSplit className="hourglassSplit" />
                <p>
                  Work in progress: This content will be available in a few
                  minutes.
                </p>
              </div>
            ) : (
              <>
                <Card.Img
                  variant="top"
                  src={media_url}
                  className={
                    isYoutube
                      ? " youtube-feed-height card-image-individual"
                      : isHasMediaUrl
                      ? " not-available media-feed-height card-image-individual"
                      : "card-image-individual"
                  }
                  loading="eager"
                  style={{
                    backgroundColor: brand_color,
                    marginBottom: "2%",
                    height: height,
                  }}
                />

                {post?.product_name && (
                  <p className="productName-card">{post?.product_name}</p>
                )}
              </>
            )}
          </a>
        </Card>
      </>
    );
  });

  return (
    <>
      {" "}
      <span>
        {mediaUrls.some((media_url: string) => media_url.includes("video")) ? (
          <>
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: string, index: number) => (
                <div key={index}>
                  <>
                    {media_url.includes("video") ? (
                      <VideoPostTag
                        media_url={media_url}
                        post={post}
                        thumbnail={
                          // thumbnail &&
                          //   thumbnail.split(",").length > 0 &&
                          //   thumbnail.split(",")[index] && thumbnail.split(",")[index].length > 0
                          //   ? thumbnail.split(",")[index]
                          thumbnail &&
                          thumbnail.length > 0 &&
                          thumbnail[index]?.length > 0
                            ? thumbnail[index]
                            : "/assets/dummy_video.png"
                        }
                        handleRedirect={handleRedirect}
                      />
                    ) : (
                      <ImagePostTag
                        data={post}
                        title={title}
                        media_url={media_url}
                        isVideo={true}
                        handleRedirect={handleRedirect}
                      />
                    )}
                  </>
                </div>
              ))}
            </Carousel>
            {isLinks && (
              <Button
                onClick={(e) =>
                  handleButtonClick(e, post, title, handleRedirect)
                }
                className={"template-shop-btn-links mt-4"}
                
              >
                Shop
              </Button>
            )}
          </>
        ) : (
          <>
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: any, index: any) => (
                <>
                  {" "}
                  <ImagePostTagCarousel
                    post={post}
                    media_url={media_url}
                    handleRedirect={handleRedirect}
                  />
                </>
              ))}
            </Carousel>
            {isLinks && (
              <Button
                onClick={(e) =>
                  handleButtonClick(e, post, title, handleRedirect)
                }
                className={"template-shop-btn-links mt-4"}
              >
                Shop
              </Button>
            )}
          </>
        )}
      </span>
    </>
  );
};

export default IndividualPostCarouselTag;
