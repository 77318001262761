import { Tabs } from "antd";
import { useParams } from "react-router-dom";

import CreatorViewControls from "./StagingViewControls/creatorViewControls";
import LiveViewControls from "./LiveViewControls/liveViewControls";
import { StaticVariables } from "../../../Utils/StaticVariables";

import "./Controls.css";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import GeneralControls from "./LiveViewControls/Components/GeneralControls";
import CategoriesControls from "./LiveViewControls/Components/CategoriesControls";
import SearchbarControls from "./LiveViewControls/Components/SearchbarControls";
import { useAuth } from "../../../hooks/useAuth";
import StayUpToDateControls from "./LiveViewControls/Components/StayUpToDateControls";

const ControlsTab = (props: any) => {
  const { subscription_type } = props;
  const authObj = useAuth();

  const user_id = authObj.user ? authObj.user.id : "";
  const { username } = useParams();
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);

  const items = [
    {
      key: "General",
      label: "General",
      children: (
        <GeneralControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
          username={username}
          defaultColor={userData?.brand_color}
          defaultTextColor={userData?.brand_text_color}
        />
      ),
    },
    {
      key: "Categories",
      label: "Categories",
      children: (
        <CategoriesControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
          username={username}
        />
      ),
    },
    {
      key: "Searchbar",
      label: "Search Bar",
      children: (
        <SearchbarControls
          username={username}
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
        />
      ),
    },
  
  ];

  return (
    <div className="dashboard-container">
      <div className="details">
        {
          <h1 className="dashboard-mobile-hide">
            {StaticVariables().CONTROLS_TITLE}
          </h1>
        }
        <div className="row" id="analytics-row">
          <Tabs defaultActiveKey="General" items={items} type="card" />
        </div>
        {/* <LiveViewControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
        /> */}
      </div>
    </div>
  );
};

export default ControlsTab;
