import React from "react";
import Skeleton from "react-loading-skeleton";

const MyLinksSkeletonTemplateWrapper = () => {
  const SkeletonStatBox = () => {
    return (
      <div
        className="skeleton-stat-box"
      >
        <Skeleton
          className="skeletonStyle"
        />
      </div>
    );
  };
  return (
    <>{[...Array(6)].map((_, index) => (
    
      <SkeletonStatBox />))}
    </>
  );
};

export default MyLinksSkeletonTemplateWrapper;
