import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "../../Header/header";
import FeedPosts from "./skeletonComponents/feedPosts";
import Hashtags from "./skeletonComponents/hashtags";
// import ContentFilter from "./skeletonComponents/contentFilter";
const GlobalViewSkeleton = (props: any) => {
  return (
    <>
      <div className="dash-row">
        <Header />
      </div>

   
      <div className="search-container-skeleton">
        <Skeleton
          className="search-wrapper-input search-input"
        />
      </div>

      <div className="skeleton-globalhashtag">
        <div className="skeleton-hashtags-scroll">
          <>{Array(8).fill(<Hashtags />)}</>
        </div>
      </div>

      <FeedPosts />
    </>
  );
};

export default GlobalViewSkeleton;
