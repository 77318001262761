import { Input, Modal } from "antd";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

declare global {
  interface Window {
    fd: any;
  }
}

export const FormComponent = (props: any) => {
  const {
    isFormModalOpen,
    user_id,
    username,
    StayUpToDateComponents,
    filloutId,
    isFilloutOn,
  } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const  [name,setName]=useState("Form")
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      name,
      filloutId,
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "Form"
    );
    if (res === "true") {
      props.refetch();
      props.setIsFormModalOpen(false);
      props.setIsModalOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsFormModalOpen(false);
    props.setIsModalOpen(false);
  };

  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsFormModalOpen(false);
  };
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    // setIsButtonDisabled(!value);
  };
  return (
    <>
      <Modal
        title={
          <div>
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isFormModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
      >
        <div>
          <h5 className="title mb-15">Form</h5>
          <label
             className="bold-label"
          >
            Form Title
          </label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              // setErrorMsg("");
            }}
            className="mb-20"
          />
          <p>Form template will be visible to the followers.</p>
        </div>
      </Modal>
    </>
  );
};
