export function processPages(
    pagesData: any[],
    dataCount: any,
    necData: any[],
    necLoading:any,necDataIncluded:any,
    embeddingData?: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (result.length + 1 < dataCount) {
                result.push(post);
              } else {
                result.push(post);

                if (!necLoading) {
                  if (necData && necData.length && !necDataIncluded) {
                    const filteredNecData = necData.filter(
                      (necPost) =>
                        !result.some(
                          (resPost) => resPost?.post_id === necPost?.post_id
                        )
                    );
                    filteredNecData.forEach((necPost) => {
                      result.push(necPost);
                    });
                    necDataIncluded = true;
                  } else {
                    if (embeddingData?.length) {
                      embeddingData
                        .flatMap((page: any) => page)
                        .filter((post: any) => post?.post_id)
                        .reduce((embedResult: any[], post: any) => {
                          const isAllowedDuplicate = post.index === "active,ad";
                          const isDuplicate = result.some(
                            (uniquePost) => uniquePost.post_id === post?.post_id
                          );
                          if (!isDuplicate || isAllowedDuplicate) {
                            result.push(post);
                          }
                        });
                    }
                  }
                }
                if (necDataIncluded && embeddingData?.length) {
                  embeddingData
                    .flatMap((page: any) => page)
                    .filter((post: any) => post?.post_id)
                    .reduce((embedResult: any[], post: any) => {
                      const isAllowedDuplicate = post.index === "active,ad";
                      const isDuplicate = result.some(
                        (uniquePost) => uniquePost.post_id === post?.post_id
                      );
                      if (!isDuplicate || isAllowedDuplicate) {
                        result.push(post);
                      }
                    });
                }
              }
            }

            return result;
          }, [])
      : "";
  }
export  function processEmbeddingPages(
    userData:any,
    pagesData: any[],
    dataCount: any,
necDataIncluded:any,
    necData: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any) => {
            const isAllowedDuplicate = post?.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost?.post_id === post?.post_id
            );
            if (!isDuplicate || isAllowedDuplicate) {
              if (userData?.is_nec_present) {
                if (
                  necData !== undefined &&
                  necData &&
                  necData.length &&
                  !necDataIncluded
                ) {
                  const filteredNecData = necData.filter(
                    (necPost) =>
                      !result.some(
                        (resPost) => resPost?.post_id === necPost?.post_id
                      )
                  );
                  // result.push(...filteredNecData);
                  filteredNecData.forEach((necPost) => {
                    result.push(necPost);

                    // Add an ad after every 10 posts
                  });
                  necDataIncluded = true;
                } else {
                  if (
                    !result.some(
                      (resPost) => resPost?.post_id === post?.post_id
                    )
                  ) {
                    result.push(post);
                  }
                }

                if (necDataIncluded) {
                  if (
                    !result.some(
                      (resPost) => resPost?.post_id === post?.post_id
                    )
                  ) {
                    result.push(post);
                  }
                }
              } else {
                if (
                  !result.some((resPost) => resPost?.post_id === post?.post_id)
                ) {
                  result.push(post);
                }
              }
            }

            return result;
          }, [])
      : "";
  }
 export function processNecData(necData: any[],necDataIncluded:any) {
    return necData
      ? necData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (necData && necData.length && !necDataIncluded) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                });
                necDataIncluded = true;
              }
            }

            return result;
          }, [])
      : "";
  }