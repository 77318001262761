import { Link } from "react-router-dom";
import { StaticVariables } from "../../Utils/StaticVariables";
const AuthPagesHeader = () => {
	return (
		<div className='header-nav-container'>
			<h1 className='header-nav-title'>
				<Link to='/'>{StaticVariables().TITLE}</Link>
			</h1>
		</div>
	);
};

export default AuthPagesHeader;
