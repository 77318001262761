import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadBrokenPages(
  userIds: any,
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getCount() {
    let feedCount;
    let postIds;
 
    const { data, error } = await supabase.rpc("get_brokenlinkscountsss", {
      query_string:filter? [`%%${filter}%%`]:[],
      userid: userIds,

      username: usernames,

      index_type: "archived",
    });
    feedCount = data?.[0]?.count;
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(
    ["paginatedBrokenPosts", filter, userIds, index,postsPerPage],
    getCount,
    {}
  );
}
