import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ControlsStagingSkeleton = () => {
  //
  function SkeletonStatBox() {
    return (
      <div className="skeleton-controls-grid-item">
        <Skeleton />
      </div>
    );
  }
  return (
    <div className="controls-wrapper">
      <>
        <div className="controls-container">
          <div className="row">
            <div className="controls">						{[...Array(6)].map((_, index) => <SkeletonStatBox key={index} />)}
</div>
          </div>
        </div>
        <div className="skeleton-push-btn-div">
          <Skeleton
            className="push-btn mr-3"
          />

          <Skeleton
            className="push-btn ml-15"
          />
        </div>
      </>
    </div>
  );
};

export default ControlsStagingSkeleton;
