const HeaderDark = ({ extraComponent = null }: { extraComponent?: any }) => {
	return (

		<div className='navbar_container'>
		<a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
		  <div className='logo-text'>
			<img
			  className='homepage-icon'
			  src='/assets/output-onlinepngtools-edited.png'
			  alt='thesearchbubble logo'
			/>
		  </div>
		</a>
	  </div>
	);
};

export default HeaderDark;