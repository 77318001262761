import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";



export function useLoadContentTypeCount(
  username: any,
  user_id: string,
  typeList: any[],
  checkedType: any
) {
  async function getPaginatedFeed() {
    let disabledTypes = [];
    let activeType = "";
    for (let type of typeList) {
      if (type === "Feed") {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcount",
          {
            userid: user_id,
          
            filter: ["REELS", "FEED"],
            query_str: "",
            user_name: username,
            socials: ["instagram", "podcast", "newsletter", "pinterest"],

          }
        );

        let feedCount = content[0].count;
        if (feedCount === 0) {
          disabledTypes.push(type);
          if (checkedType !== "Stay Up To Date") {
            activeType = "All";
            await supabase
              .from("controls_live")
              .update({ initial_content2: "All" })
              .eq("user_id", user_id);
          } else {
            activeType = "Stay Up To Date";
          }
        }
      } else if (type === "Featured") {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_featuredfeedcount",
          {
            userid: user_id,
       
            filter: [
              "FEED",
              "REELS",
              "HIGHLIGHTS",
              "STORY",
              "TIKTOK",
              "PIN",
              "MAIL",
              "VIDEO",
              "PODCAST",
            ],
            query_str: "",
            user_name: username,
            socials: [
              "instagram",
              "podcast",
              "newsletter",
              "pinterest",
              "tiktok",
              "youtube",
            ],

          }
        );

        let feedCount = content[0].count;
        if (feedCount === 0) {
          disabledTypes.push(type);
          if (checkedType !== "Stay Up To Date") {
            activeType = "Feed";
            await supabase
              .from("controls_live")
              .update({ initial_content2: "Feed" })
              .eq("user_id", user_id);
          } else {
            activeType = "Stay Up To Date";
          }
        }
      } else if (type === "Stay Up To Date") {
        const { data: content, error: contentError } = await supabase
          .from("stayuptodate_components")
          .select("*")
          .eq("user_id", user_id);
        let feedCount = content?.length;
        if (feedCount === 0) {
          disabledTypes.push(type);
          activeType = "Feed";
          await supabase
            .from("controls_live")
            .update({ initial_content2: "Feed" })
            .eq("user_id", user_id);
         
        }
      }
    }
    
    return { disabledTypes: disabledTypes, activeType: activeType };
  }

  return useQuery(["customCategories"], getPaginatedFeed, {
  });
}
