import { supabase } from "../../config/supabaseClient";

export async function downloadContent(post_id: any, type: any, url: any) {
  let urlArrays = url?.split(",");
  for (let [i, u] of urlArrays.entries()) {
    try {
            // method 1-------------------------------------------

      const response = await fetch(u);
      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${post_id}_${i}`; // Specify the file name
      link.click();

      // Clean up by revoking the URL
      window.URL.revokeObjectURL(link.href);

      // method 2-------------------------------------------
      // fetch(u, {
      //   method: "GET",
      //   headers: {}
      // })
      //   .then(response => {
      //     response.arrayBuffer().then(function(buffer) {
      //       const url = window.URL.createObjectURL(new Blob([buffer]));
      //       const link = document.createElement("a");
      //       link.href = url;
      //       link.setAttribute("download",`${post_id}_${i}.png`); //or any other extension
      //       document.body.appendChild(link);
      //       link.click();
      //     });
      //   })
      //   .catch(err => {
      //   });
            // method 3 but not downloading videos-------------------------------------------

      // const img = new Image();
      // img.crossOrigin = "anonymous";
      // img.src = u;
      // img.onload = () => {
      //   // create Canvas
      //   const canvas = document.createElement("canvas");
      //   const ctx: any = canvas.getContext("2d");
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   ctx.drawImage(img, 0, 0);
      //   // for create tag anchor
      //   const a = document.createElement("a");
      //   a.download = `${post_id}_${i}.png`;
      //   a.href = canvas.toDataURL("image/png");
      //   a.click();
      // };
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
}
