import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import LinksTemplate from "../../LinksTemplate";
import Spinner from "react-bootstrap/esm/Spinner";
import PuffLoader from "react-spinners/PuffLoader";
import "./CustomContentData.css";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { Card } from "react-bootstrap";
import { BsHourglassSplit, BsLink45Deg } from "react-icons/bs";
import DataSkeleton from "../DataSkeleton";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
const CustomContentData = (props: any) => {
  const itemsPerPage = isMobile ? 1.5 : 3;

  const { user_id, username, postIds, title, brand_color, isHandleNavigate, isLoading, data, component } = props;
  const [isLocalLoading, setLocalLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLocalLoading(isLoading)

    }, 1000)
  }, [isLoading])
  // const { isLoading: CustomContentDataLoading, data: CustomContentData }: any =
  //   useLoadCustomContent(username, user_id, postIds, title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  const param = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth =
            containerRef.current.scrollWidth / data.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [data]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth =
        containerRef.current.scrollWidth / data.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [data, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };
  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });

      track("Carousel Click", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,

          title: component.name,
          // name: name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
  };

  return (
    <div>
      {isLoading || isLocalLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {data && data.length ? (
            <div>
              <div className="mainDiv">
                <p className="component-name" style={param?.id ? { cursor: "auto" } : {}} onClick={(e) => handleNavigate(component)}>{title}
                </p>
                {!param?.id && <CustomPagination
                  totalItems={data?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}

                />}
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                  className={!param?.id ? "links-grid-container-reels" : isMobile ? "item-grid creator-card-grid-wrp" : "item-grid"}
                >
                  {data &&
                    data.map((link: any, index: number) => (
                      <div key={index} className={link.media_url ? "link-url" : "link-url youtube-link-position"}>
                        <>
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                              link.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={props.handleRedirect}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={false}
                                  isHasMediaUrl={link.type==="FEED"?true:false}
                                  isYoutube={link.platform == "youtube" ? true : false}
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                  link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}
                                      isHasMediaUrl={false}
                                      isYoutube={link.platform == "youtube" ? true : false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                          thumbnail={link.thumbnail}
                                          isHasMediaUrl={link.type==="FEED"?true:false}
                                          isYoutube={link.platform == "youtube" ? true : false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                          isHasMediaUrl={link.type==="FEED"?true:false}
                                          isYoutube={link.platform == "youtube" ? true : false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                {/* {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                )} */}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={props.handleRedirect}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={false}
                                isHasMediaUrl={link.type==="FEED"?true:false}
                                isYoutube={link.platform == "youtube" ? true : false}
                              />
                            </>
                          )}
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}{" "}
        </>
      )}
    </div>
  );
};

export default CustomContentData;
