import "../Analytics.css";
function CustomCategoriesTable({ searches, from }: { searches: any, from: any }) {
	return (
		<>
			<div className="post-clicks-container">
				<table className="analytics-tables">
					<thead>
						<tr>
							{from === "links" ? <th style={{width:"100%"}}>Link Name</th> : <th style={{width:"100%"}}>Subcategories</th>}
							<th>Clicks</th>
						</tr>
					</thead>

					{searches
						? searches.map((oneKey: any, index: any) => (
							<tbody key={index}>
								<tr>
									<td><div className="analytics-postClicks-thumbnail-div">{oneKey?.query}</div></td>
									<td>{oneKey?.count}</td>
								</tr>
							</tbody>
						))
						: ""}
				</table>
			</div>
		</>
	);
}

export default CustomCategoriesTable;
